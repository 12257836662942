import { Component, OnInit } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import {
  ContractStatus,
} from '../../../models';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';
import { Contract } from '../../list/list-charges/list-charges.component';

export const SHIPPING_ITEM_KINDS = [
  { key: 'all', value: 'すべて' },
  { key: 'except_home_wifi', value: 'Home WiFi以外' },
  { key: 'only_home_wifi', value: 'Home WiFiのみ' }
];

export class ProductPropertyListItem {
  line_status: string;
  contract_product_id: number;
  product_title: string;
  device_code_1: string;
  kpd_line_number: string;
  edited: boolean;
  enable_activate: boolean;
  use_mnp: boolean;
  mnp_number: string;

  constructor() {
    this.edited = false;
    this.enable_activate = false;
  }
}

export class ContractListItem {
  ship_disable: boolean;
  contract_id: number;
  contract_code: string;
  contract_status: number;
  pay_method: string;
  send_tracking_number: string;
  return_tracking_number: string;
  shipping_method: string;
  smartpit_number: string;
  edited: boolean;
  is_unique: boolean;
  products: Array<ProductPropertyListItem>;

  constructor() {
    this.ship_disable = true;
    this.shipping_method = '-- SHIPPING METHOD --';
    this.edited = false;
  }
}

@Component({
  selector: 'app-shipwork',
  templateUrl: './shipwork.component.html',
  styleUrls: ['./shipwork.component.scss']
})
export class ShipworkComponent implements OnInit {

  public from_date: string;
  public to_date: string;
  public contracts: Array<ContractListItem>;
  public display_contracts: Array<ContractListItem>;
  public contract_statuses: Array<ContractStatus>;
  public prepared_contract_status: ContractStatus;
  public item_kinds: Array<{ key: string, value: string }>;
  public selected_item_kind: { key: string, value: string };

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
  ) {
    this.contracts = new Array<ContractListItem>();
    this.item_kinds = SHIPPING_ITEM_KINDS;
    this.selected_item_kind = this.item_kinds.find(v => {
      return v.key === 'except_home_wifi';
    });
  }

  ngOnInit() {
    this.auth_service.apiEnumContractStatuses().subscribe(
      response => {
        this.contract_statuses = response.data;
        const statuses = this.contract_statuses.filter(v => {
          return v.key === 'PREPARING SHIPMENT';
        });
        this.prepared_contract_status = statuses[0];
      },
      error => {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 10000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'Failed to load contract status candidates.',
          'OK',
          cfg
        );
        console.log(JSON.stringify(error));
      }
    );
  }

  public refreshList(): void {
    if (Utils.isValue(this.from_date) && Utils.isValue(this.to_date) &&
      this.from_date.length > 0 && this.to_date.length > 0) {
      console.log('ShipworkComponent::refreshList from : ' + this.from_date + ' - to : ' + this.to_date);

      const params = {
        from_date: this.from_date.split('T')[0],
        to_date: this.to_date.split('T')[0]
      };
      this.auth_service.apiGetShippingContracts(params).subscribe(
        response => {
          if (Utils.isValue(response) && Utils.isValue(response.data)) {
            console.log(response.data);
            this.contracts = [];
            const constracts = response.data;
            constracts.forEach(contract => {
              contract.ship_disable = true; // after refresh set disable
              let is_home = false;
              contract.products.forEach(product => {
                if (product.product_title.toLowerCase().indexOf('home wifi') >= 0) {
                  is_home = true
                  console.log(`ShipworkComponent::refreshList 1 : key=${this.selected_item_kind.key} : is_home_or_fiber = ${is_home} : ${product.product_title.toLowerCase()}`);
                }
              });
              contract.products.forEach(product => {
                const product_title = product.product_title.toLowerCase();
                if (product_title.indexOf('wifi') > 0) {
                  product.enable_activate = false;
                }
                else if (product_title.indexOf('insurance') > 0) {
                  product.enable_activate = false;
                } else if (Utils.isValue(product.use_mnp) && product.use_mnp === true) {
                  product.enable_activate = false;
                } else {
                  product.enable_activate = !Utils.isValue(product.line_status) || product.line_status.length === 0 || product.line_status === '0' ? true : false;
                }
              });
              if (this.selected_item_kind.key === 'all' || (is_home && this.selected_item_kind.key === 'only_home_wifi') || (!is_home && this.selected_item_kind.key === 'except_home_wifi')){
                this.contracts.push(contract);
              }
            });
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  public onChangeSelectingItemKind(event: any): void {
    this.selected_item_kind = this.item_kinds.find(v => {
      return v.key === event.value;
    });
    this.refreshList();
  }

  public resolveStatusToText(contract_status: number): string {
    const statuses = this.contract_statuses.filter(candidate => {
      return candidate.status === contract_status;
    });
    if (statuses.length === 0) {
      return null;
    }
    return statuses[0].title;
  }

  public onRefreshList(event: any): void {
    this.refreshList();
  }

  public onStartDateFromChanged(event): void {
    if (Utils.isValue(event.value)) {
      this.from_date = Utils.dateToDateString(event.value);
    }
    if (Utils.isValue(this.from_date) && Utils.isValue(this.to_date)) {
      this.refreshList();
    }
  }

  public onStartDateToChanged(event) {
    if (Utils.isValue(event.value)) {
      this.to_date = Utils.dateToDateString(event.value);
    }
    if (Utils.isValue(this.from_date) && Utils.isValue(this.to_date)) {
      this.refreshList();
    }
  }

  private _productPropertyListItemsAt(contract_id: number, contract_product_id: number): ProductPropertyListItem {
    const cs = this.contracts.filter(c => {
      return c.contract_id === contract_id;
    });

    if (cs.length <= 0) {
      return null;
    }

    const pr = cs[0].products.filter(p => {
      return p.contract_product_id === contract_product_id;
    });

    if (pr.length <= 0) {
      return null;
    }

    return pr[0];
  }

  public onAssignSimID(event_data: any): void {
    console.log(event_data);
    this.auth_service.apiAssignSimID({ contract_product_id: event_data.data.contract_product_id }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          event_data.data.value = response.data.value;
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
          event_data.event.target.style.backgroundColor = 'rgba(255, 255, 255, 1)';
        }
      },
      error => {
        if (Utils.isValue(error.message) && Utils.isValue(error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.message.message,
            'OK',
            cfg
          );
          event_data.event.target.style.backgroundColor = 'rgba(255, 192, 192, 1)';
        }
      }
    );
  }

  public onKeyUpContractProperty(event_data: any): void {
    console.log(event_data);
    if (event_data.data.edited && event_data.event.key === 'Enter') {
      this.updateContractProperty(event_data.event.target, event_data.event.target.value, event_data.data, event_data.is_unique);
    } else if (event_data.event.key !== 'Tab') {
      if (event_data.data.contract_property_type_id === 29 /* SMARTPIT NUMBER */) {
        event_data.event.target.value = event_data.event.target.value.match(/[0-9]*/g).join('').substring(0, 13);
      }
      event_data.data.edited = true;
    }
  }

  public onBlurContractProperty(event_data: any): void {
    console.log(event_data);
    if (event_data.data.edited) {
      if (event_data.data.contract_property_type_id === 29 /* SMARTPIT NUMBER */) {
        event_data.event.target.value = event_data.event.target.value.match(/[0-9]*/g).join('').substring(0, 13);
      }
      this.updateContractProperty(event_data.event.target, event_data.event.target.value, event_data.data, event_data.is_unique);
    }
  }

  private updateContractProperty(target: any, new_value: string, property: any, is_unique: boolean) {
    property.edited = false;
    this.auth_service.apiUpdateContractProperty({ property_id: property.id, new_value: new_value, is_unique: is_unique }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
          target.style.backgroundColor = 'rgba(255, 255, 255, 1)';
        }
      },
      error => {
        if (Utils.isValue(error.message) && Utils.isValue(error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.message.message,
            'OK',
            cfg
          );
          target.style.backgroundColor = 'rgba(255, 192, 192, 1)';
        }
      }
    );
  }

  public onKeyUpProductProperty(event_data: any): void {
    console.log(event_data);
    if (event_data.data.edited && event_data.event.key === 'Enter') {
      this.updateContractProductProperty(event_data.event.target, event_data.event.target.value, event_data.data, event_data.is_unique, event_data.value_type);
    } else if (event_data.event.key !== 'Tab') {
      event_data.data.edited = true;
    }
  }

  public onBlurProductProperty(event_data: any): void {
    console.log(event_data);
    if (event_data.data.edited) {
      this.updateContractProductProperty(event_data.event.target, event_data.event.target.value, event_data.data, event_data.is_unique, event_data.value_type);
    }
  }

  public onClickActivateSIM(event_data: any): void {
    console.log(event_data);
    this.auth_service.apiActivateSim({ contract_id: event_data.contract_id, contract_product_id: event_data.contract_product_id }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          const pr = this._productPropertyListItemsAt(event_data.contract_id, event_data.contract_product_id);
          if (pr !== null) {
            pr.line_status = 'ENABLED';
            pr.enable_activate = false;
          }
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 3000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }

  private updateContractProductProperty(target: any, new_value: string, property: any, is_unique: boolean, value_type: string) {
    property.edited = false;
    console.log(property);
    if (new_value.toUpperCase() === 'UNDEFINED') {
      is_unique = false;
    }
    if (new_value.toUpperCase().startsWith('EXPIRED', 0)) {
      is_unique = false;
    }
    if (value_type === 'tel') {
      new_value = Utils.toTelString(new_value);
      if (!Utils.isSimpleTelString(new_value)) {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          'Invalid tel number format (require hyphen)',
          'OK',
          cfg
        );
        target.style.backgroundColor = 'rgba(255, 192, 192, 1)';
        return;
      }
    }
    this.auth_service.apiUpdateContractProductProperty({ property_id: property.id, new_value: new_value, is_unique: is_unique }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
          target.style.backgroundColor = 'rgba(255, 255, 255, 1)';
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
          target.style.backgroundColor = 'rgba(255, 192, 192, 1)';
        }
      }
    );
  }

  public onChangeShippingMethod(event_data: any): void {
    const contract = this.contracts.filter(v => {
      return v.contract_id === event_data.contract_id;
    });
    if (Utils.isValue(contract) && contract.length > 0) {
      contract[0].shipping_method = event_data.new_shipping_method;
      contract[0].ship_disable = event_data.new_shipping_method === '-- SHIPPING METHOD --';
    }
  }

  public isESIMProduct(product_title: string): boolean {
    if (product_title.indexOf('eSIM') > 0) {
      return true;
    }
    return false;
  }

  public onSendShippingNotifyClicked(event_data: any): void {
    console.log(event_data);
    this.auth_service.apiSendShippingNotify({ contract_id: event_data.contract_id, shipping_method: event_data.shipping_method }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          const statuses = this.contract_statuses.filter(v => {
            return v.key === 'SHIPPED';
          });
          const contract = this.contracts.filter(v => {
            return v.contract_id === event_data.contract_id;
          });
          if (contract.length > 0) {
            contract[0].ship_disable = true;
            contract[0].contract_status = statuses[0].status;
            console.log(contract[0]);
          }
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }
}
