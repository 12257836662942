export class ParentalConsent {
    id: number;
    user_id: number;
    contract_id: number;
    email: string;
    full_name: string;
    address: string;
    relationship: string;
    date_of_birth: string;
    age: number;
    contact_phone_number: string;
    submission_date: string;
    holder_name: string;
    holder_address: string;
    holder_date_of_birth: string;
    holder_age: number;
    filtering_service: boolean;
    not_need_filtering_service_reason: string;
    created_at: string;
    updated_at: string;
}

export class ParentalConsentDocument {
    id: number;
    parental_consent_id: number;
    file_name: number;
    created_at: string;
    updated_at: string;
}

