<div class="app-generate-invoice-progress-dialog">
    <div class="title">{{progress_title}}</div>
    <mat-progress-bar class="progress_bar" mode="determinate" [value]="progress" [style.display]="progress_display"></mat-progress-bar>
    <div class="message">{{progress_message}}</div>
    <table style="width: 100%">
        <tr>
            <td colspan="2" style="text-align: right">
                <button mat-flat-button class="yes" (click)="onClickClose($event)" [disabled]="close_disable">Close</button>
            </td>
        </tr>
    </table>
</div>
