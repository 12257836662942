<div class="app-generate-invoice-confirm-dialog">
  <div>
    Invoice generate :
    <mat-form-field class="date_pick">
      <input matInput type="month" (change)="onChargeMonthChanged($event.target.value)">
    </mat-form-field>
  </div>
  <div class="message">
    <p class="over_write_warning" [style.display]="over_write_warning_display">既にこの月のinvoiceは生成されています。Generateを進めると、この月に関するメール送付などの履歴も全て初期化されます。</p>
  </div>
  <div>

  </div>
  <table style="width: 100%">
    <tr>
      <td colspan="2" style="text-align: right">
        <button mat-flat-button class="yes" (click)="onClickGenerate($event)" [disabled]="button_generate_disable">Generate</button>
        <button mat-flat-button class="no" (click)="onClickCancel($event)" [disabled]="button_cancel_disable">Cancel</button>
      </td>
    </tr>
  </table>
</div>