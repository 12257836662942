<div class="app-list-invoices">
  <table class="operation" style="width: 100%">
    <tr>
      <td>
        <button mat-icon-button class="edit_icon" (click)="onRefreshList($event)">
          <mat-icon aria-label="Refresh list">refresh</mat-icon>
        </button>
        Show unsent only:
        <mat-checkbox [checked]="unsent_only" (change)="onUnsentOnlyCheckChanged($event)"></mat-checkbox>
      </td>
      <td style="text-align: right; align-content: right;">
        <table class="invoice_month" style="width: 100%; margin-left: auto;">
          <tr>
            <td class="title">
              請求書発行月 :&nbsp;
            </td>
            <td class="date">
              <mat-form-field class="date_pick">
                <input matInput type="month" [(ngModel)]="invoice_month" (change)="onInvoiceMonthChanged($event.target.value)">
                <mat-hint>
                  <div *ngIf="invoice_month_error" class="invoice_month_error">Invalid</div>
                </mat-hint>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </td>
      <td>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field class="filter">
          <input matInput [(ngModel)]="current_filter" (keyup)="onFilterKeyUp($event.target.value)" placeholder="Filter">
        </mat-form-field>
      </td>
      <td class="charging-list-paginator">
        <mat-paginator class="charging-list-paginator" [pageSizeOptions]="[50, 100, 500, 1000, 5000, 10000, 50000]" showFirstLastButtons></mat-paginator>
      </td>
      <td style="text-align: right;">
        <button mat-flat-button class="warn" (click)="onClickSendInvoice($event)">Send invoice</button>
      </td>
    </tr>
  </table>
  <div class="list-invoices-table-container mat-elevation-z8">
    <table mat-table [dataSource]="data_source" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="contract_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract code </th>
        <td mat-cell *matCellDef="let element">
            <a class="contract_code" href="{{'/operation/invoice?contract_code=' + element.contract_code + '&invoice_month=' + invoice_month}}"
            target="_blank">{{element.contract_code}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="pdf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PDF</th>
        <td mat-cell *matCellDef="let element">
            <a class="pdf" href="{{root_for_pdf + '/invoices/show/' + element.secure_random}}" target="_blank">show</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="mail_send_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Send status </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.mail_send_status === 0">
            <select name="send_status_select" (change)="onChangeMailSendStatus({invoice: element, new_status: $event.target.value})">
              <option value="NOT_YET" selected>未送信</option>
              <option value="SENT">送信済</option>
            </select>
          </div>
          <div *ngIf="element.mail_send_status !== 0">
            <select name="send_status_select" (change)="onChangeMailSendStatus({invoice: element, new_status: $event.target.value})">
              <option value="NOT_YET">未送信</option>
              <option value="SENT" selected>送信済</option>
            </select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="mail_send_config">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Send config </th>
        <td mat-cell *matCellDef="let element"> {{element.mail_send_config === 0 ? 'Manual' : 'Auto'}}
        </td>
      </ng-container>


      <ng-container matColumnDef="mail_send_config_org">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> &lt;-- orginal </th>
        <td mat-cell *matCellDef="let element"> {{element.mail_send_config_org}}
        </td>
      </ng-container>mail_send_config_org

      <ng-container matColumnDef="invoice_products">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> products </th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let product of element.products; last as isLast">
            <div *ngIf="isLast; else notLastProduct">
              {{product.contract_product_name}}<br/> 
            </div>
            <ng-template #notLastProduct>
              {{product.contract_product_name}} 
            </ng-template>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="user_e_mail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> e-mail </th>
        <td mat-cell *matCellDef="let element"> {{element.user_e_mail}} </td>
      </ng-container>

      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User name </th>
        <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
      </ng-container>

      <ng-container matColumnDef="contract_end_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> End date </th>
        <td mat-cell *matCellDef="let element"> {{getDisplayDateString(element.contract_end_date)}} </td>
      </ng-container>

      <ng-container matColumnDef="pay_method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay method </th>
        <td mat-cell *matCellDef="let element"> {{element.pay_method}} </td>
      </ng-container>

      <ng-container matColumnDef="pay_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay status </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.pay_status === 0">
            <select name="send_status_select" (change)="onChangePayStatus({invoice: element, new_status: $event.target.value})">
                <option value="NOT_YET" selected>未払い</option>
                <option value="PAID">支払済</option>
            </select>
          </div>
          <div *ngIf="element.pay_status !== 0">
            <select name="send_status_select" (change)="onChangePayStatus({invoice: element, new_status: $event.target.value})">
              <option value="NOT_YET">未払い</option>
              <option value="PAID" selected>支払済</option>
            </select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="pay_error">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Error </th>
        <td mat-cell *matCellDef="let element"> {{element.pay_error}} </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="display_columns; sticky: true"></tr> -->
      <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
      <tr mat-row *matRowDef="let row; columns: display_columns;" (click)="onClickRow($event)"></tr>
    </table>
  </div>
</div>