
export class InvoiceListItemProductProperty {
    invoice_id: number;
    invoice_product_id: number;
    invoice_product_property_id: number;
    contract_id: number;
    contract_product_id: number;
    contract_product_property_id: number;
    key: string;
    value: string;
    title: string;
    description: string;
}

export class InvoiceListItemProduct {
    invoice_id: number;
    invoice_product_id: number;
    contract_id: number;
    contract_product_id: number;
    title: string;
    price_sub_total: number;
    price_tax: number;
}

export class InvoiceListItem {
    id: number; // invoice_id
    invoice_month: string;
    invoice_code: string;
    contract_id: number;
    contract_code: string;
    user_id: number;
    user_e_mail: string;
    user_name_id: number;
    user_name: string;
    mail_send_status: number;
    mail_send_config: number;
    mail_send_config_org: number;
    pay_method: string;
    demand_status: number;
    demand_send_user_id: number;
    demand_sent_at: string;
    pay_status: number;
    pay_error: string;
    price_total: number;
    price_tax: number;

    is_selected:  boolean;    // for user interface
    constructor() {
        this.is_selected = false;
    }
}
