import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FlashMessage } from '../../../models';
import { FlashMessageService } from '../../../services/misc';
import { Subscription } from 'rxjs';
import { Utils } from '../../../helpers';
import { Values } from '../../../constants';

@Component({
  selector: 'app-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss']
})
export class FlashMessageComponent implements OnInit, OnDestroy {
  private _el: HTMLElement;
  public flash_message: FlashMessage;
  private _queuedflash_message: Array<FlashMessage>;
  private _subscriptionflash_message: Subscription;

  private _showing: boolean;
  private _timeout: any;

  constructor(
    private el: ElementRef,
    private flash_message_service: FlashMessageService
  ) {
    this._el = el.nativeElement;
    this._queuedflash_message = new Array<FlashMessage>();
    this._showing = false;
    this._timeout = undefined;
  }

  ngOnInit() {
    this._subscriptionflash_message = this.flash_message_service.flash_message$.subscribe(
      message => {
        // console.log('FlashMessageComponent::_subscriptionflash_message - start');
        if (this._showing === false) {
          this.flash_message = message;
          this.startMessage();
        } else {
          this._queuedflash_message.push(message);
        }
      }
    );
  }

  ngOnDestroy() {
    this._subscriptionflash_message.unsubscribe();
  }

  public onCloseClicked($event) {
    this.endMessage(this);
  }

  private showAnimation() {
    const el = <HTMLDivElement>(
      this._el.querySelectorAll('div.flash-message')[0]
    );
    if (el.classList.contains('slidein')) {
      el.classList.remove('slidein');
    }
    if (el.classList.contains('slideout')) {
      el.classList.remove('slideout');
    }
    el.style.display = 'block';
    el.classList.add('slidein');
  }

  private hideAnimationEnd(event) {
    const el = <HTMLDivElement>(
      this._el.querySelectorAll('div.flash-message')[0]
    );
    this.flash_message = undefined;
    el.style.display = 'none';
  }

  private hideAnimation() {
    const el = <HTMLDivElement>(
      this._el.querySelectorAll('div.flash-message')[0]
    );
    if (el.classList.contains('slidein')) {
      el.classList.remove('slidein');
    }
    if (el.classList.contains('slideout')) {
      el.classList.remove('slideout');
    }
    el.addEventListener('animationend', this.hideAnimationEnd.bind(this), {
      once: true
    });
    el.classList.add('slideout');
  }

  private startMessage() {
    if (Utils.isValue(this.flash_message) === false) {
      return;
    }
    if (Utils.isValue(this.flash_message.title) === false) {
      return;
    }
    if (this.flash_message.title.length <= 0) {
      return;
    }
    this._showing = true;
    const el = <HTMLDivElement>(
      this._el.querySelectorAll('div.flash-message')[0]
    );
    el.classList.remove('success');
    el.classList.remove('information');
    el.classList.remove('error');
    if (this.flash_message.status === Values.STAT_SUCCESS) {
      el.classList.add('success');
    } else if (this.flash_message.status === Values.STAT_NOCARE) {
      el.classList.add('information');
    } else if (this.flash_message.status === Values.STAT_ERROR) {
      el.classList.add('error');
    } else {
      el.classList.add('error'); // default
    }
    this.showAnimation();
    if (this.flash_message.duration > 0) {
      this._timeout = setTimeout(
        this.endMessage,
        this.flash_message.duration,
        this
      );
    } else {
    }
  }

  private endMessage(context: FlashMessageComponent) {
    if (Utils.isValue(context._timeout)) {
      clearTimeout(context._timeout);
      context._timeout = undefined;
    }
    context._showing = false;
    context.hideAnimation();
    if (context._queuedflash_message.length > 0) {
      context.flash_message = context._queuedflash_message.shift();
      context.startMessage();
    }
  }
}
