<div class="app-contract-print-component">
  <button mat-flat-button class="primary" [disabled]="is_preparation_for_shipping" (click)="onClickContractPrinted($event)">Preparation for shipping</button><br/>
  <div class="contract_code">Contract code : {{contract_content.contract_summary.contract_code}}</div>
  <div class="user-info">
    <div class="section-title">Customer information</div>
    <table class="list">
      <tbody>
        <tr>
          <td class="row-title">Customer mail address</td>
          <td>{{contract_content.user_summary.email}}</td>
        </tr>
        <tr *ngIf="getUserPropertyValue('USER FULL NAME') !== null">
          <td class="row-title">{{getUserPropertyTitle('USER FULL NAME')}}</td>
          <td>{{getUserPropertyValue('USER FULL NAME')}}</td>
        </tr>
        <tr *ngIf="getUserPropertyValue('GMO CARD HOLDER NAME') !== null">
          <td class="row-title">{{getUserPropertyTitle('GMO CARD HOLDER NAME')}}</td>
          <td>{{getUserPropertyValue('GMO CARD HOLDER NAME')}}</td>
        </tr>
        <tr *ngIf="getUserPropertyValue('GMO USER ID') !== null">
          <td class="row-title">{{getUserPropertyTitle('GMO USER ID')}}</td>
          <td>{{getUserPropertyValue('GMO USER ID')}}</td>
        </tr>
        <tr *ngIf="getUserPropertyValue('ADDRESS') !== null">
          <td class="row-title">{{getUserPropertyTitle('ADDRESS')}}</td>
          <td>{{getUserPropertyValue('ADDRESS')}}</td>
        </tr>
        <tr *ngIf="getUserPropertyValue('TEL') !== null">
          <td class="row-title">{{getUserPropertyTitle('TEL')}}</td>
          <td>{{getUserPropertyValue('TEL')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="contract-info">
    <div class="section-title">Contract summaries</div>
    <table class="list">
      <tbody>
        <tr>
          <td class="row-title">Status</td>
          <td>{{getContractStatusString(contract_content.contract_summary.status)}}</td>
        </tr>
        <tr>
          <td class="row-title">Start date</td>
          <td>{{contract_content.contract_summary.start_at}}</td>
        </tr>
        <tr>
          <td class="row-title">End date</td>
          <td>{{contract_content.contract_summary.end_at}}</td>
        </tr>
        <tr *ngIf="getContractPropertyValue('CURRENT STATUS') !== null">
          <td class="row-title">{{getContractPropertyTitle('CURRENT STATUS')}}</td>
          <td>{{getContractPropertyValue('CURRENT STATUS')}}</td>
        </tr>
      </tbody>
    </table>
    <div class="section-title">Contract properties</div>
    <table class="list">
      <tbody>
        <tr *ngFor="let property of contract_content.contract_properties">
          <td *ngIf="getContractPropertyValue(property.key) !== null" class="row-title">{{getContractPropertyTitle(property.key)}}</td>
          <td *ngIf="getContractPropertyValue(property.key) !== null">{{getContractPropertyValue(property.key)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="contract-products-info">
    <div class="section-title">Contract items</div>
    <div *ngFor="let product_summary of contract_content.product_summaries">
      <div class="contract-item-title">{{product_summary.title}}</div>
      <!--
      <table class="contract_item">
        <tbody>
          <tr *ngFor="let product_property of getContractProductProperties(product_summary.contract_product_id)">
            <td *ngIf="getContractProductPropertyValue(product_property.contract_product_id, product_property.key) !== null" class="row-title">
              {{getContractProductPropertyTitle(product_property.contract_product_id, product_property.key)}}
            </td>
            <td *ngIf="getContractProductPropertyValue(product_property.contract_product_id, product_property.key) !== null">
              {{getContractProductPropertyValue(product_property.contract_product_id, product_property.key)}}
            </td>
          </tr>
        </tbody>
      </table>
      -->
    </div>
  </div>
  <div class="contract-memo">
    <div class="section-title">MEMO</div>
    <table class="list memo">
      <tbody>
        <tr *ngFor="let memo of memos">
          <td>{{memo.created_at}}</td>
          <td>{{memo.user_e_mail}}</td>
          <td [innerHTML]="memo.memo"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>