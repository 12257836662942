<div class="app-list-contract-memos">
  <table class="operation" style="width: 100%">
    <tr>
      <td style="text-align: right;">
        <button mat-mini-fab matTooltip="Add memo" (click)="onClickAddContractMemo($event)">
          <mat-icon aria-label="Add Memo">add</mat-icon>
        </button>
      </td>
    </tr>
  </table>
  <div *ngIf="memos && memos.length > 0" class="list-contract-memo-table-container mat-elevation-z8">
    <table>
      <div *ngFor="let memo of memos" class="list-contract-memos">
        <tr>
          <td class="created_at">
            {{memo.created_at.split('.')[0]}}
          </td>
          <td class="user_e_mail">
            {{memo.user_e_mail.split('@')[0]}}
          </td>
          <td class="delete_button">
            <button mat-icon-button class="delete_icon" (click)="onClickDeleteMemo(memo)">
              <mat-icon aria-label="Delete">delete_forever</mat-icon>
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="memo">
            <div [innerHTML]="memo.memo">

            </div>
          </td>
        </tr>
      </div>
    </table>
  </div>
</div>