import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Utils } from '../../../helpers';
import { EmailErrorStateMatcher } from '../../../helpers/email-error-state-matcher';

@Component({
  selector: 'app-edit-property-dialog-base',
  templateUrl: './edit-property-dialog-base.component.html',
  styleUrls: ['./edit-property-dialog-base.component.scss']
})
export class EditPropertyDialogBaseComponent implements OnInit {
  public input_value: string;
  public candidates: Array<any>;
  public button_ok_disable: boolean;
  public button_cancel_disable: boolean;
  public email_form_control: FormControl;
  public email_error_matcher: EmailErrorStateMatcher;
  public date_form_control: FormControl;

  constructor(
    public dialogRef: MatDialogRef<EditPropertyDialogBaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.input_value = '';
    this.button_ok_disable = true;
    this.button_cancel_disable = false;
    this.candidates = new Array<any>();
    this.email_form_control = new FormControl('', [
      Validators.required,
      Validators.email
    ]);
    this.email_error_matcher = new EmailErrorStateMatcher();
    this.date_form_control = new FormControl((new Date()).toISOString());
  }

  ngOnInit() {
    console.log('EditPropertyDialogBaseComponent::ngOnInit');
    console.log(this.data);
    this.input_value = this.data.value;
    switch (this.data.value_type) {

      case 'line_status':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : line_status(contract product) : ' + JSON.stringify(this.data));
        this.candidates.push({display: '未アクティベート', key: 'UNACTIVATED'});
        this.candidates.push({display: '正常', key: 'ENABLED'});
        this.candidates.push({display: '一時停止', key: 'PAUSED'});
        // this.candidates.push({display: '解約', key: 'CONTRACT ENDED'});
        // this.candidates.push({display: '強制解約', key: 'CONTRACT ABORTED'});
        break;

      case 'paymethod':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : paymethod : ' + JSON.stringify(this.data));
        this.candidates.push({display: 'CREDIT CARD', key: 'CREDIT CARD'});
        this.candidates.push({display: 'SMARTPIT', key: 'SMARTPIT'});
        this.candidates.push({display: 'PAYPAL', title: 'PAYPAL' });
        this.candidates.push({display: 'PONTA', key: 'PONTA'});
        this.candidates.push({display: 'GI BILL', key: 'GI BILL'});
        break;

      case 'zip':
      console.log('EditPropertyDialogBase::ngOnInit : TODO : zip');
        break;

      case 'tel':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : tel');
        break;

      case 'email':
        // https://angular.io/api/forms/FormControlDirective#use-with-ngmodel
        this.email_form_control.setValue(this.input_value);
        break;

      case 'address':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : address');
        break;

      case 'month':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : month');
        break;

      case 'date':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : date');
        console.log(this.input_value);
        // this.date_form_control.setValue(this.input_value);
        break;

      case 'datetime':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : datetime');
        break;

      case 'file':
      console.log('EditPropertyDialogBase::ngOnInit : TODO : file');
        break;

      case 'string':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : string');
        break;

      case 'text':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : text');
        break;

      case 'currency':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : currency');
        break;

      case 'number':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : number');
        break;

      case 'status':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : status');
        break;

      case 'sex':
        console.log('EditPropertyDialogBase::ngOnInit : sex : ' + JSON.stringify(this.data));
        this.candidates.push({display: '男性', key: 'Male'});
        this.candidates.push({display: '女性', key: 'Female'});
        break;

      case 'nationality':
        console.log('EditPropertyDialogBase::ngOnInit : nationality : ' + JSON.stringify(this.data));
        this.candidates.push({display: 'Unknown(不明)', key: '不明'});
        this.candidates.push({display: 'Abkhazia, Republic of(アブハジア)', key: 'アブハジア'});
        this.candidates.push({display: 'Afghanistan(アフガニスタン)', key: 'アフガニスタン'});
        this.candidates.push({display: 'Albania(アルバニア)', key: 'アルバニア'});
        this.candidates.push({display: 'Algeria(アルジェリア)', key: 'アルジェリア'});
        this.candidates.push({display: 'Andorra(アンドラ)', key: 'アンドラ'});
        this.candidates.push({display: 'Angola(アンゴラ)', key: 'アンゴラ'});
        this.candidates.push({display: 'Antigua and Barbuda(アンティグア・バーブーダ)', key: 'アンティグア・バーブーダ'});
        this.candidates.push({display: 'Argentina(アルゼンチン)', key: 'アルゼンチン'});
        this.candidates.push({display: 'Armenia(アルメニア)', key: 'アルメニア'});
        this.candidates.push({display: 'Australia(オーストラリア)', key: 'オーストラリア'});
        this.candidates.push({display: 'Austria(オーストリア)', key: 'オーストリア'});
        this.candidates.push({display: 'Azerbaijan(アゼルバイジャン)', key: 'アゼルバイジャン'});
        this.candidates.push({display: 'Bahamas(バハマ)', key: 'バハマ'});
        this.candidates.push({display: 'Bahrain(バーレーン)', key: 'バーレーン'});
        this.candidates.push({display: 'Bangladesh(バングラデシュ)', key: 'バングラデシュ'});
        this.candidates.push({display: 'Barbados(バルバドス)', key: 'バルバドス'});
        this.candidates.push({display: 'Belarus(ベラルーシ)', key: 'ベラルーシ'});
        this.candidates.push({display: 'Belgium(ベルギー)', key: 'ベルギー'});
        this.candidates.push({display: 'Belize(ベリーズ)', key: 'ベリーズ'});
        this.candidates.push({display: 'Benin(ベナン)', key: 'ベナン'});
        this.candidates.push({display: 'Bhutan(ブータン)', key: 'ブータン'});
        this.candidates.push({display: 'Bolivia, Plurinational State of(ボリビア)', key: 'ボリビア'});
        this.candidates.push({display: 'Bosnia and Herzegovina(ボスニア・ヘルツェゴビナ)', key: 'ボスニア・ヘルツェゴビナ'});
        this.candidates.push({display: 'Botswana(ボツワナ)', key: 'ボツワナ'});
        this.candidates.push({display: 'Brazil(ブラジル)', key: 'ブラジル'});
        this.candidates.push({display: 'Brunei Darussalam(ブルネイ)', key: 'ブルネイ'});
        this.candidates.push({display: 'Bulgaria(ブルガリア)', key: 'ブルガリア'});
        this.candidates.push({display: 'Burkina Faso(ブルキナファソ)', key: 'ブルキナファソ'});
        this.candidates.push({display: 'Burundi(ブルンジ)', key: 'ブルンジ'});
        this.candidates.push({display: 'Cambodia(カンボジア)', key: 'カンボジア'});
        this.candidates.push({display: 'Cameroon(カメルーン)', key: 'カメルーン'});
        this.candidates.push({display: 'Canada(カナダ)', key: 'カナダ'});
        this.candidates.push({display: 'Cape Verde(カーボベルデ)', key: 'カーボベルデ'});
        this.candidates.push({display: 'Central African Republic(中央アフリカ共和国)', key: '中央アフリカ共和国'});
        this.candidates.push({display: 'Chad(チャド)', key: 'チャド'});
        this.candidates.push({display: 'Chile(チリ)', key: 'チリ'});
        this.candidates.push({display: 'China(中国)', key: '中国'});
        this.candidates.push({display: 'Colombia(コロンビア)', key: 'コロンビア'});
        this.candidates.push({display: 'Comoros(コモロ)', key: 'コモロ'});
        this.candidates.push({display: 'Congo(コンゴ共和国)', key: 'コンゴ共和国'});
        this.candidates.push({display: 'Congo, the Democratic Republic of the(コンゴ民主共和国)', key: 'コンゴ民主共和国'});
        this.candidates.push({display: 'Cook Islands(クック諸島)', key: 'クック諸島'});
        this.candidates.push({display: 'Costa Rica(コスタリカ)', key: 'コスタリカ'});
        this.candidates.push({display: 'Côte d\'Ivoire(コートジボワール)', key: 'コートジボワール'});
        this.candidates.push({display: 'Croatia(クロアチア)', key: 'クロアチア'});
        this.candidates.push({display: 'Cuba(キューバ)', key: 'キューバ'});
        this.candidates.push({display: 'Cyprus(キプロス)', key: 'キプロス'});
        this.candidates.push({display: 'Czechia(チェコ)', key: 'チェコ'});
        this.candidates.push({display: 'Denmark(デンマーク)', key: 'デンマーク'});
        this.candidates.push({display: 'Djibouti(ジブチ)', key: 'ジブチ'});
        this.candidates.push({display: 'Dominica(ドミニカ国)', key: 'ドミニカ国'});
        this.candidates.push({display: 'Dominican Republic(ドミニカ共和国)', key: 'ドミニカ共和国'});
        this.candidates.push({display: 'Ecuador(エクアドル)', key: 'エクアドル'});
        this.candidates.push({display: 'Egypt(エジプト)', key: 'エジプト'});
        this.candidates.push({display: 'El Salvador(エルサルバドル)', key: 'エルサルバドル'});
        this.candidates.push({display: 'Equatorial Guinea(赤道ギニア)', key: '赤道ギニア'});
        this.candidates.push({display: 'Eritrea(エリトリア)', key: 'エリトリア'});
        this.candidates.push({display: 'Estonia(エストニア)', key: 'エストニア'});
        this.candidates.push({display: 'Ethiopia(エチオピア)', key: 'エチオピア'});
        this.candidates.push({display: 'Fiji(フィジー)', key: 'フィジー'});
        this.candidates.push({display: 'Finland(フィンランド)', key: 'フィンランド'});
        this.candidates.push({display: 'France(フランス)', key: 'フランス'});
        this.candidates.push({display: 'Gabon(ガボン)', key: 'ガボン'});
        this.candidates.push({display: 'Gambia(ガンビア)', key: 'ガンビア'});
        this.candidates.push({display: 'Georgia(ジョージア)', key: 'ジョージア'});
        this.candidates.push({display: 'Germany(ドイツ)', key: 'ドイツ'});
        this.candidates.push({display: 'Ghana(ガーナ)', key: 'ガーナ'});
        this.candidates.push({display: 'Greece(ギリシャ)', key: 'ギリシャ'});
        this.candidates.push({display: 'Grenada(グレナダ)', key: 'グレナダ'});
        this.candidates.push({display: 'Guatemala(グアテマラ)', key: 'グアテマラ'});
        this.candidates.push({display: 'Guinea(ギニア)', key: 'ギニア'});
        this.candidates.push({display: 'Guinea-Bissau(ギニアビサウ)', key: 'ギニアビサウ'});
        this.candidates.push({display: 'Guyana(ガイアナ)', key: 'ガイアナ'});
        this.candidates.push({display: 'Haiti(ハイチ)', key: 'ハイチ'});
        this.candidates.push({display: 'Holy See (Vatican City State)(バチカン)', key: 'バチカン'});
        this.candidates.push({display: 'Honduras(ホンジュラス)', key: 'ホンジュラス'});
        this.candidates.push({display: 'Hungary(ハンガリー)', key: 'ハンガリー'});
        this.candidates.push({display: 'Iceland(アイスランド)', key: 'アイスランド'});
        this.candidates.push({display: 'India(インド)', key: 'インド'});
        this.candidates.push({display: 'Indonesia(インドネシア)', key: 'インドネシア'});
        this.candidates.push({display: 'Iran, Islamic Republic of(イラン)', key: 'イラン'});
        this.candidates.push({display: 'Iraq(イラク)', key: 'イラク'});
        this.candidates.push({display: 'Ireland(アイルランド)', key: 'アイルランド'});
        this.candidates.push({display: 'Israel(イスラエル)', key: 'イスラエル'});
        this.candidates.push({display: 'Italy(イタリア)', key: 'イタリア'});
        this.candidates.push({display: 'Jamaica(ジャマイカ)', key: 'ジャマイカ'});
        this.candidates.push({display: 'Japan(日本)', key: '日本'});
        this.candidates.push({display: 'Jordan(ヨルダン)', key: 'ヨルダン'});
        this.candidates.push({display: 'Kazakhstan(カザフスタン)', key: 'カザフスタン'});
        this.candidates.push({display: 'Kenya(ケニア)', key: 'ケニア'});
        this.candidates.push({display: 'Kiribati(キリバス)', key: 'キリバス'});
        this.candidates.push({display: 'Korea, Democratic People\'s Republic of(北朝鮮)', key: '北朝鮮'});
        this.candidates.push({display: 'Korea, Republic of(韓国)', key: '韓国'});
        this.candidates.push({display: 'Kosovo(コソボ)', key: 'コソボ'});
        this.candidates.push({display: 'Kuwait(クウェート)', key: 'クウェート'});
        this.candidates.push({display: 'Kyrgyzstan(キルギス)', key: 'キルギス'});
        this.candidates.push({display: 'Lao People\'s Democratic Republic(ラオス)', key: 'ラオス'});
        this.candidates.push({display: 'Latvia(ラトビア)', key: 'ラトビア'});
        this.candidates.push({display: 'Lebanon(レバノン)', key: 'レバノン'});
        this.candidates.push({display: 'Lesotho(レソト)', key: 'レソト'});
        this.candidates.push({display: 'Liberia(リベリア)', key: 'リベリア'});
        this.candidates.push({display: 'Libya(リビア)', key: 'リビア'});
        this.candidates.push({display: 'Liechtenstein(リヒテンシュタイン)', key: 'リヒテンシュタイン'});
        this.candidates.push({display: 'Lithuania(リトアニア)', key: 'リトアニア'});
        this.candidates.push({display: 'Luxembourg(ルクセンブルク)', key: 'ルクセンブルク'});
        this.candidates.push({display: 'Macedonia, the former Yugoslav Republic(マケドニア旧ユーゴスラビア共和国)', key: 'マケドニア旧ユーゴスラビア共和国'});
        this.candidates.push({display: 'Madagascar(マダガスカル)', key: 'マダガスカル'});
        this.candidates.push({display: 'Malawi(マラウイ)', key: 'マラウイ'});
        this.candidates.push({display: 'Malaysia(マレーシア)', key: 'マレーシア'});
        this.candidates.push({display: 'Maldives(モルディブ)', key: 'モルディブ'});
        this.candidates.push({display: 'Mali(マリ)', key: 'マリ'});
        this.candidates.push({display: 'Malta(マルタ)', key: 'マルタ'});
        this.candidates.push({display: 'Marshall Islands(マーシャル諸島)', key: 'マーシャル諸島'});
        this.candidates.push({display: 'Mauritania(モーリタニア)', key: 'モーリタニア'});
        this.candidates.push({display: 'Mauritius(モーリシャス)', key: 'モーリシャス'});
        this.candidates.push({display: 'Mexico(メキシコ)', key: 'メキシコ'});
        this.candidates.push({display: 'Micronesia, Federated States of(ミクロネシア連邦)', key: 'ミクロネシア連邦'});
        this.candidates.push({display: 'Moldova, Republic of(モルドバ)', key: 'モルドバ'});
        this.candidates.push({display: 'Monaco(モナコ)', key: 'モナコ'});
        this.candidates.push({display: 'Mongolia(モンゴル)', key: 'モンゴル'});
        this.candidates.push({display: 'Montenegro(モンテネグロ)', key: 'モンテネグロ'});
        this.candidates.push({display: 'Morocco(モロッコ)', key: 'モロッコ'});
        this.candidates.push({display: 'Mozambique(モザンビーク)', key: 'モザンビーク'});
        this.candidates.push({display: 'Myanmar(ミャンマー)', key: 'ミャンマー'});
        this.candidates.push({display: 'Nagorno Karabagh Republic(ナゴルノ・カラバフ)', key: 'ナゴルノ・カラバフ'});
        this.candidates.push({display: 'Namibia(ナミビア)', key: 'ナミビア'});
        this.candidates.push({display: 'Nauru(ナウル)', key: 'ナウル'});
        this.candidates.push({display: 'Nepal(ネパール)', key: 'ネパール'});
        this.candidates.push({display: 'Netherlands(オランダ)', key: 'オランダ'});
        this.candidates.push({display: 'New Zealand(ニュージーランド)', key: 'ニュージーランド'});
        this.candidates.push({display: 'Nicaragua(ニカラグア)', key: 'ニカラグア'});
        this.candidates.push({display: 'Niger(ニジェール)', key: 'ニジェール'});
        this.candidates.push({display: 'Nigeria(ナイジェリア)', key: 'ナイジェリア'});
        this.candidates.push({display: 'Niue(ニウエ)', key: 'ニウエ'});
        this.candidates.push({display: 'Northern Cyprus(北キプロス)', key: '北キプロス'});
        this.candidates.push({display: 'Norway(ノルウェー)', key: 'ノルウェー'});
        this.candidates.push({display: 'Oman(オマーン)', key: 'オマーン'});
        this.candidates.push({display: 'Pakistan(パキスタン)', key: 'パキスタン'});
        this.candidates.push({display: 'Palau(パラオ)', key: 'パラオ'});
        this.candidates.push({display: 'Palestinian Territory(パレスチナ)', key: 'パレスチナ'});
        this.candidates.push({display: 'Panama(パナマ)', key: 'パナマ'});
        this.candidates.push({display: 'Papua New Guinea(パプアニューギニア)', key: 'パプアニューギニア'});
        this.candidates.push({display: 'Paraguay(パラグアイ)', key: 'パラグアイ'});
        this.candidates.push({display: 'Peru(ペルー)', key: 'ペルー'});
        this.candidates.push({display: 'Philippines(フィリピン)', key: 'フィリピン'});
        this.candidates.push({display: 'Poland(ポーランド)', key: 'ポーランド'});
        this.candidates.push({display: 'Portugal(ポルトガル)', key: 'ポルトガル'});
        this.candidates.push({display: 'Pridnestrovian Moldavian Republic(沿ドニエストル)', key: '沿ドニエストル'});
        this.candidates.push({display: 'Qatar(カタール)', key: 'カタール'});
        this.candidates.push({display: 'Romania(ルーマニア)', key: 'ルーマニア'});
        this.candidates.push({display: 'Russian Federation(ロシア)', key: 'ロシア'});
        this.candidates.push({display: 'Rwanda(ルワンダ)', key: 'ルワンダ'});
        this.candidates.push({display: 'Saint Kitts and Nevis(セントクリストファー・ネイビス)', key: 'セントクリストファー・ネイビス'});
        this.candidates.push({display: 'Saint Lucia(セントルシア)', key: 'セントルシア'});
        this.candidates.push({display: 'Saint Vincent and the Grenadines(セントビンセントおよびグレナディーン諸島)', key: 'セントビンセントおよびグレナディーン諸島'});
        this.candidates.push({display: 'Samoa(サモア)', key: 'サモア'});
        this.candidates.push({display: 'San Marino(サンマリノ)', key: 'サンマリノ'});
        this.candidates.push({display: 'Sao Tome and Principe(サントメ・プリンシペ)', key: 'サントメ・プリンシペ'});
        this.candidates.push({display: 'Saudi Arabia(サウジアラビア)', key: 'サウジアラビア'});
        this.candidates.push({display: 'Senegal(セネガル)', key: 'セネガル'});
        this.candidates.push({display: 'Serbia(セルビア)', key: 'セルビア'});
        this.candidates.push({display: 'Seychelles(セーシェル)', key: 'セーシェル'});
        this.candidates.push({display: 'Sierra Leone(シエラレオネ)', key: 'シエラレオネ'});
        this.candidates.push({display: 'Singapore(シンガポール)', key: 'シンガポール'});
        this.candidates.push({display: 'Slovakia(スロバキア)', key: 'スロバキア'});
        this.candidates.push({display: 'Slovenia(スロベニア)', key: 'スロベニア'});
        this.candidates.push({display: 'Solomon Islands(ソロモン諸島)', key: 'ソロモン諸島'});
        this.candidates.push({display: 'Somalia(ソマリア)', key: 'ソマリア'});
        this.candidates.push({display: 'Somaliland, Republic of(ソマリランド)', key: 'ソマリランド'});
        this.candidates.push({display: 'South Africa(南アフリカ)', key: '南アフリカ'});
        this.candidates.push({display: 'South Ossetia(南オセチア)', key: '南オセチア'});
        this.candidates.push({display: 'South Sudan(南スーダン)', key: '南スーダン'});
        this.candidates.push({display: 'Spain(スペイン)', key: 'スペイン'});
        this.candidates.push({display: 'Sri Lanka(スリランカ)', key: 'スリランカ'});
        this.candidates.push({display: 'Sudan(スーダン)', key: 'スーダン'});
        this.candidates.push({display: 'Suriname(スリナム)', key: 'スリナム'});
        this.candidates.push({display: 'Swaziland(スワジランド)', key: 'スワジランド'});
        this.candidates.push({display: 'Sweden(スウェーデン)', key: 'スウェーデン'});
        this.candidates.push({display: 'Switzerland(スイス)', key: 'スイス'});
        this.candidates.push({display: 'Syrian Arab Republic(シリア)', key: 'シリア'});
        this.candidates.push({display: 'Taiwan(台湾)', key: '台湾'});
        this.candidates.push({display: 'Tajikistan(タジキスタン)', key: 'タジキスタン'});
        this.candidates.push({display: 'Tanzania, United Republic of(タンザニア)', key: 'タンザニア'});
        this.candidates.push({display: 'Thailand(タイ)', key: 'タイ'});
        this.candidates.push({display: 'Timor-Leste(東ティモール)', key: '東ティモール'});
        this.candidates.push({display: 'Togo(トーゴ)', key: 'トーゴ'});
        this.candidates.push({display: 'Tonga(トンガ)', key: 'トンガ'});
        this.candidates.push({display: 'Trinidad and Tobago(トリニダード・トバゴ)', key: 'トリニダード・トバゴ'});
        this.candidates.push({display: 'Tunisia(チュニジア)', key: 'チュニジア'});
        this.candidates.push({display: 'Turkey(トルコ)', key: 'トルコ'});
        this.candidates.push({display: 'Turkmenistan(トルクメニスタン)', key: 'トルクメニスタン'});
        this.candidates.push({display: 'Tuvalu(ツバル)', key: 'ツバル'});
        this.candidates.push({display: 'Uganda(ウガンダ)', key: 'ウガンダ'});
        this.candidates.push({display: 'Ukraine(ウクライナ)', key: 'ウクライナ'});
        this.candidates.push({display: 'United Arab Emirates(アラブ首長国連邦)', key: 'アラブ首長国連邦'});
        this.candidates.push({display: 'United Kingdom(イギリス)', key: 'イギリス'});
        this.candidates.push({display: 'United States(アメリカ)', key: 'アメリカ'});
        this.candidates.push({display: 'Uruguay(ウルグアイ)', key: 'ウルグアイ'});
        this.candidates.push({display: 'Uzbekistan(ウズベキスタン)', key: 'ウズベキスタン'});
        this.candidates.push({display: 'Vanuatu(バヌアツ)', key: 'バヌアツ'});
        this.candidates.push({display: 'Venezuela, Bolivarian Republic of(ベネズエラ)', key: 'ベネズエラ'});
        this.candidates.push({display: 'Viet Nam(ベトナム)', key: 'ベトナム'});
        this.candidates.push({display: 'Western Sahara(西サハラ)', key: '西サハラ'});
        this.candidates.push({display: 'Yemen(イエメン)', key: 'イエメン'});
        this.candidates.push({display: 'Zambia(ザンビア)', key: 'ザンビア'});
        this.candidates.push({display: 'Zimbabwe(ジンバブエ)', key: 'ジンバブエ'});
        break;

      case 'status_of_residence':
        console.log('EditPropertyDialogBase::ngOnInit : status_of_residence : ' + JSON.stringify(this.data));
        this.candidates.push({display: '不明', key: '不明'});
        this.candidates.push({display: '留学', key: '留学'});
        this.candidates.push({display: '教育', key: '教育'});
        this.candidates.push({display: '技術・人文知識・国際業務', key: '技術・人文知識・国際業務'});
        this.candidates.push({display: '特定活動', key: '特定活動'});
        this.candidates.push({display: '米軍', key: '米軍'});
        this.candidates.push({display: '文化活動', key: '文化活動'});
        this.candidates.push({display: '家族滞在', key: '家族滞在'});
        this.candidates.push({display: '永住者', key: '永住者'});
        this.candidates.push({display: '日本人の配偶者等', key: '日本人の配偶者等'});
        this.candidates.push({display: '永住者の配偶者等', key: '永住者の配偶者等'});
        this.candidates.push({display: '経営・管理', key: '経営・管理'});
        this.candidates.push({display: '外交', key: '外交'});
        this.candidates.push({display: '公用', key: '公用'});
        this.candidates.push({display: '教授', key: '教授'});
        this.candidates.push({display: '芸術', key: '芸術'});
        this.candidates.push({display: '宗教', key: '宗教'});
        this.candidates.push({display: '報道', key: '報道'});
        this.candidates.push({display: '高度専門職１号イ', key: '高度専門職１号イ'});
        this.candidates.push({display: '高度専門職１号ロ', key: '高度専門職１号ロ'});
        this.candidates.push({display: '高度専門職１号ハ', key: '高度専門職１号ハ'});
        this.candidates.push({display: '高度専門職２号', key: '高度専門職２号'});
        this.candidates.push({display: '法律・会計業務', key: '法律・会計業務'});
        this.candidates.push({display: '医療', key: '医療'});
        this.candidates.push({display: '研究', key: '研究'});
        this.candidates.push({display: '企業内転勤', key: '企業内転勤'});
        this.candidates.push({display: '介護', key: '介護'});
        this.candidates.push({display: '興行', key: '興行'});
        this.candidates.push({display: '技能', key: '技能'});
        this.candidates.push({display: '特定技能1号', key: '特定技能1号'});
        this.candidates.push({display: '特定技能2号', key: '特定技能2号'});
        this.candidates.push({display: '技能実習１号イ', key: '技能実習１号イ'});
        this.candidates.push({display: '技能実習１号ロ', key: '技能実習１号ロ'});
        this.candidates.push({display: '技能実習２号イ', key: '技能実習２号イ'});
        this.candidates.push({display: '技能実習２号ロ', key: '技能実習２号ロ'});
        this.candidates.push({display: '技能実習３号イ', key: '技能実習３号イ'});
        this.candidates.push({display: '技能実習３号ロ', key: '技能実習３号ロ'});
        this.candidates.push({display: '研修', key: '研修'});
        this.candidates.push({display: '定住者', key: '定住者'});
        this.candidates.push({display: '特別永住者', key: '特別永住者'});
        break;

      case 'fiber_plan_type':
        console.log('EditPropertyDialogBase::ngOnInit : fiber_plan_type : ' + JSON.stringify(this.data));
        this.candidates.push({display: 'ファミリータイプSHS', key: 'ファミリータイプSHS'});
        this.candidates.push({display: 'マンションタイプSHS', key: 'マンションタイプSHS'});
        this.candidates.push({display: 'マンションタイプVDSL', key: 'マンションタイプVDSL'});
        break;

      case 'fiber_installation_type':
        console.log('EditPropertyDialogBase::ngOnInit : fiber_installation_type : ' + JSON.stringify(this.data));
        this.candidates.push({display: 'リモート', key: 'リモート'});
        this.candidates.push({display: '派遣', key: '派遣'});
        break;

      case 'fiber_rental_equipment':
        console.log('EditPropertyDialogBase::ngOnInit : fiber_rental_equipment : ' + JSON.stringify(this.data));
        this.candidates.push({display: 'TP-Link/ONU', key: 'TP-Link/ONU'});
        this.candidates.push({display: 'HGW/ONU', key: 'HGW/ONU'});
        break;

      case 'smartpit_number':
        break;

      default:
      console.log('EditPropertyDialogBase::ngOnInit : Warning : unknown type');
        break;
    }
  }

  public onOkClicked(event) {
    console.log('EditPropertyDialogBaseComponent::onOkClicked - should be override');
    this.dialogRef.close();
  }

  public onValueChanged(event) {
    console.log('EditPropertyDialogBaseComponent::onValueChanged : new value = ' + event.value);
    if (event.value === this.data.value) {
      this.button_ok_disable = true;
    } else {
      this.button_ok_disable = false;
    }
    switch (this.data.value_type) {
      case 'line_status':
        this.input_value = event.value;
        break;

      case 'paymethod':
        this.input_value = event.value;
        break;

      case 'zip':
        break;

      case 'tel':
        console.log('EditPropertyDialogBaseComponent::onValueChanged : case tel');
        console.log(this.input_value);
        this.button_ok_disable = !Utils.isSimpleTelString(this.input_value);
        break;

      case 'email':
        // https://angular.io/api/forms/FormControlDirective#use-with-ngmodel
        this.input_value = this.email_form_control.value;
        if (this.input_value === this.data.value) {
          this.button_ok_disable = true;
        } else {
          this.button_ok_disable = this.email_form_control.hasError('email') || this.email_form_control.hasError('required');
        }
        return;
      case 'address':
        break;

      case 'month':
        break;

      case 'date':
        console.log('EditPropertyDialogBase::ngOnInit : TODO : date');
        this.input_value = Utils.dateToDateString(event.value);
        console.log(this.input_value);
        break;

      case 'datetime':
        break;

      case 'file':
        break;

      case 'string':
        break;

      case 'text':
        break;

      case 'currency':
        break;

      case 'number':
        break;

      case 'status':
        break;

      case 'sex':
        this.input_value = event.value;
        break;

      case 'nationality':
        this.input_value = event.value;
        break;

      case 'status_of_residence':
        this.input_value = event.value;
        break;

      case 'fiber_plan_type':
        this.input_value = event.value;
        break;

      case 'fiber_installation_type':
        this.input_value = event.value;
        break;

      case 'fiber_rental_equipment':
        this.input_value = event.value;
        break;

      case 'smartpit_number':
        this.input_value = this.input_value.match(/[0-9]*/g).join('').substring(0, 13);
        break;

      default:
        break;
    }
  }

  public onCancelClicked(event) {
    console.log('EditPropertyDialogBaseComponent::onCancelClicked');
    this.dialogRef.close();
  }
}
