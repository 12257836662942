<table style="width: 100%">
  <tr>
    <td colspan="2">
      {{data.title}}
    </td>
  </tr>
  <tr>
    <td colspan="2">
      {{data.message}}
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <mat-form-field class="full_width">
        <input matInput [matDatepicker]="myDatePicker" placeholder="Date" [value]="selected_date" (dateChange)="onValueChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="myDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatePicker></mat-datepicker>
      </mat-form-field>
    </td>
  </tr>
  <tr>
    <td colspan="2" style="text-align: right">
      <button mat-flat-button class="yes" (click)="onOkClicked($event)">OK</button>
      <button mat-flat-button class="no" (click)="onCancelClicked($event)">Cancel</button>
    </td>
  </tr>
</table>
