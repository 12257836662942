<div class="app-invoice">
  <table class="page">
    <tr>
      <td>
          <button mat-flat-button class="warn" (click)="onResendInvoice($event)">(Re) send</button>
      </td>
    </tr>
    <tr>
      <!-- header information -->
      <td>
        <table class="header">
          <tr>
            <td rowspan="2">
              <!-- sakura mobile logo-->
              <!-- <img class="logo" src="./sakuramobile-doc-logo.png"> -->
              <!-- contract info -->
              <table class="contract_info">
                <tr>
                  <td class="title">Company：</td>
                  <td>Og inc. (Invoice Registration Number：T5030001101731)</td>
                </tr>
                <tr>
                  <td class="title">Address : </td>
                  <td>Zenken Plaza II 6th Fl. 1-3-13, Nishishinjuku, Shinjuku-ku, Tokyo, Japan</td>
                </tr>
                <tr>
                  <td class="title">e-mail : </td>
                  <td>contact@sakuramobile.jp</td>
                </tr>
                <tr>
                  <td>Website : </td>
                  <td>www.sakuramobile.jp</td>
                </tr>
                <tr>
                  <td class="title">Phone : </td>
                  <td>03-5937-5869</td>
                </tr>
                <tr>
                  <td class="title">Fax : </td>
                  <td>050-3737-7625</td>
                </tr>
              </table>
            </td>
            <td class="doc_title">
              <!-- Invoice-->
              Invoice
            </td>
          </tr>
          <tr>
            <td class="invoice_info">
              <!-- invoice info -->
              <table class="invoice_info">
                <tr>
                  <td class="title">Contract code :</td>
                  <td>{{invoice_content && invoice_content.detail.contract_code}}</td>
                </tr>
                <tr>
                  <td class="title">Invoice number :</td>
                  <td>{{invoice_content && invoice_content.detail.invoice_code}}</td>
                </tr>
                <tr>
                  <td class="title">Date :</td>
                  <td>{{invoice_content && invoice_content.detail.invoice_date}}</td>
                </tr>
                <tr>
                  <td class="title">Due :</td>
                  <td>{{invoice_content && invoice_content.detail.invoice_due_date}}</td>
                </tr>
              </table>

            </td>
          </tr>
          <tr>
            <td colspan="2" class="customer_info">
              <table>
                <tr>
                  <td class="title">Customer :</td>
                  <td>{{invoice_content && invoice_content.detail.user_name}}</td>
                </tr>
                <tr>
                  <td class="title"></td>
                  <td>{{invoice_content && invoice_content.detail.user_e_mail}}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr class="content_space">
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>
        <div class="invoice_item_list">
          <div class="list_item">
            <div class="list_header">
              <div class="description">Description</div>
              <div class="amount">Amount</div>
            </div>
          </div>
          <div *ngFor="let item of invoice_content.list">
            <div class="list_item" [ngSwitch]="item.item_type">
              <div class="title" *ngSwitchCase="ref_InvoiceItemType.TITLE">
                <div class="description">
                  {{item.description}}
                </div>
              </div>

              <div class="product" *ngSwitchCase="ref_InvoiceItemType.PRODUCT">
                <div class="description">{{item.description}}</div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>

              <div class="property" *ngSwitchCase="ref_InvoiceItemType.PROPERTY">
                <div class="description">
                  <button mat-icon-button class="edit_icon" (click)="onClickEditProductProperty(item)">
                    <mat-icon aria-label="Edit property">edit</mat-icon>
                  </button>
                  {{item.description}}
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>

              <div class="product_price" *ngSwitchCase="ref_InvoiceItemType.PRODUCT_PRICE">
                <div class="description">
                  <button mat-icon-button class="add_icon" (click)="onClickAddProductProperty(item)">
                    <mat-icon aria-label="Add property">add_circle_outline</mat-icon>
                  </button>
                  <button mat-icon-button class="edit_icon" (click)="onClickEditProductProperty(item)">
                    <mat-icon aria-label="Edit property">edit</mat-icon>
                  </button>
                  {{item.description}}
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>

              <div class="product_tax" *ngSwitchCase="ref_InvoiceItemType.PRODUCT_TAX">
                <div class="description">
                  {{item.description}}S
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>
              <div class="property_price" *ngSwitchCase="ref_InvoiceItemType.PROPERTY_PRICE">
                <div class="description">
                  <button mat-icon-button class="edit_icon" (click)="onClickEditProductProperty(item)">
                    <mat-icon aria-label="Edit property">edit</mat-icon>
                  </button>
                  {{item.description}}
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>
              <div class="property_tax" *ngSwitchCase="ref_InvoiceItemType.PROPERTY_TAX">
                <div class="description">
                  {{item.description}}
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>

              <div class="sub_total" *ngSwitchCase="ref_InvoiceItemType.SUB_TOTAL">
                <div class="description">
                  {{item.description}}
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>

              <div class="sub_total_tax" *ngSwitchCase="ref_InvoiceItemType.SUB_TOTAL_TAX">
                <div class="description">
                  {{item.description}}
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>

              <div class="total" *ngSwitchCase="ref_InvoiceItemType.TOTAL">
                <div class="description">
                  {{item.description}}
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>

              <div class="note" *ngSwitchCase="ref_InvoiceItemType.NOTE">
                <div class="description">
                  <button mat-icon-button class="edit_icon" (click)="onClickEditProductProperty(item)">
                    <mat-icon aria-label="Edit property">edit</mat-icon>
                  </button>
                  {{item.description}}
                </div>
                <div class="amount">{{yen(item.amount)}}</div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <!-- subtotal / vat -->

    </tr>
  </table>
</div>