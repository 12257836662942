import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';

export class SelectDialogItem {
  key: string;
  title: string;
}

export class SelectDialogData {
  title: string;
  message: string;
  placeholder: string;
  items: Array<SelectDialogItem>;
  current_item_key: string;
}

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})

export class SelectDialogComponent implements OnInit {

  protected selecting_item: SelectDialogItem;
  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectDialogData
  ) {
  }

  ngOnInit() {
    console.log(this.data);
  }

  public onCancelClicked(event): void {
    console.log('SelectDialogComponent::onCancelClicked -start');
    this.dialogRef.close({
      closeby: 'cancel'
    });
  }

  public onOkClicked(event): void {
    console.log('SelectDialogComponent::onOkClicked -start');
    this.dialogRef.close({
      closeby: 'ok',
      selected_item: this.selecting_item
    });
  }

  public onChangedSelectingItem(event): void {
    console.log('SelectDialogComponent::onChangedSelectingItem -start');
    const filtered = this.data.items.filter(v => {
      return v.key === event.value;
    });
    if (Utils.isValue(filtered) && filtered.length > 0) {
      this.selecting_item = filtered[0];
    }
  }
}
