export class Duration {
    public days: number;
    public hours: number;
    public minutes: number;
    public seconds: number;
    public msec: number;
    public total_msec: number;

    constructor(
      msec: number
    ) {
      let excess = msec;
      this.days = Math.floor(excess / (24 * 60 * 60 * 1000));
      excess -= this.days * (24 * 60 * 60 * 1000);
      this.hours = Math.floor(excess / (60 * 60 * 1000));
      excess -= this.hours * (60 * 60 * 1000);
      this.minutes = Math.floor(excess / (60 * 1000));
      excess -= this.minutes * (60 * 1000);
      this.seconds = Math.floor(excess / (1000));
      excess -= this.seconds * 1000;
      this.msec = excess;
      this.total_msec = msec;
    }
}
