export class SearchTextListItem {
    contract_id: number;
    contract_code: string;
    category: string;
    title: string;
    value: boolean;
}

export class SearchFastTextListItem {
    contract_id: number;
    contract_code: string;
    subscription_plan: string;
    contract_status: string;
    contract_start_at: string;
    contract_end_at: string;
    email: string;
    user_full_name: string;
    found_value: string;
}

export class SearchTextInUsersListItem {
    user_id: number;
    email: string;
    title: string;
    value: boolean;
}

export class SearchTextInContractsListItem {
    user_id: number;
    contract_code: string;
    title: string;
    value: boolean;
}
