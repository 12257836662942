<div class="app-admin-tool">
  <table>
    <tr class="api_form">
      <td>
        契約の削除
      </td>
      <td>
        <table>
          <tr>
            <td>
              契約コード
            </td>
            <td>
              <input type="text" [(ngModel)]="delete_contract_contract_code"/>
            </td>
            <td>
              <button (click)="onClickDeleteContract()">実行する</button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <!-- >>> start : 契約プロパティの追加 -->
    <tr class="api_form">
      <td>
        契約プロパティの追加
      </td>
      <td>
        <table>
          <tr>
            <td>
              value_type
            </td>
            <td>
              <input type="text" [(ngModel)]="add_contract_property_type_value_type"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              key
            </td>
            <td>
              <input type="text" [(ngModel)]="add_contract_property_type_key"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              title
            </td>
            <td>
              <input type="text" [(ngModel)]="add_contract_property_type_title"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              description
            </td>
            <td>
              <input type="text" [(ngModel)]="add_contract_property_type_description"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              value_behavior
            </td>
            <td>
              <input type="text" [(ngModel)]="add_contract_property_type_value_behavior"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              default_value
            </td>
            <td>
              <input type="text" [(ngModel)]="add_contract_property_type_default_value"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              show_order
            </td>
            <td>
              <input type="text" [(ngModel)]="add_contract_property_type_show_order"/>
            </td>
            <td>
              <button (click)="onClickAddContractPropertyType()">実行する</button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <!-- <<< end : 契約プロパティの追加 -->
    <!-- >>> start : 商品の追加 -->
    <tr class="api_form">
      <td>
        商品の追加
      </td>
      <td>
        <table>
          <tr>
            <td>
              title
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_type_title"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              description
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_type_description"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              invoice_sender_key
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_type_invoice_sender_key"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              show_order
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_type_show_order"/>
            </td>
            <td>
              <button (click)="onClickAddProductType()">実行する</button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <!-- <<< end : 契約プロパティの追加 -->
    <!-- >>> start : 商品プロパティの追加 -->
    <tr class="api_form">
      <td>
        商品プロパティの追加
      </td>
      <td>
        <table>
          <tr>
            <td>
              value_type
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_property_type_value_type"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              key
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_property_type_key"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              title
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_property_type_title"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              description
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_property_type_description"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              value_behavior
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_property_type_value_behavior"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              show_order
            </td>
            <td>
              <input type="text" [(ngModel)]="add_product_property_type_show_order"/>
            </td>
            <td>
              <button (click)="onClickAddProductPropertyType()">実行する</button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <!-- <<< end : 商品プロパティの追加 -->
    <tr class="api_form">
      <td>
        追加した商品プロパティの商品への適用
      </td>
      <td>
        <table>
          <tr>
            <td>
              product_property_type_id
            </td>
            <td>
                <input type="text" [(ngModel)]="apply_product_property_type_product_property_type_id"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              product_type_id
            </td>
            <td>
              <input type="text" [(ngModel)]="apply_product_property_type_product_id"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              default_value
            </td>
            <td>
              <input type="text" [(ngModel)]="apply_product_property_type_default_value"/>
            </td>
            <td>
              <button (click)="onClickApplyProductPropertyTypeToProduct()">実行する</button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr class="api_form">
      <td>
        チェックフロントの契約情報取得
      </td>
      <td>
        <table>
          <tr>
            <td>
              contract_code
            </td>
            <td>
                <input type="text" [(ngModel)]="checkfront_api_test_contract_id"/>
            </td>
            <td>
              <button (click)="onClickCheckfrontAPITest()">実行する</button>
            </td>
          </tr>
        </table>
        <hr>
        解析内容
        <table mat-table [dataSource]="booking_sim_infos">
            <ng-container matColumnDef="booking_id">
              <th mat-header-cell *matHeaderCellDef>booking id</th>
              <td mat-cell *matCellDef="let booking_sim_info">
                {{booking_sim_info.booking_id}}
              </td>
            </ng-container>
            <ng-container matColumnDef="sim_id0">
              <th mat-header-cell *matHeaderCellDef>sim</th>
              <td mat-cell *matCellDef="let booking_sim_info">
                  {{booking_sim_info.sim_ids.length > 0 ? booking_sim_info.sim_ids[0] : ''}}
              </td>
            </ng-container>
            <ng-container matColumnDef="sim_id1">
              <th mat-header-cell *matHeaderCellDef>sim</th>
              <td mat-cell *matCellDef="let booking_sim_info">
                  {{booking_sim_info.sim_ids.length > 1 ? booking_sim_info.sim_ids[1] : ''}}
              </td>
            </ng-container>
            <ng-container matColumnDef="sim_id2">
              <th mat-header-cell *matHeaderCellDef>sim</th>
              <td mat-cell *matCellDef="let booking_sim_info">
                  {{booking_sim_info.sim_ids.length > 2 ? booking_sim_info.sim_ids[2] : ''}}
              </td>
            </ng-container>
            <ng-container matColumnDef="sim_id3">
              <th mat-header-cell *matHeaderCellDef>sim</th>
              <td mat-cell *matCellDef="let booking_sim_info">
                  {{booking_sim_info.sim_ids.length > 3 ? booking_sim_info.sim_ids[3] : ''}}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
            <tr mat-row *matRowDef="let booking_sim_info; columns: display_columns;"></tr>
        </table>
      </td>
    </tr>
    <!-- <<< end : 商品プロパティの追加 -->
    <tr class="api_form">
      <td>
        JSONデータの送信
      </td>
      <td>
        <table>
          <tr>
            <td>
              POST送信？
            </td>
            <td>
                <input type="checkbox" [(ngModel)]="is_post"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              送信先URL
            </td>
            <td>
                <input type="text" [(ngModel)]="json_send_test_url"/>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              送信データ
            </td>
            <td>
              <textarea [(ngModel)]="json_send_test_send_data" ></textarea>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>
              受信データ(レスポンス)
            </td>
            <td>
              <textarea [(ngModel)]="json_send_test_response_data" ></textarea>
            </td>
            <td>
              <button (click)="onClickSendJSON()">実行する</button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>