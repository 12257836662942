import { Component, Injectable, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth';
import { VisibleService, FlashMessageService } from '../../../services/misc';
import { Visible } from '../../../constants';
import { AuthGetReply } from '../../../models';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  // 入力内容
  public input_user_id: string;
  public input_password: string;
  public input_password_confirmation: string;

  // サービス読み取り
  private _is_logging_in: boolean;
  private _subscription_is_logging_in: Subscription;

  private _invisible: boolean;
  private _subscription_invisible: Subscription;

  constructor(
    private auth_service: AuthService,
    private visible_service: VisibleService,
    private flash_service: FlashMessageService
  ) { }

  ngOnInit() {
    this._subscription_is_logging_in = this.auth_service.logging_in$.subscribe(
      logging_in => {
        console.log('LoginComponent::_subscription_is_logging_in : logging_in=' + logging_in);
        if (logging_in === true) {
          console.log('LoginComponent::_subscription_is_logging_in (success to login): logging_in=' + logging_in);
          location.href = '/operation'; // TODO : check user role and redirect url
        } else {
          console.log('LoginComponent::_subscription_is_logging_in (failed to login): logging_in=' + logging_in);
        }
      }
    );

    this._subscription_invisible = this.visible_service.login$.subscribe(
      invisible => {
        console.log('LoginComponent::_subscription_invisible : invisible=' + invisible);
        const el = document.querySelector(
          'div.app-login'
        );
        if (this._invisible === Visible.HIDDEN || this._invisible === undefined) {
          el.classList.remove('invisible');
          if (invisible === Visible.HIDDEN) {
            el.classList.add('invisible');
            // invisible => invisible (nothing to do)
          } else {
            // invisible to visible => TODO : initialize display data
          }
        } else {
          if (invisible === Visible.HIDDEN) {
            el.classList.add('invisible');
            // visible => invisible => TODO : clear data
          } else {
            // visible => visible (nothing to do)
          }
        }
        this._invisible = invisible;
      }
    );
    // initialize login status here!
    this.auth_service.initialize();
  }

  public onClickSignUp(event) {
    this.auth_service.signup(
        this.input_user_id,
        this.input_password,
        this.input_password_confirmation,
    );
  }
}
