<div class="flash-message" style="display: none">
    <div class="header">
        <table>
            <tr>
                <td class="title">
                    <div *ngIf="flash_message">
                        {{flash_message.title}}
                    </div>
                </td>
                <td class="close">
                    <img src="./assets/img/b-circle-x.png" (click)="onCloseClicked($event)">
                </td>
            </tr>
        </table>
    </div>
    <hr>
    <div *ngIf="flash_message">
        <div class="description" [innerHTML]="flash_message.message"></div>
    </div>
</div>