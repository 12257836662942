import { Injectable } from '@angular/core';
import { FlashMessage } from '../../models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlashMessageService {
  public flash_message = new Subject<FlashMessage>();
  public flash_message$ = this.flash_message.asObservable();

  public startflash_message(
    status: string,
    level: string,
    title: string,
    message: string,
    duration: number
  ) {
    // console.log('FlashMessageService::startflash_message - start');
    this.flash_message.next(new FlashMessage(
      status,
      level,
      title,
      message,
      duration
    ));
  }
  constructor() {}
}
