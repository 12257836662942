import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { FlashMessage, AuthGetReply } from '../../../models';
import { Utils } from '../../../helpers';

export class AddContractMemoDialogData {
  contract_id: number;
}

@Component({
  selector: 'app-add-contract-memo-dialog',
  templateUrl: './add-contract-memo-dialog.component.html',
  styleUrls: ['./add-contract-memo-dialog.component.scss']
})
export class AddContractMemoDialogComponent implements OnInit {
  public memo: string;
  public to_be_contract_note: boolean;
  public button_add_disable: boolean;
  public button_cancel_disable: boolean;

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddContractMemoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddContractMemoDialogData
  ) {
    this.to_be_contract_note = false;
    this.button_add_disable = true;
    this.button_cancel_disable = false;
  }

  ngOnInit() {}
  public onMemoKeyUp(memo: string) {
    if (Utils.isValue(memo) && memo.length > 0) {
      this.button_add_disable = false;
    }
  }

  public onClickAdd(event): void {
    const param = {
      contract_id: this.data.contract_id,
      memo: this.memo,
      to_be_contract_note: this.to_be_contract_note
    };
    this.auth_service.apiAddContractMemo(param, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      console.log(reply);
      context.snackBar.dismiss();
      const cfg = new MatSnackBarConfig();
      cfg.duration = 2000;
      cfg.panelClass = ['notify_snackbar', 'success'];
      context.snackBar.open(
        'Contract memo added.',
        'OK',
        cfg
      );
      context.dialogRef.close({
        added: true
      });
    });
  }

  public onClickCancel(event): void {
    this.dialogRef.close();
  }
}
