import { Component, OnInit, ElementRef } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatDialog,
} from '@angular/material';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Utils } from '../../../helpers';
import { AuthService } from '../../../services/auth';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent implements OnInit {

  public close_month: string;
  public close_month_error: string;
  public close_line_button_disabled: boolean;

  protected _el: HTMLElement;

  public confirm_dialog_ref: any;

  constructor(
    protected el: ElementRef,
    private http: HttpClient,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this._el = el.nativeElement;
    this.close_line_button_disabled = true;
  }

  ngOnInit() {
  }

  public onCloseMonthChanged(close_month: string): void {
    console.log('UtilitiesComponent::onCloseMonthChanged');
    this.close_month = close_month;
    this.updateCloseLineButtonDisabled();
  }

  public onClickCloseLine(event: any): void {
    console.log('UtilitiesComponent::onClickCloseLine');

    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '回線閉鎖登録の確認',
        message: this.close_month + '内に契約終了日が設定されている契約が使用する、全ての電話番号を持つ回線の閉鎖を登録します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.auth_service.apiCloseLinesAtMonth({cancel_line_month: this.close_month}).subscribe(
            response => {
              console.log(response);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 2000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open(response.message.message, 'OK', cfg);
            },
            error => {
              console.log(error);
              this.snackBar.dismiss();
              const cfg = new MatSnackBarConfig();
              cfg.duration = 5000;
              cfg.panelClass = ['notify_snackbar', 'error'];
              this.snackBar.open(error.error.message.message, 'OK', cfg);
            }
          );
        }
      }
    });
  }

  private updateCloseLineButtonDisabled() {
    console.log('UtilitiesComponent::updateCloseLineButtonDisabled');
    this.close_line_button_disabled = !Utils.isValue(this.close_month);
  }
}
