import { Component, OnInit } from '@angular/core';
import { NgSwitch } from '@angular/common';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import {
  InvoiceContent,
  InvoiceContentListItemType,
  FlashMessage,
  AuthGetReply
} from '../../../models';
import { Utils } from '../../../helpers';
import { Subscription } from 'rxjs';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import { EditInvoiceProductPropertyDialogComponent } from '../../dialog/edit-invoice-product-property-dialog/edit-invoice-product-property-dialog.component';
import {
  AddInvoiceProductPropertyDialogComponent,
  AddInvoiceProductPropertyDialogData
} from '../../dialog/add-invoice-product-property-dialog/add-invoice-product-property-dialog.component';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  public invoice_id: number;
  public contract_code: string;
  public invoice_month: string;
  public invoice_content: InvoiceContent;
  public ref_InvoiceItemType = InvoiceContentListItemType; // これはダサい...

  protected _el: HTMLElement;

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this._el = el.nativeElement;
    this.invoice_content = new InvoiceContent();
  }

  ngOnInit() {
    if (this.auth_service.isLoggingIn() !== true) {
      location.href = '/auth/login';
      return;
    }
    this.auth_service.apiIsMemberUser('operator', this, function(
      reply: AuthGetReply,
      context: any
    ) {
      if (reply.data.is_member === true) {
      } else {
        location.href = '/auth/login';
      }
    });
    this.contract_code = Utils.getUrlParameter('contract_code');
    this.invoice_month = Utils.getUrlParameter('invoice_month');
    if (
      Utils.isValue(this.contract_code) &&
      Utils.isValue(this.invoice_month)
    ) {
      this.refresh();
    }
  }

  public refresh() {
    const params = {
      contract_code: this.contract_code,
      invoice_month: this.invoice_month
    };

    this.auth_service.apiGetInvoiceContent(params, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      context.invoice_content = <InvoiceContent>reply.data;
      console.log(context.invoice_content);
    });
  }

  public onClickAddProductProperty(event): void {
    console.log('ContractComponent::onClickAddProductProperty');
    console.log(event);
    const dialogAddRef = this.dialog.open(
      AddInvoiceProductPropertyDialogComponent,
      {
        width: '30rem',
        hasBackdrop: true,
        data: event
      }
    );

    dialogAddRef.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.applied === true) {
          this.refresh();
        }
      }
    });
  }

  public onClickEditProductProperty(event): void {
    console.log('ContractComponent::onClickEditProductProperty');
    console.log(event);
    const dialogAddRef = this.dialog.open(
      EditInvoiceProductPropertyDialogComponent,
      {
        width: '30rem',
        hasBackdrop: true,
        data: event
      }
    );

    dialogAddRef.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.applied === true) {
          this.refresh();
        }
      }
    });
  }

  public onRegenerateInvoice(event): void {
    console.log('InvoiceComponent::onRegenerateInvoice');
    const params = {
      contract_code: this.contract_code,
      invoice_month: this.invoice_month
    };
    this.auth_service.apiGenerateInvoice(params, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      console.log(reply);
    });
  }

  public onResendInvoice(event): void {
    console.log('InvoiceComponent::onResendInvoice');
    const params = {
      invoice_id: this.invoice_content.detail.invoice_id
    };
    this.auth_service.apiSendInvoice(params, this, function(
      reply: any,
      context: any
    ) {
      console.log('InvoiceComponent::onResendInvoice : got reply');
      console.log(reply);
      context.snackBar.dismiss();
      const cfg = new MatSnackBarConfig();
      cfg.duration = 2000;
      if (reply.message.status === 'success') {
        cfg.panelClass = ['notify_snackbar', 'success'];
        const bar_ref = context.snackBar.open(
          'Send invoice successfully done.',
          'OK',
          cfg
        );
      }
    });
  }

  public yen(num: number): string {
    return Utils.yen(num);
  }
}
