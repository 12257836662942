<div class="app-file-export">
  <div class="section">
    <div class="title">請求書発行月 :</div>
    <div class="date_pick">
      <mat-form-field class="date_pick">
        <input matInput type="month"
          (change)="onChargeMonthChanged($event.target.value)">
      </mat-form-field>
    </div>
  </div>
  <hr/>
  <div class="section">
    <div class="title">Charge list</div>
    <div class="file">
      <button mat-flat-button style="margin-left: 0;"
        (click)="onDownloadVoiceCredit($event)" class="generate disabled"
        [disabled]="generate_disabled">Credit card</button>
    </div>
    <div class="file">
      <button mat-flat-button style="margin-left: 0;"
        (click)="onDownloadVoiceSmartpit($event)" class="generate disabled"
        [disabled]="generate_disabled">Smartpit</button>
    </div>
    <div class="file">
      <button mat-flat-button style="margin-left: 0;"
        (click)="onDownloadVoicePonta($event)" class="generate disabled"
        [disabled]="generate_disabled">Ponta</button>
    </div>
    <div class="file" style="display: none;">
      <button mat-flat-button style="margin-left: 0;"
        (click)="onDownloadVoiceGIBill($event)" class="generate disabled"
        [disabled]="generate_disabled">Download</button>
    </div>
    <div class="file">
      <button mat-flat-button style="margin-left: 0;"
        (click)="onDownloadInvoicePoints($event)" class="generate disabled"
        [disabled]="generate_disabled">Point list (Invoice)</button>
    </div>
  </div>
  <hr/>
  <div class="section">
    <div class="title">Aggregation</div>
    <div class="file">
      <table width="100%">
        <tr>
          <td>
            <select name="contract_aggregation_mode" (change)="onContractAggregationModeChanged($event.target.value)">
              <option value="start_at" selected>Start at</option>
              <option value="end_at">End at</option>
              <option value="created_at">Created at</option>
            </select>
          </td>
          <td>
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="fromDatePickerContractAggregation"
                placeholder="From"
                [value]="contract_aggregation_from_date"
                (dateChange)="onContractAggregationFromDateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="fromDatePickerContractAggregation"></mat-datepicker-toggle>
              <mat-datepicker #fromDatePickerContractAggregation></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="toDatePickerContractAggregation"
                placeholder="To"
                [value]="contract_aggregation_to_date"
                (dateChange)="onContractAggregationToDateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="toDatePickerContractAggregation"></mat-datepicker-toggle>
              <mat-datepicker #toDatePickerContractAggregation></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <button mat-flat-button style="margin-left: 0;"
              (click)="onDownloadContractAggregationData($event)" class="generate" [disabled]="contract_aggregation_disabled">
              Generate (Wait email)
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <hr/>

  <div class="section">
    <div class="title">Sagawa label</div>
    <div class="file">
      <table width="100%">
        <tr>
          <td>
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="fromDatePickerSagawa"
                placeholder="From"
                [value]="sagawa_from_date"
                (dateChange)="onSagawaFromDateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="fromDatePickerSagawa"></mat-datepicker-toggle>
              <mat-datepicker #fromDatePickerSagawa></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="toDatePickerSagawa"
                placeholder="To"
                [value]="sagawa_to_date"
                (dateChange)="onSagawaToDateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="toDatePickerSagawa"></mat-datepicker-toggle>
              <mat-datepicker #toDatePickerSagawa></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <button mat-flat-button style="margin-left: 0;"
              (click)="onDownloadSagawaLabelData($event)" class="generate
              disabled" [disabled]="sagawa_disabled">Generate</button>
          </td>
          <td>
            <div class="download">
              <a id="sagawa_label_data" class="download disable"
                target="_blank">Download</a>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="section">
    <div class="title">Sagawa label (e飛伝III)</div>
    <div class="file">
      <table width="100%">
        <tr>
          <td>
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="fromDatePickerEHiden3"
                placeholder="From"
                [value]="ehiden3_from_date"
                (dateChange)="onEHiden3FromDateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="fromDatePickerEHiden3"></mat-datepicker-toggle>
              <mat-datepicker #fromDatePickerEHiden3></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="toDatePickerEHiden3"
                placeholder="To"
                [value]="ehiden3_to_date"
                (dateChange)="onEHiden3ToDateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="toDatePickerEHiden3"></mat-datepicker-toggle>
              <mat-datepicker #toDatePickerEHiden3></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <button mat-flat-button style="margin-left: 0;"
              (click)="onGenerateEHiden3LabelData($event)" class="generate
              disabled" [disabled]="ehiden3_disabled">Generate</button>
          </td>
          <td>
          </td>
        </tr>
      </table>
    </div>
  </div>


  <div class="section">
    <div class="title">Airport label</div>
    <div class="file">
      <table width="100%">
        <tr>
          <td>
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="fromDatePickerAirport"
                placeholder="From"
                [value]="airport_from_date"
                (dateChange)="onAirportFromDateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="fromDatePickerAirport"></mat-datepicker-toggle>
              <mat-datepicker #fromDatePickerAirport></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="toDatePickerAirport"
                placeholder="To"
                [value]="airport_to_date"
                (dateChange)="onAirportToDateChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="toDatePickerAirport"></mat-datepicker-toggle>
              <mat-datepicker #toDatePickerAirport></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
            <button mat-flat-button style="margin-left: 0;"
              (click)="onDownloadAirportLabelData($event)" class="generate
              disabled" [disabled]="airport_disabled">Generate</button>
          </td>
          <td>
            <div class="download">
              <a id="airport_label_data" class="download disable"
                target="_blank">Download</a>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <hr/>
  <div class="section">
    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'future_start_list'})" 
      class="generate">
      01. 未来日付の契約を抽出
    </button><br/>
    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'pay_method_duplicates'})" 
      class="generate">
      02. 複数支払いを持つユーザーの確認
    </button><br/>
    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'returned_no_return_date_01'})" 
      class="generate">
      03-1. 返却済みだが返却日未記載(1)
    </button><br/>

    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'returned_no_return_date_02'})" 
      class="generate">
      03-2. 返却済みだが返却日未記載(2)
    </button><br/>

    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'start_is_null'})" 
      class="generate">
      04. 利用開始日が未記載
    </button><br/>

    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'no_phone_numbers'})" 
      class="generate">
      05. 電話番号が未記載
    </button><br/>

    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'voice_options'})" 
      class="generate">
      06. VOICE関連契約
    </button><br/>

    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'smartpit_warning_items'})" 
      class="generate">
      06. スマートピット利用終了アイテム
    </button><br/>

    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'sim_cancellations'})" 
      class="generate">
      07. SIM解約リスト
    </button><br/>

    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickMonthlyAction({event: $event, action: 'charge_amounts'})" 
      class="generate">
      08. 請求額集計
    </button><br/>
<!--
    <button mat-flat-button style="margin-left: 0;"
      (click)="onClickMonthlyAction({event: $event, action: '')" 
      class="generate">
    </button><br/>
-->
  </div>
  <hr/>
  <div class="section">
    <div class="title">eSIM QR Code</div>
    <mat-form-field class="full_width">
      <input matInput type="text"
        placeholder="Publish count"
        [value]="esim_qr_publish_count"
        (change)="onEsimQrPublishCountChanged($event)">
    </mat-form-field>
    <button mat-flat-button style="margin-left: 0; margin-bottom: 2px;"
      (click)="onClickEsimQrPublish()" 
      class="generate">
      発行
    </button><br/>
  </div>
  <br/>
</div>