export class Values {
    public static STAT_SUCCESS = 1;
    public static STAT_NOCARE = 0;
    public static STAT_ERROR = -1;
    public static STAT_SUCCESS_STR = 'success';
    public static STAT_NOCARE_STR = 'nocare';
    public static STAT_ERROR_STR = 'error';

    public static LVL_INFO = 4;
    public static LVL_FATAL = 3;
    public static LVL_ERROR = 2;
    public static LVL_WARN = 1;
    public static LVL_DEBUG = 0;
    public static LVL_INFO_STR = 'INFO';
    public static LVL_FATAL_STR = 'FATAL';
    public static LVL_ERROR_STR = 'ERROR';
    public static LVL_WARN_STR = 'WARN';
    public static LVL_DEBUG_STR = 'DEBUG';

    public static strToStatus(status: string): number {
        if (Values.stringMatchI(status, Values.STAT_SUCCESS_STR)) {
            return Values.STAT_SUCCESS;
        } else if (Values.stringMatchI(status, Values.STAT_NOCARE_STR)) {
            return Values.STAT_NOCARE;
        } else if (Values.stringMatchI(status, Values.STAT_ERROR_STR)) {
            return Values.STAT_ERROR;
        }
        return Values.STAT_ERROR; // default
    }

    public static strToLevel(status: string): number {
        if (Values.stringMatchI(status, Values.LVL_INFO_STR)) {
            return Values.LVL_INFO;
        } else if (Values.stringMatchI(status, Values.LVL_FATAL_STR)) {
            return Values.LVL_FATAL;
        } else if (Values.stringMatchI(status, Values.LVL_ERROR_STR)) {
            return Values.LVL_ERROR;
        } else if (Values.stringMatchI(status, Values.LVL_WARN_STR)) {
            return Values.LVL_WARN;
        } else if (Values.stringMatchI(status, Values.LVL_DEBUG_STR)) {
            return Values.LVL_DEBUG;
        }
        return Values.LVL_ERROR; // default
    }

    constructor() {}

    public static stringMatchI(target: string, find: string): boolean {
        const exp = new RegExp('^' + find + '$', 'i');
        console.log();
        return exp.test(target);
    }
}
