import {environment} from 'src/environments/environment';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { Utils } from '../../../helpers';
import { ListFiberSchedulesService } from '../../../services/list';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import { GenerateTopupTicketDialogComponent } from '../../dialog/generate-topup-ticket-dialog/generate-topup-ticket-dialog.component';

@Component({
  selector: 'app-topup-tickets',
  templateUrl: './topup-tickets.component.html',
  styleUrls: ['./topup-tickets.component.scss']
})
export class TopupTicketsComponent implements OnInit {

  public selected_campaign: string;
  public campaigns: Array<string>;
  public tickets: Array<any>;

  public data_source: MatTableDataSource<any>;
  public display_columns: Array<string>;

  protected _loading_animation;
  protected _el: HTMLElement;

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    private list_fiber_schedules_service: ListFiberSchedulesService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this._el = el.nativeElement;
    this.display_columns = [
      // 'select',
      'campaign_title',
      'topup_amount',
      'valid_until',
      'applied',
      'used_phone_number',
      'user_full_name',
      'email',
    ];
  }

  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  ngOnInit() {
    this.refresh(true, null);
  }

  public refresh(from_db: boolean, selecting_campaign: string): void {
    if (from_db) {
      const params = {};

      if (!Utils.isValue(this._loading_animation)) {
        this._loading_animation = this._el.querySelector('#top_up_ticket_updating');
      }
      this._loading_animation.style.opacity = 1.0;  // show loading icon;

      this.auth_service.apiGetTopUpTicketCampagins().subscribe(
        response => {
          this._loading_animation.style.opacity = 0.0;
          this.initializeCampaigns(response);
        },
        error => {
          this._loading_animation.style.opacity = 0.0;
          console.log('TopupTicketsComponent : apiGetTopUpTicketCampagins(error)', error);
        }
      );

      this.auth_service.apiGetTopUpTickets().subscribe(
        response => {
          if (selecting_campaign) {
            this.selected_campaign = selecting_campaign;
          }
          else {
            this.selected_campaign = "全て";
          }
          this.tickets = response;
          this.initializeDisplayTickets();

          this.data_source.sort = this.sort;
          this.data_source.paginator = this.paginator;

          this._loading_animation.style.opacity = 0.0;
        },
        error => {
          this._loading_animation.style.opacity = 0.0;
          console.log('TopupTicketsComponent : apiGetTopUpTickets(error)', error);
        }
      );
    }
    else {
      // this.filterDisplayItems();
    }
  }

  private initializeCampaigns(campaigns: any): void {
    this.campaigns = ['全て'];
    campaigns.forEach(campaign => {
      this.campaigns.push(campaign);
    });
    this.selected_campaign = this.campaigns[0];
  }

  private filterDisplayTickets(): Array<any> {
    let displayItems = [];
    if (this.selected_campaign !== '全て') {
      displayItems = this.tickets.filter(v => {
        return v.campaign_title === this.selected_campaign;
      });
    }
    else {
      displayItems = this.tickets;
    }
    return displayItems;
  }

  private initializeDisplayTickets(): void {
    this.data_source = new MatTableDataSource(this.filterDisplayTickets());
    this.data_source.sort = this.sort;
    this.data_source.paginator = this.paginator;
  }

  public onRefreshList(event: any): void {
    this.refresh(true, null);
  }

  public formatDatePart(strDateTime: string): string {
    return Utils.getDatePart(strDateTime, false);
  }

  public formatUsedOrNot(used: boolean): string {
    return used ? "済" : "未";
  }

  public onChangedCampaign(event: any): void {
    this.selected_campaign = event.source.value;
    // console.log('TopupTicketsComponent : onChangedCampaign(success)', this.selected_campaign, event);
    this.initializeDisplayTickets();
  }

  public onClickGenerateTicket(event: any): void {
    // console.log('TopupTicketsComponent::onClickGenerateTicket :');
    const dialogRef = this.dialog.open(
      GenerateTopupTicketDialogComponent,
      {
        width: '60rem',
        hasBackdrop: true
      }
    );

    dialogRef.afterClosed().subscribe(confirm_result => {
      console.log(`TopupTicketsComponent`, confirm_result);
      if (Utils.isValue(confirm_result)) {
        if (confirm_result.generate === true) {
          this.refresh(true, confirm_result.campaign_title);
        }
      }
    });
  }

  private formatTicketLink(ticket_hash: string): string {
    // http://localhost:4201/top-up-ticket?ticket_hash=TEST00000001
    return `${environment.orderFormRoot}/top-up-ticket?ticket_hash=${ticket_hash}`;
  }

  public onClickExportTickets(event: any): void {
    // console.log(`TopupTicketsComponent::onClickExportTickets : `);
    let utf8_records = "";
    utf8_records += `"キャンペーン","リンクURL","データ量","有効期限","利用済","利用番号","利用者","メールアドレス"\r\n`;
    this.filterDisplayTickets().forEach(el => {
      utf8_records += `"${el.campaign_title}","${this.formatTicketLink(el.ticket_hash)}","${el.topup_amount}","${this.formatDatePart(el.valid_until)}","${this.formatUsedOrNot(el.applied)}","${el.used_phone_number}","${el.user_full_name}","${el.email}"\r\n`;
    });
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const file = new Blob([bom, utf8_records], {type: 'text/csv;charset=utf-8'});
    const download_el = document.createElement('a');
    download_el.download = `top_up_tickets.csv`
    download_el.href = window.URL.createObjectURL(file);
    download_el.click();
    // console.log(`TopupTicketsComponent::onClickExportTickets : `);
  }
}
