import { cloneDeep } from "lodash";
import { Component, ViewChild, OnInit } from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { L } from "@angular/core/src/render3";

export class ReferralPointItem {
  contract_id: number;
  contract_code: string;
  contract_start_at: string;
  contract_status: number;
  contract_status_title: string;
  order_user_id: number;
  order_user_email: string;
  order_user_full_name: string;
  invite_user_id: number;
  invite_woo_user_id: number;
  invited_woo_user_id: number;
  invite_user_email: string;
  invite_user_full_name: string;
  paid_at_start_month: boolean;
  point_issued_id: number;
  point_issued: string;
  modified: boolean;
  disabled: boolean;
  constructor() {
    this.modified = false;
    this.disabled = false;
  }
}

@Component({
  selector: 'app-refferal-points',
  templateUrl: './refferal-points.component.html',
  styleUrls: ['./refferal-points.component.scss']
})
export class ReferralPointsComponent implements OnInit {

  public contract_start_month: Date;
  public filter_string: string;
  public unissued_only: boolean;
  public referral_points_all: Array<ReferralPointItem>;
  public referral_points_display: Array<ReferralPointItem>;
  public display_columns: Array<string>;
  public data_source: MatTableDataSource<ReferralPointItem>;

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
  ) {
    this.referral_points_all = new Array<ReferralPointItem>();
    this.data_source = new MatTableDataSource(this.referral_points_display);
    this.unissued_only = true;
    this.display_columns = [
      // 'select',
      'contract_code',
      'contract_start_at',
      'order_user_email',
      'order_user_full_name',
      'invite_user_email',
      'invite_user_full_name',
      'paid_at_start_month',
      'point_issued'
    ];
    this.contract_start_month = new Date();
  }

  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  ngOnInit() {
    console.log('ReferralPointsComponent::ngOnInit')
    this.refresh();
  }

  public refresh() {
    this.auth_service.apiGetReferralPoints({
      contract_start_month: this.monthExpression(this.contract_start_month)
    }).subscribe(
      response => {
        this.referral_points_all = response.data;
        this.referral_points_all.forEach(item => {
          item.modified = false;
          if (item.point_issued === null ) {
            item.point_issued = "0";
          }
          item.disabled = item.point_issued === "0" ? false : true;
        });
        this.referral_points_display = cloneDeep(this.referral_points_all);
        this.applyFilter();
      },
      error => {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 10000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          'Failed to get referral point list data.',
          'OK',
          cfg
        );
        console.log(JSON.stringify(error));
      }
    );
  }

  public applyFilter() {
    // console.log(`unissued_only = ${this.unissued_only}`);
    // console.log(this.referral_points_display);
    this.referral_points_display = cloneDeep(this.referral_points_all);
    if (this.unissued_only) {
      this.referral_points_display = this.referral_points_display.filter(item => {
        return item.point_issued === "0" ? true : false;
      });
    }

    if (this.filter_string && this.filter_string.length > 0) {
      const uppered_filter_string = this.filter_string.toUpperCase();
      this.referral_points_display = this.referral_points_display.filter(item => {
        return item.contract_code.toUpperCase().includes(uppered_filter_string) ||
           item.order_user_email.toUpperCase().includes(uppered_filter_string) ||
           item.invite_user_email.toUpperCase().includes(uppered_filter_string);
      });
    }
    this.data_source = new MatTableDataSource(this.referral_points_display);
    this.data_source.sort = this.sort;
    this.data_source.paginator = this.paginator;
  }

  public onUnissuedOnlyChanged(event: any): void {
    const modifieds = this.referral_points_display.filter(item => {
      return item.modified;
    });
    this.unissued_only = !this.unissued_only;
    this.applyFilter();
  }

  public onSearchStringKeyUp(value: string): void {
    this.applyFilter();
  }

  public onIssuedCheckChanged(event_item: any): void {
    this.referral_points_display.forEach(item => {
      if (item.point_issued_id === event_item.point_issued_id) {
        event_item.modified = true;
        event_item.point_issued = event_item.point_issued === "0" ? "1" : event_item.point_issued === "1" ? "0" : "1";
        item = event_item;
      }
    });
  }

  public onExportUnissuedReferralPoints(event: any): void {
    console.log('onExportUnissuedReferralPoints');
    const referral_point_value = 3000;
    const export_dt = new Date();
    let utf8_string = `"user_id","point","description","date_earning"\r\n`;
    this.referral_points_display.forEach(item => {
      console.log('item=',item);
      if (item.modified === true && item.point_issued === "1") {
        utf8_string += `"${item.invite_woo_user_id}","${referral_point_value}","Invite point : INVT-${item.contract_code}","${this.dateExpression(export_dt)}"\r\n`;
        utf8_string += `"${item.invited_woo_user_id}","${referral_point_value}","Invited point : INVD-${item.contract_code}","${this.dateExpression(export_dt)}"\r\n`;
      }
    });

    const dt = new Date();
    const file = new Blob([utf8_string], {type: 'text/csv;charset=utf-8'});
    const a = document.createElement('a');
    a.download = `referral_points_${this.monthExpression(this.contract_start_month)}_${this.dateExpression(dt)}.csv`
    a.href = window.URL.createObjectURL(file);
    a.click();
  }

  public onApplyUnissuedPoints(event: any): void {
    this.auth_service.apiSetReferralPoints({records: this.referral_points_display}).subscribe(
      response => {
        this.refresh();
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'Sucess to set referral point list data.',
          'OK',
          cfg
        );
      },
      error => {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 10000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          'Failed to set referral point list data.',
          'OK',
          cfg
        );
        console.log(JSON.stringify(error));
      }
    );
  }

  public onContractMonthPrev(event: any): void {
    const now = new Date();
    this.contract_start_month = new Date(this.contract_start_month.getFullYear(), this.contract_start_month.getMonth() - 1, this.contract_start_month.getDate());
    this.refresh();
  }

  public onContractMonthNext(event: any): void {
    const now = new Date();
    this.contract_start_month = new Date(this.contract_start_month.getFullYear(), this.contract_start_month.getMonth() + 1, this.contract_start_month.getDate());
    if (this.contract_start_month > now) {
      this.contract_start_month = now;
    }
    this.refresh();
  }

  public endOfMonth(dt: Date): Date {
    if (dt === null) {
      return null;
    }
    const dt_t = new Date(dt.getFullYear(), dt.getMonth() + 1, 0, 23, 59, 59,999);
    return dt_t;
  }

  public beginningOfMonth(dt: Date): Date {
    if (dt === null) {
      return null;
    }
    const dt_t = new Date(dt.getFullYear(), dt.getMonth(), 1, 0, 0, 0, 0);
    return dt_t;
  }

  public monthExpression(dt: Date): string {
    if (dt === null) {
      return "";
    }
    return `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}`;
  }
  
  public dateExpression(dt: Date): string {
    if (dt === null) {
      return "";
    }
    return `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`;
  }
  
  public dateExpressionByString(dt_str: string): string {
    const dt = new Date(dt_str);
    return `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`;
  }
  
  public paidExpression(paid_value: boolean): string {
    return paid_value ? "Paid" : "Unpaid";
  }
}
