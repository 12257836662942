import { Values } from '../constants';

export class FlashMessage {
    public static DURATION_SHORT = 3000;
    public static DURATION_LONG = 6000;
    public static DURATION_STAY = 0;

    public status: number;
    public level: number;
    public title: string;
    public message: string;
    public duration: number;

    constructor(status: string, level: string, title: string, message: string, duration: number) {
        // status
        if (status !== null && status !== undefined) {
            this.status = Values.strToStatus(status);
        } else {
            this.status = Values.STAT_ERROR;
        }

        // level
        if (level !== null && level !== undefined) {
            this.level = Values.strToLevel(level);
        } else {
            this.status = Values.STAT_NOCARE;
        }

        // title
        if (title !== null && title !== undefined) {
            this.title = title;
        } else {
            this.title = '';
        }

        // message
        if (message !== null && message !== undefined) {
            this.message = message;
        } else {
            this.message = '';
        }

        // duration
        if (duration !== null && duration !== undefined) {
            this.duration = duration;
        } else {
            this.duration = FlashMessage.DURATION_SHORT;
        }
    }
}
