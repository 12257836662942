<div class="app-file-import">
  <!-- 通話/データ使用料CSVの登録 -->
  <div class="section">
    <div class="title">通話/データ使用量</div>
    <div class="file">
      <input style="display: none" type="file" (change)="onKpdChargeFileChanged($event)" #importKpdCharge>
      <button mat-flat-button style="margin-left: 0;" (click)="importKpdCharge.click($event)">Select File</button>
      {{kpdChargeFile && kpdChargeFile.name}}
    </div>
    <div class="execute">
      <button mat-flat-button class="warn" (click)="onImportKpdCharge($event)">Import</button>
    </div>
  </div>
  <!-- チャージ結果の登録 -->
  <div class="section">
    <div class="title">チャージ結果(CREDIT CARD/PONTA)</div>
    <div class="file">
      <input style="display: none" type="file" (change)="onChargeResultCreditFileChanged($event)" #importChargeResultCredit>
      <button mat-flat-button style="margin-left: 0;" (click)="importChargeResultCredit.click($event)">Select File</button>
      {{chargeResultCreditFile && chargeResultCreditFile.name}}
    </div>
    <div class="execute">
      <button mat-flat-button class="warn" (click)="onImportChargeResultCredit($event)">Import</button>
    </div>
  </div>

  <div class="section">
    <div class="title">チャージ結果(SMARTPIT)</div>
    <div class="file">
      <input style="display: none" type="file" (change)="onChargeResultSmartpitFileChanged($event)" #importChargeResultSmartpit>
      <button mat-flat-button style="margin-left: 0;" (click)="importChargeResultSmartpit.click($event)">Select File</button>
      {{chargeResultSmartpitFile && chargeResultSmartpitFile.name}}
    </div>
    <div class="execute">
      <button mat-flat-button class="warn" (click)="onImportChargeResultSmartpit($event)">Import</button>
    </div>
  </div>

  <div class="section">
    <div class="title">チャージ結果(GI BILL)</div>
    <div class="file">
      <input style="display: none" type="file" (change)="onChargeResultGIBillFileChanged($event)" #importChargeResultGIBill>
      <button mat-flat-button style="margin-left: 0;" (click)="importChargeResultGIBill.click($event)">Select File</button>
      {{chargeResultGIBillFile && chargeResultGIBillFile.name}}
    </div>
    <div class="execute">
      <button mat-flat-button class="warn" (click)="onImportChargeResultGIBill($event)">Import</button>
    </div>
  </div>
</div>
