import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import {
  InvoiceListItemProductProperty,
  InvoiceContentListItem
} from '../../../models';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';
import { FlashMessage, AuthGetReply } from '../../../models';

export class AddInvoiceProductPropertyDialogData {
  invoice_id: number;
  invoice_product_id: number;
}

@Component({
  selector: 'app-add-invoice-product-property-dialog',
  templateUrl: './add-invoice-product-property-dialog.component.html',
  styleUrls: ['./add-invoice-product-property-dialog.component.scss']
})
export class AddInvoiceProductPropertyDialogComponent implements OnInit {
  public key: string;
  public title: string;
  public description: string;
  public amount: number;
  public button_ok_disable: boolean;
  public button_cancel_disable: boolean;
  public selectable_properties: Array<InvoiceListItemProductProperty>;
  public selecting_property: InvoiceListItemProductProperty;

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddInvoiceProductPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddInvoiceProductPropertyDialogData
  ) {
    console.log('AddInvoiceProductPropertyDialogComponent::constructor');
    console.log(this.data);
    this.button_ok_disable = true;
    this.selectable_properties = new Array<InvoiceListItemProductProperty>();
  }

  OnInit() {
    console.log('AddInvoiceProductPropertyDialogComponent::OnInit');
  }

  ngOnInit() {
    console.log('AddInvoiceProductPropertyDialogComponent::ngOnInit');
    const param = {
      invoice_id: this.data.invoice_id,
      invoice_product_id: this.data.invoice_product_id
    };
    this.auth_service.apiGetUnassignedInvoiceContentProperties(
      param,
      this,
      function(reply: AuthGetReply, context: any) {
        context.selectable_properties = reply.data.unassigned_properties;
        console.log(context.selectable_properties);
      }
    );
  }

  public switchApplyDisable() {
    if (
      this.description !== this.selecting_property.description ||
      this.amount !== parseInt(this.selecting_property.value, 10)
    ) {
      this.button_ok_disable = false;
    } else {
      this.button_ok_disable = true;
    }
  }

  public onDescriptionChanged(event): void {
    this.switchApplyDisable();
  }

  public onAmountChanged(event): void {
    this.switchApplyDisable();
  }

  public onChangedPropertyTitle(event) {
    console.log(
      'AddInvoiceProductPropertyDialogComponent::onChangedPropertyTitle'
    );
    console.log(event);
    const founds = this.selectable_properties.filter(property => {
      return property.title === event.value;
    });
    this.selecting_property = founds[0];
    this.description = this.selecting_property.description;
    this.amount = parseInt(this.selecting_property.value, 10);
  }

  public onOkClicked(event): void {
    console.log('AddInvoiceProductPropertyDialogComponent::onOkClicked');
    const param = {
      invoice_id: this.selecting_property.invoice_id,
      invoice_product_id: this.selecting_property.invoice_product_id,
      key: this.selecting_property.key,
      description: this.description,
      amount: this.amount
    };
    this.auth_service.apiAddInvoiceContentProperty(param, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      console.log(reply);
      context.snackBar.dismiss();
      const cfg = new MatSnackBarConfig();
      cfg.duration = 2000;
      cfg.panelClass = ['notify_snackbar', 'success'];
      context.snackBar.open(
        'Property added.',
        'OK',
        cfg
      );
      context.dialogRef.close({
        applied: true
      });
    });
  }

  public onCancelClicked(event): void {
    this.dialogRef.close({
      applied: false
    });
  }
}
