import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../services/auth';
import { Url } from '../../../constants/url';
import {
  FlashMessage,
  AuthGetReply
} from '../../../models';

@Component({
  selector: 'app-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.scss']
})
export class FileImportComponent implements OnInit {

  public kpdChargeFile: File;
  public chargeResultCreditFile: File;
  public chargeResultSmartpitFile: File;
  public chargeResultGIBillFile: File;

  constructor(
    private http: HttpClient,
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
  }

  onKpdChargeFileChanged(event) {
    console.log('FileImportComponent::onKpdChargeFileChanged()');
    this.kpdChargeFile = event.target.files[0];
    console.log(this.kpdChargeFile);
  }

  onImportKpdCharge(event) {
    console.log('onImportKpdCharge() - ' + this.kpdChargeFile.name);
    this.auth_service.upload(Url.BASE + '/file_imports/api_import_kpd_charge', this.kpdChargeFile).
    subscribe(
      response => {
        console.log('FileImportComponent::onImportKpdCharge - reply success');
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'KPD Charge data impoted successfully.',
          'OK',
          cfg);
      },
      error => {
        console.log('FileImportComponent::onImportKpdCharge - reply error');
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          'Failed to import KPD charge data.[' + error.message + ']',
          'OK',
          cfg);
      }
    );
    this.snackBar.dismiss();
    const cfg_info = new MatSnackBarConfig();
    cfg_info.duration = 2000;
    cfg_info.panelClass = ['notify_snackbar', 'information'];
    this.snackBar.open(
      'File import takes long time. Please ignore if timeout error had occured.',
      'OK',
      cfg_info);
  }

  onChargeResultCreditFileChanged(event) {
    console.log('FileImportComponent::onChargeResultCreditFileChanged()');
    this.chargeResultCreditFile = event.target.files[0];
    console.log(this.chargeResultCreditFile);
  }

  onImportChargeResultCredit(event) {
    console.log('onImportChargeResultCredit() - ' + this.chargeResultCreditFile.name);
    this.auth_service.upload(Url.IMPORT_CHARGE_RESULT_CREDIT, this.chargeResultCreditFile).
    subscribe(
      response => {
        console.log('FileImportComponent::onImportChargeResultCredit - reply success');
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'チャージ結果(CREDIT CARD/PONTA)の取り込みが完了しました.',
          'OK',
          cfg);
      },
      error => {
        console.log('FileImportComponent::onImportChargeResultCredit - reply error');
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        if (error.error.message && error.error.message.message) {
          this.snackBar.open(
            'チャージ結果(CREDIT CARD/PONTA)の取り込みに失敗[' + error.error.message.message + ']',
            'OK',
            cfg);
        } else if (error.message) {
          this.snackBar.open(
            'チャージ結果(CREDIT CARD/PONTA)の取り込みに失敗[' + error.message + ']',
            'OK',
            cfg);
        }
      }
    );
    this.snackBar.dismiss();
    const cfg_info = new MatSnackBarConfig();
    cfg_info.duration = 2000;
    cfg_info.panelClass = ['notify_snackbar', 'information'];
    this.snackBar.open(
      'File import takes long time. Please ignore if timeout error had occured.',
      'OK',
      cfg_info);
  }

  onChargeResultSmartpitFileChanged(event) {
    console.log('FileImportComponent::onChargeResultSmartpitFileChanged()');
    this.chargeResultSmartpitFile = event.target.files[0];
    console.log(this.chargeResultSmartpitFile);
  }

  onImportChargeResultSmartpit(event) {
    console.log('onImportChargeResultSmartpit() - ' + this.chargeResultSmartpitFile.name);
    this.auth_service.upload(Url.IMPORT_CHARGE_RESULT_SMARTPIT, this.chargeResultSmartpitFile).
    subscribe(
      response => {
        console.log('FileImportComponent::onImportChargeResultSmartpit - reply success');
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'チャージ結果(SMARTPIT)の取り込みが完了しました.',
          'OK',
          cfg);
      },
      error => {
        console.log('FileImportComponent::onImportChargeResultSmartpit - reply error');
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          'チャージ結果(SMARTPIT)の取り込みに失敗.[' + error.message + ']',
          'OK',
          cfg);
      }
    );
    this.snackBar.dismiss();
    const cfg_info = new MatSnackBarConfig();
    cfg_info.duration = 2000;
    cfg_info.panelClass = ['notify_snackbar', 'information'];
    this.snackBar.open(
      'File import takes long time. Please ignore if timeout error had occured.',
      'OK',
      cfg_info);
  }

  onChargeResultGIBillFileChanged(event) {
    console.log('FileImportComponent::onChargeResultGIBillFileChanged()');
    this.chargeResultGIBillFile = event.target.files[0];
    console.log(this.chargeResultGIBillFile);
  }

  onImportChargeResultGIBill(event) {
    console.log('onImportChargeResultGIBill() - ' + this.chargeResultGIBillFile.name);
    this.auth_service.upload(Url.IMPORT_CHARGE_RESULT_GI_BILL, this.chargeResultGIBillFile).
    subscribe(
      response => {
        console.log('FileImportComponent::onImportChargeResultGIBill - reply success');
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'チャージ結果(GI Bill)の取り込みが完了しました.',
          'OK',
          cfg);
      },
      error => {
        console.log('FileImportComponent::onImportChargeResultGIBill - reply error');
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        if (error.error.message && error.error.message.message) {
          this.snackBar.open(
            'チャージ結果(GI Bill)の取り込みに失敗[' + error.error.message.message + ']',
            'OK',
            cfg);
        } else if (error.message) {
          this.snackBar.open(
            'チャージ結果(GI Bill)の取り込みに失敗[' + error.message + ']',
            'OK',
            cfg);
        }
      }
    );
    this.snackBar.dismiss();
    const cfg_info = new MatSnackBarConfig();
    cfg_info.duration = 2000;
    cfg_info.panelClass = ['notify_snackbar', 'information'];
    this.snackBar.open(
      'File import takes long time. Please ignore if timeout error had occured.',
      'OK',
      cfg_info);
  }
}
