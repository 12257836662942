<div class="app-operation-utilities">
  <div class="sim_close_line">
    <!-- 回線停止 -->
    <div class="title">
      回線停止対象月 :
    </div>
    <div class="close_month">
      <mat-form-field class="date_pick">
        <input matInput type="month" [(ngModel)]="close_month" (change)="onCloseMonthChanged($event.target.value)">
        <mat-hint>
          <div *ngIf="close_month_error" class="close_month_error">Invalid</div>
        </mat-hint>
      </mat-form-field>
    </div>
    <button mat-button (click)="onClickCloseLine($event)" [disabled]=close_line_button_disabled>Close lines</button>
  </div>
</div>
