import { Injectable } from '@angular/core';
import { ProductType, ListProductPropertyItem } from '../../models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListProductPropertiesService {
  private _product_type = new Subject<ProductType>();
  public product_type$ = this._product_type.asObservable();
  private _refresh = new Subject<boolean>();
  public refresh$ = this._refresh.asObservable();
  private _current_item = new Subject<ListProductPropertyItem>();
  public current_item$ = this._current_item.asObservable();

  public set product_type(type: ProductType) {
    this._product_type.next(type);
  }

  public set refresh(trigger: boolean) {
    this._refresh.next(trigger);
  }

  public set current_item(item: ListProductPropertyItem) {
    this._current_item.next(item);
  }

  constructor() {}
}
