<table style="width: 100%">
  <tr>
      <td colspan="2">
        {{data.title}}
    </td>
  </tr>
  <tr>
    <td colspan="2">
        <div [innerHTML]="data.message"></div>
    </td>
  </tr>
  <tr>
      <td style="text-align: right">
        <button mat-flat-button class="warn" (click)="onOkClicked($event)">OK</button>
        <button mat-flat-button class="no" (click)="onCancelClicked($event)">Cancel</button>
      </td>
  </tr>
</table>
