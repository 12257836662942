import { Component, OnInit } from '@angular/core';

import {
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import {
  AuthGetReply,
  ContractContent,
  ContractProductProperty,
  ContractMemoListItem,
  ContractStatus,
} from '../../../models';
import { Utils } from '../../../helpers';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-contract-print',
  templateUrl: './contract-print.component.html',
  styleUrls: ['./contract-print.component.scss']
})
export class ContractPrintComponent implements OnInit {
  public contract_id: number;
  public contract_content: ContractContent;
  public memos: Array<ContractMemoListItem>;
  public contract_statuses: Array<ContractStatus>;
  public preparation_for_shipping_status: number;
  public is_preparation_for_shipping: boolean;
  public contract_status_string: string;

  protected _el: HTMLElement;
  public _domSanitizer: DomSanitizer;

  public confirm_dialog_ref: any;

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
  ) {
    this._el = el.nativeElement;
    this.contract_content = new ContractContent();
    this._domSanitizer = sanitizer;
    this.is_preparation_for_shipping = true;
    this.preparation_for_shipping_status = null;
    this.contract_status_string = '';
  }

  ngOnInit() {
    if (this.auth_service.isLoggingIn() !== true) {
      location.href = '/auth/login';
      return;
    }
    this.auth_service.apiIsMemberUser('operator', this, function(
      reply: AuthGetReply,
      context: any
    ) {
      if (reply.data.is_member === true) {
      } else {
        location.href = '/auth/login';
      }
    });
    this.contract_id = Number.parseInt(Utils.getUrlParameter('contract_id'));
    if (Utils.isValue(this.contract_id)) {
      this.refresh();
    }
  }

  private _reduceContractProperty() {
    this.contract_content.contract_properties = this.contract_content.contract_properties.filter(prop => {
      return prop.key !== 'START DATE';
    });
  }

  private _reduceContractProductProperty() {
    this.contract_content.product_properties = this.contract_content.product_properties.filter(prop => {
      return !prop.key.startsWith('KPD KIND');
    });
  }

  public refresh() {
    this.auth_service.apiEnumContractStatuses().subscribe(
      response => {
        this.contract_statuses = response.data;
        const statuses = this.contract_statuses.filter(candidate => {
          return candidate.key === 'PREPARING SHIPMENT';
        });
        this.preparation_for_shipping_status = statuses[0].status;
        if (!Utils.isValue(this.preparation_for_shipping_status) ||
            !Utils.isValue(this.contract_content.contract_summary.status)) {
          this.is_preparation_for_shipping = true; // PREPARING SHIPMENT (for disable)
        } else if (this.contract_content.contract_summary.status !== this.preparation_for_shipping_status) {
          console.log('ContractPrintComponent::refresh : status has PREPARING SHIPMENT');
          this.is_preparation_for_shipping = false; // PREPARING SHIPMENT
        } else {
          this.is_preparation_for_shipping = true; // PREPARING SHIPMENT (for disable)
        }
      },
      error => {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 10000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'Failed to load contract status candidates.',
          'OK',
          cfg
        );
        console.log(JSON.stringify(error));
      }
    );

    const contract_memo_params = {
      contract_id: this.contract_id
    };
    this.auth_service.apiListContractMemos(contract_memo_params, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      // convert gmt offset 0 to local gmt offset
      console.log(reply.data);
      const memos = <Array<ContractMemoListItem>>reply.data;
      context.memos = new Array<ContractMemoListItem>();
      console.log(reply.data);
      memos.forEach(element => {
        console.log(element.memo);
        context.memos.push({
          memo_id: element.memo_id,
          contract_id: element.contract_id,
          user_id: element.user_id,
          user_e_mail: element.user_e_mail,
          memo: context._domSanitizer.bypassSecurityTrustHtml(element.memo),
          created_at: Utils.getLocalTimeString(element.created_at),
        });
      });
    });

    const contract_params = {
      contract_id: this.contract_id
    };
    this.auth_service.apiGetContract(contract_params).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.contract_content = <ContractContent>response.data;
          this._reduceContractProperty();
          this._reduceContractProductProperty();
          document.title = this.contract_content.contract_summary.contract_code;
          if (!Utils.isValue(this.preparation_for_shipping_status) ||
              !Utils.isValue(this.contract_content.contract_summary.status)) {
            this.is_preparation_for_shipping = true; // PREPARING SHIPMENT (for disable)
          } else if (this.contract_content.contract_summary.status !== this.preparation_for_shipping_status) {
            console.log('ContractPrintComponent::refresh : status has PREPARING SHIPMENT');
            this.is_preparation_for_shipping = false; // PREPARING SHIPMENT
          } else {
            this.is_preparation_for_shipping = true; // PREPARING SHIPMENT (for disable)
          }
        }
      },
      error => {
        console.log(error);
        if (
          Utils.isValue(error.error.message) &&
          Utils.isValue(error.error.message.message)
        ) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
      }
    );
  }

  public getContractStatusString(status: number) {

    const statuses = this.contract_statuses.filter(candidate => {
      return candidate.status === status;
    });
    if (statuses.length > 0) {
      return this.contract_status_string = statuses[0].title;
    }
    return 'Unknown status(' + status + ')';
  }

  public getUserPropertyTitle(key: string): string {
    const properties = this.contract_content.user_properties.filter(candidate => {
      return candidate.key === key;
    });
    if (properties.length === 0) {
      return null;
    }
    return properties[0].title;
  }

  public getUserPropertyValue(key: string): string {
    const properties = this.contract_content.user_properties.filter(candidate => {
      return candidate.key === key;
    });
    if (properties.length === 0 ||
      Utils.isValue(properties[0].value) === false ||
      properties[0].value.length === 0) {
      return null;
    }
    return properties[0].value;
  }


  public getContractPropertyTitle(key: string): string {
    const properties = this.contract_content.contract_properties.filter(candidate => {
      return candidate.key === key;
    });
    if (properties.length === 0) {
      return null;
    }
    return properties[0].title;
  }

  public getContractPropertyValue(key: string): string {
    const properties = this.contract_content.contract_properties.filter(candidate => {
      return candidate.key === key;
    });
    if (properties.length === 0 ||
      Utils.isValue(properties[0].value) === false ||
      properties[0].value.length === 0) {
      return null;
    }
    return properties[0].value;
  }

  public getContractProductProperties(product_id: number): Array<ContractProductProperty> {
    const properties = this.contract_content.product_properties.filter(candidate => {
      return candidate.contract_product_id === product_id;
    });
    console.log('found properties : product_id=' + product_id);
    console.log(properties);
    return properties;
  }

  public getContractProductPropertyTitle(product_id: number, key: string) {
    const properties = this.contract_content.product_properties.filter(candidate => {
      return candidate.contract_product_id === product_id && candidate.key === key;
    });
    if (properties.length === 0) {
      return null;
    }
    return properties[0].title;
  }

  public getContractProductPropertyValue(product_id: number, key: string) {
    const properties = this.contract_content.product_properties.filter(candidate => {
      return candidate.contract_product_id === product_id && candidate.key === key;
    });
    if (properties.length === 0 ||
      Utils.isValue(properties[0].value) === false ||
      properties[0].value.length === 0) {
      // console.log('not found : id:' + product_id, + ' : key:' + key);
      return null;
    }
    // console.log('found : id:' + product_id, + ' : key:' + key + ' : value' + properties[0].value);
    return properties[0].value;
  }

  public onClickContractPrinted(event: any): void {
    console.log('ContractPrintComponent::onClickContractPrinted : ');
    const update_param = {
      contract_id: this.contract_id,
      status: this.preparation_for_shipping_status
    };
    this.auth_service.apiUpdateContractStatus(update_param).subscribe(
      response => {
        console.log(JSON.stringify(response));
        this.snackBar.dismiss();
        const barcfg = new MatSnackBarConfig();
        barcfg.duration = 5000;
        barcfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(response.message.message, 'OK', barcfg);
        this.is_preparation_for_shipping = true;
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const barcfg = new MatSnackBarConfig();
        barcfg.duration = 5000;
        barcfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', barcfg);
      }
    );
    /*
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: 'Preparation for shipping',
        message: 'Contract status will be changed to "Preparation for shipping" Are you sure?'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          const statuses = this.contract_statuses.filter(candidate => {
            return candidate.key === 'PREPARING SHIPMENT';
          });
          const update_param = {
            contract_id: this.contract_id,
            status: statuses[0].status
          };
          
          this.auth_service.apiUpdateContractStatus(update_param).subscribe(
            response => {
              console.log(JSON.stringify(response));
              this.snackBar.dismiss();
              const barcfg = new MatSnackBarConfig();
              barcfg.duration = 5000;
              barcfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open(response.message.message, 'OK', barcfg);
              this.is_preparation_for_shipping = true;
            },
            error => {
              console.log(error);
              this.snackBar.dismiss();
              const barcfg = new MatSnackBarConfig();
              barcfg.duration = 5000;
              barcfg.panelClass = ['notify_snackbar', 'error'];
              this.snackBar.open(error.error.message.message, 'OK', barcfg);
            }
          );
        }
      }
    });
    */
  }
}
