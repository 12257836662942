import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Utils } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class VisibleService {

  // サービスで提供するメンバ
  private _login = new Subject<boolean>();
  public login$ = this._login.asObservable();
  private _home = new Subject<boolean>();
  public home$ = this._home.asObservable();
  private _editor_list_product_types = new Subject<boolean>();
  public editor_list_product_types$ = this._editor_list_product_types.asObservable();

  public set login(invisible: boolean) {
    console.log('VisibleService::home : login=' + invisible);
    this._login.next(invisible);
  }

  public set home(invisible: boolean) {
    console.log('VisibleService::home : invisible=' + invisible);
    this._home.next(invisible);
  }

  public set editor_list_product_types(invisible: boolean) {
    console.log('VisibleService::editor_list_product_types : invisible=' + invisible);
    this._editor_list_product_types.next(invisible);
  }

  constructor() {
  }
}
