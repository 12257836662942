<table style="width: 100%">
  <tr>
    <td colspan="2">
      {{data.title}}
    </td>
  </tr>
  <tr>
    <td colspan="2">
      {{data.message}}
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <mat-form-field>
        <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onChangedSelectingItem($event)">
          <mat-option *ngFor="let item of data.items" [value]="item.key">
            {{item.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </tr>
  <tr>
    <td colspan="2" style="text-align: right">
      <button mat-flat-button class="yes" (click)="onOkClicked($event)">OK</button>
      <button mat-flat-button class="no" (click)="onCancelClicked($event)">Cancel</button>
    </td>
  </tr>
</table>