<div class="app-refferal-points">
  <table>
    <tr>
      <td class="l">
        <mat-checkbox [checked]="unissued_only" (change)="onUnissuedOnlyChanged($event)">Unissued only</mat-checkbox>
      </td>
      <td class="c">

      </td>
      <td class="r">

      </td>
    </tr>
    <tr>
      <td class="l">
        <mat-form-field>
          <input matInput [(ngModel)]="filter_string" (keyup)="onSearchStringKeyUp($event.target.value)" placeholder="Search...">
        </mat-form-field>
      </td>
      <td class="c">
        <button mat-icon-button class="edit_icon" (click)="onContractMonthPrev($event)">
          <mat-icon aria-label="Refresh list">keyboard_arrow_left</mat-icon>
        </button>
        {{monthExpression(contract_start_month)}}
        <button mat-icon-button class="edit_icon" (click)="onContractMonthNext($event)">
          <mat-icon aria-label="Refresh list">keyboard_arrow_right</mat-icon>
        </button>
      </td>
      <td class="r">
        <table style="width: 0; margin-left: auto; margin-right: 0;">
          <tr>
            <td><button mat-flat-button (click)="onExportUnissuedReferralPoints($event)" class="r">Export</button></td>
            <td><button mat-flat-button (click)="onApplyUnissuedPoints($event)" class="r">Apply</button></td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table mat-table [dataSource]="data_source" matSort>
    <ng-container matColumnDef="contract_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-heade> Contract code </th>
      <td mat-cell *matCellDef="let element">
        <a class="contract_code" target="_blank" href="{{'/operation/contract?contract_id=' + element.contract_id}}">{{element.contract_code}}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="contract_start_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Start at </th>
      <td mat-cell *matCellDef="let element">
        {{dateExpressionByString(element.contract_start_at)}}
      </td>
    </ng-container>
    <ng-container matColumnDef="order_user_email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> email </th>
      <td mat-cell *matCellDef="let element">
        {{element.order_user_email}}
      </td>
    </ng-container>
    <ng-container matColumnDef="order_user_full_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> name </th>
      <td mat-cell *matCellDef="let element">
        {{element.order_user_full_name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="invite_user_email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> email (invite) </th>
      <td mat-cell *matCellDef="let element">
        {{element.invite_user_email}}
      </td>
    </ng-container>
    <ng-container matColumnDef="invite_user_full_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> name (invite) </th>
      <td mat-cell *matCellDef="let element">
        {{element.invite_user_full_name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="paid_at_start_month">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> paid </th>
      <td mat-cell *matCellDef="let element">
        {{paidExpression(element.paid_at_start_month)}}
      </td>
    </ng-container>
    <ng-container matColumnDef="point_issued">
      <th mat-header-cell *matHeaderCellDef>issued</th>
      <td mat-cell *matCellDef="let element" style="text-align: center;">
        <mat-checkbox (change)="onIssuedCheckChanged(element)" [(checked)]="element.point_issued" [disabled]="!element.paid_at_start_month || element.disabled">
        </mat-checkbox>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
    <tr mat-row *matRowDef="let row; columns: display_columns;"></tr>
  </table>
</div>