<div class="app_shipwork">
  <table class="tool_header">
    <tr>
      <td>
        <div style="date_picker">
          <mat-form-field style="min-width: 6rem;">
            <mat-select placeholder="表示アイテム" (selectionChange)="onChangeSelectingItemKind($event)" value="{{selected_item_kind.key}}">
              <mat-option *ngFor="let kind of item_kinds" [value]="kind.key">
                {{kind.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
      <td>
        &nbsp;
      </td>
      <td>
        契約開始日 :
        <div class="date_picker">
          <mat-form-field class="full_width">
            <input matInput [matDatepicker]="fromDatePicker" placeholder="From"
              [value]="from_date" (dateChange)="onStartDateFromChanged($event)">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div style="display: inline-block; margin-left: 1rem;">
          〜
        </div>
        <div class="date_picker">
          <mat-form-field class="full_width">
            <input matInput [matDatepicker]="toDatePicker" placeholder="To"
              [value]="to_date" (dateChange)="onStartDateToChanged($event)">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="date_picker">
          <button mat-icon-button class="icon"
            (click)="onRefreshList($event)">
            <mat-icon aria-label="Refresh">refresh</mat-icon>
          </button>
        </div>
      </td>
    </tr>
  </table>
  <div class="shipping_items">
    <table>
      <div class="contract" *ngFor="let contract of contracts">
        <tr>
          <td>
            <div class="inline">
              <div class="inline">
                <a href="/operation/contract_print?contract_id={{contract.contract_id}}" 
                    target="_blank">
                    <mat-icon aria-label="Print contract">print</mat-icon>
                </a>
              </div>
              <div class="inline">
                <a *ngIf="contract.order_source === 'Checkfront'" 
                  style="color: white; background-color: blue; left-margin: 0.5em; right-margin: 0.5em;"
                  href="https://sakuramobilemonthly.checkfront.com/booking/{{contract.contract_code}}"
                  target="_blank">Checkfront
                </a>
              </div>
              <div class="inline">
                <a href="/operation/contract?contract_id={{contract.contract_id}}"
                  target="_blank">{{contract.contract_code}}
                </a>
              </div>
              <div class="inline">
                {{resolveStatusToText(contract.contract_status)}}
              </div>
              <div class="inline">
                <mat-icon aria-label="Payment">payment</mat-icon>{{contract.pay_method.value}}
              </div>
              <div class="inline">
                <mat-icon aria-label="Pick up method">pan_tool</mat-icon>{{contract.pickup_method.value}}
              </div>
              <div class="inline" *ngIf="contract.pay_method && (contract.pay_method.value === 'SMARTPIT' || contract.pay_method.value === 'PONTA') && contract.card_number">
                {{contract.pay_method.value}}番号:
                <input [(value)]="contract.card_number.value"
                  (keyup)="onKeyUpContractProperty({event: $event, data:
                  contract.card_number, is_unique: false, value_type: 'text'})"
                  (blur)="onBlurContractProperty({event: $event, data:
                  contract.card_number, is_unique: false, value_type: 'text'})"
                  />
              </div>
              <div class="inline">
                <mat-icon aria-label="Pick up place">location_city</mat-icon>{{contract.pickup_place_1.value}}
              </div>
              <br/>
              <div class="inline">
                <mat-icon aria-label="Shipping method">local_shipping</mat-icon>
                <select #shipping_method_opts name="shipping_method" (change)="onChangeShippingMethod({contract_id: 
                  contract.contract_id, new_shipping_method: shipping_method_opts.value})">
                  <option>-- SHIPPING METHOD --</option>
                  <option>Sagawa</option>
                  <option>Postal</option>
                  <option>Airport</option>
                  <option>Shinjuku counter</option>
                  <option>other counter</option>
                </select>
              </div>
              <div class="inline" *ngIf="contract.send_tracking_number">
                追跡番号(送付) :
                <input [(value)]="contract.send_tracking_number.value"
                  (keyup)="onKeyUpContractProperty({event: $event, data:
                  contract.send_tracking_number, is_unique: false, value_type:
                  'text'})"
                  (blur)="onBlurContractProperty({event: $event, data:
                  contract.send_tracking_number, is_unique: false, value_type:
                  'text'})" />
              </div>
              <div class="inline" *ngIf="contract.return_tracking_number">
                追跡番号(返却) :
                <input [(value)]="contract.return_tracking_number.value"
                  (keyup)="onKeyUpContractProperty({event: $event, data:
                  contract.return_tracking_number, is_unique: false, value_type:
                  'text'})"
                  (blur)="onBlurContractProperty({event: $event, data:
                  contract.return_tracking_number, is_unique: false, value_type:
                  'text'})" />
              </div>
            </div>
            <div class="inline">
              <button mat-raised-button color="warn" style="transform: translateY(-0.5rem)"
                (click)="onSendShippingNotifyClicked({contract_id: contract.contract_id, shipping_method: shipping_method_opts.value})"
                [disabled]="contract.ship_disable">Ship &amp; Send notification
              </button>
            </div>
            <div class="product" *ngFor="let product of contract.products">
              <div class="product_title">
                商品 : {{product.product_title}}
              </div>
              <div class="start_at">
                利用開始 : {{product.start_at}}
              </div>
              
              <div *ngIf="!isESIMProduct(product.product_title)" class="device_code_1">
                <!-- <div *ngIf="product.device_code_1"> -->
                  SIM ID :
                  <button mat-icon-button class="icon"
                    (click)="onAssignSimID({event: $event, data:
                    product.device_code_1})">
                    <mat-icon aria-label="Assign new SIM ID">sim_card</mat-icon>
                  </button>
                  <input [(value)]="product.device_code_1.value"
                    (keyup)="onKeyUpProductProperty({event: $event, data:
                    product.device_code_1, is_unique: true, value_type:
                    'text'})"
                    (blur)="onBlurProductProperty({event: $event, data:
                    product.device_code_1, is_unique: true, value_type:
                    'text'})" />
                <!-- </div> -->
              </div>
              <div *ngIf="!isESIMProduct(product.product_title)" class="kpd_line_number">
                <div *ngIf="product.kpd_line_number">
                  回線番号 <span *ngIf="product.use_mnp !== null && product.use_mnp == true" style="color: green;">[MNP]&nbsp;</span>
                  <input [(value)]="product.kpd_line_number.value"
                    (keyup)="onKeyUpProductProperty({event: $event, data:
                    product.kpd_line_number, is_unique: true, value_type:
                    'tel'})"
                    (blur)="onBlurProductProperty({event: $event, data:
                    product.kpd_line_number, is_unique: true, value_type:
                    'tel'})" />
                </div>
              </div>
              <div *ngIf="!isESIMProduct(product.product_title)" class="kpd_iccid">
                <div *ngIf="product.kpd_iccid">
                  ICCID :
                  <input [(value)]="product.kpd_iccid.value"
                    (keyup)="onKeyUpProductProperty({event: $event, data:
                    product.kpd_iccid, is_unique: true, value_type: 'text'})"
                    (blur)="onBlurProductProperty({event: $event, data:
                    product.kpd_iccid, is_unique: true, value_type: 'text'})" />
                </div>
              </div>
              <div *ngIf="!isESIMProduct(product.product_title)" class="activate_link">
                <div *ngIf="product.enable_activate">
                  <button style="margin-left: 0; margin-right: 0;" mat-button
                    (click)="onClickActivateSIM({event: $event, contract_id:
                    contract.contract_id, contract_product_id:
                    product.contract_product_id})">Activate
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </div>
    </table>
  </div>
</div>