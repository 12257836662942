import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';

export class DateDialogData {
  title: string;
  message: string;
  initial_date: string;
}

@Component({
  selector: 'app-date-dialog',
  templateUrl: './date-dialog.component.html',
  styleUrls: ['./date-dialog.component.scss']
})
export class DateDialogComponent implements OnInit {

  public selected_date: string;
  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DateDialogData
  ) {
    if (Utils.isValue(this.data.initial_date)) {
      this.selected_date = this.data.initial_date;
    }
  }

  ngOnInit() {
    console.log(this.data);
  }

  public onCancelClicked(event): void {
    console.log('ConfirmDialog::onCancelClicked -start');
    this.dialogRef.close({
      closeby: 'cancel'
    });
  }

  public onOkClicked(event): void {
    console.log('ConfirmDialog::onOkClicked -start');
    this.dialogRef.close({
      closeby: 'ok',
      selected_date: this.selected_date
    });
  }

  public onValueChanged(event): void {
    console.log('ConfirmDialog::onChangedSelectingItem - start');
    this.selected_date = Utils.dateToDateString(event.value);
  }
}
