import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-invoice-confirm-dialog',
  templateUrl: './send-invoice-confirm-dialog.component.html',
  styleUrls: ['./send-invoice-confirm-dialog.component.scss']
})
export class SendInvoiceConfirmDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
