import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';
import { FlashMessage, AuthGetReply, ContractListItem } from '../../../models';

export class GenerateInvoiceConfirmDialogData {
  invoice_month: string;
  contract: ContractListItem;
}

@Component({
  selector: 'app-generate-invoice-confirm-dialog',
  templateUrl: './generate-invoice-confirm-dialog.component.html',
  styleUrls: ['./generate-invoice-confirm-dialog.component.scss']
})
export class GenerateInvoiceConfirmDialogComponent implements OnInit {
  public button_generate_disable: boolean;
  public button_cancel_disable: boolean;
  public over_write_warning_display: string;
  public invoice_month: string;
  public has_invoice: boolean;

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<GenerateInvoiceConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenerateInvoiceConfirmDialogData
  ) {
    this.over_write_warning_display = 'none';
    this.button_generate_disable = true;
    this.button_cancel_disable = false;
  }

  ngOnInit() {}

  public onChargeMonthChanged(month: string) {
    // console.log('GenerateInvoiceConfirmDialogComponent::onClickGenerate - start');
    if (!Utils.isValue(month) && month.length <= 0) {
      return;
    }
    this.invoice_month = month;
    if (Utils.isValue(this.data.contract)) {
      // selecting
      const params = {
        invoice_month: this.invoice_month,
        contract_code: this.data.contract.contract_code
      };
      this.auth_service.apiHasInvoiceWithMonth(params, this, function(
        reply: AuthGetReply,
        context: any
      ) {
        // console.log('GenerateInvoiceConfirmDialogComponent::apiHasInvoicesWithMonth : reply_proc');
        if (
          context.invoice_month !== undefined &&
          context.invoice_month.length > 0
        ) {
          context.button_generate_disable = false;
        }
        context.has_invoice = <boolean>reply.data.having;
        context.over_write_warning_display = context.has_invoice
          ? 'block'
          : 'none';
      });
    } else {
      // all
      const params = {
        invoice_month: this.invoice_month
      };
      this.auth_service.apiHasInvoicesWithMonth(params, this, function(
        reply: AuthGetReply,
        context: any
      ) {
        // console.log('GenerateInvoiceConfirmDialogComponent::apiHasInvoicesWithMonth : reply_proc');
        if (
          context.invoice_month !== undefined &&
          context.invoice_month.length > 0
        ) {
          context.button_generate_disable = false;
        }
        context.has_invoice = <boolean>reply.data.having;
        context.over_write_warning_display = context.has_invoice
          ? 'block'
          : 'none';
      });
    }
  }

  public onClickGenerate(event): void {
    console.log(
      'GenerateInvoiceConfirmDialogComponent::onClickGenerate - start'
    );
    this.data.invoice_month = this.invoice_month;
    if (Utils.isValue(this.data.contract)) {
      // selecting : generate an invoice here
      const params = {
        invoice_month: this.invoice_month,
        contract_code: this.data.contract.contract_code
      };
      this.auth_service.apiGenerateInvoice(params, this, function(
        reply: AuthGetReply,
        context: any
      ) {
        context.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        context.snackBar.open(
          'Generate invoice successfully done.',
          'OK',
          cfg
        );
        console.log(reply);
        // console.log('GenerateInvoiceConfirmDialogComponent::apiHasInvoicesWithMonth : reply_proc');
        context.dialogRef.close({
          generate: true,
          invoice_month: context.invoice_month,
          contract: context.data.contract
        });
      });
    } else {
      // all : close => progress dialog => generate invoices
      this.dialogRef.close({
        generate: true,
        invoice_month: this.invoice_month
      });
    }
  }

  public onClickCancel(event): void {
    console.log('GenerateInvoiceConfirmDialogComponent::onClickCancel - start');
    this.dialogRef.close();
  }
}
