import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import { ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';
import { Duration } from '../../../helpers/units';

export class SystemTaskListItem {
  system_task_id: number;
  user_id: number;
  title: string;
  description: string;
  result: number;
  start_at: Date;
  start_at_duration: Duration;
  end_at: Date;
  end_at_duration: Duration;
  confirmed: boolean;
  confirmed_at: Date;
  created_at: Date;
  updated_at: Date;
  constructor() {
    this.confirmed = false;
    this.confirmed_at = undefined;
    this.start_at_duration = undefined;
    this.end_at_duration = undefined;
  }
}

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit, OnDestroy {

  protected _el: HTMLElement;
  protected _notification_interval_id: any;
  public notification_summary_string: string;

  public toolbar_title: string;
  public display_list_contracts: string;
  public display_fiber_schedules: string;
  public display_list_invoices: string;
  public display_ship_work: string;
  public display_file_import: string;
  public display_file_export: string;
  public display_search_fast: string;
  public display_search: string;
  public display_dashboard: string;
  public display_charges: string;
  public display_utilities: string;
  public display_refferal_points: string;
  public display_top_up_tickets: string;
  public monthly_summary: any;

  private _is_logging_in: boolean;
  private _subscription_is_logging_in: Subscription;

  public display_system_tasks: Array<SystemTaskListItem>;
  private _received_system_tasks: Array<SystemTaskListItem>;
  private _system_task_refresh_mode: boolean;

  constructor(
    protected el: ElementRef,
    private auth_service: AuthService,
  ) {
    this._el = el.nativeElement;
    this._notification_interval_id = undefined;
    this.toolbar_title = 'Search';
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'block';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_fiber_schedules = 'none';
    this.display_top_up_tickets = 'none';
    this._system_task_refresh_mode = false;
    this.monthly_summary = null;
  }

  ngOnInit() {
    document.title = 'Operation';
    this._subscription_is_logging_in = this.auth_service.logging_in$.subscribe(
      logging_in => {
        console.log('LoginComponent::_subscription_is_logging_in : logging_in=' + logging_in);
        if (logging_in === true) {
          console.log('LoginComponent::_subscription_is_logging_in (success to login): logging_in=' + logging_in);
        } else {
          console.log('LoginComponent::_subscription_is_logging_in (failed to login): logging_in=' + logging_in);
          location.href = '/auth/login';
        }
      }
    );
    this.onUpdateMonthlySummary(null);

    // 稼働中タスクの通知管理
    // this._loadListSystemTasksFromStorage();
    // this._optimizeListSystemTasks();
    // this._notification_interval_id = setInterval(function() {
    //   this._checkListSystemTasks();
    // }.bind(this), 60000);
  }

  private _updateNotificationSummaryString(): void {
    if (Utils.isValue(this.display_system_tasks)) {
      const unconfirmed = this.display_system_tasks.filter(system_task => {
        return system_task.confirmed !== true;
      });
      this.notification_summary_string = '通知 ' + this.display_system_tasks.length + '件 / 未確認 ' + unconfirmed.length + '件';
    } else {
      this.notification_summary_string = '確認中...';
    }
  }

  private _optimizeListSystemTasks(): void {
    if (!Utils.isValue(this._received_system_tasks) || this._received_system_tasks.length <= 0) {
      return;
    }
    this._received_system_tasks.forEach(in_task => {
      const pushed_tasks = this.display_system_tasks.filter(
        dest_task => {
          return dest_task.system_task_id === in_task.system_task_id;
        }
      );
      if (pushed_tasks.length <= 0) {
        this.display_system_tasks.push(in_task);
      }
    });
  }

  private _loadListSystemTasksFromStorage(): void {
    this.display_system_tasks = JSON.parse(localStorage.getItem('system_tasks_notification_messages'));
    this._received_system_tasks = new Array<SystemTaskListItem>();
    if (!Utils.isValue(this.display_system_tasks)) {
      this.display_system_tasks = new Array<SystemTaskListItem>();
    }
    // レスポンスに含まれないプロパティの初期化
    this.display_system_tasks.forEach(task => {
      if (task.confirmed === undefined) {
        task.confirmed = false;
      }
      if (task.confirmed_at === undefined) {
        task.confirmed_at = null;
      }
    });
    this._updateNotificationSummaryString();
  }

  private _saveListSystemTaskFromStorage(): void {
    localStorage.setItem('system_tasks_notification_messages', JSON.stringify(this.display_system_tasks));
  }

  private _checkListSystemTasks(): void {
    const params = {
      only_working: false,
      duration: 24 * 60 * 60,
    };
    this.auth_service.apiListSystemTasks(params).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          this._received_system_tasks = new Array<SystemTaskListItem>();
          response.data.forEach(element => {
            if (Utils.isValue(element.start_at)) {
              // TO Convert from UTC Date expression to Date
              element.start_at = new Date(element.start_at);
            }
            if (Utils.isValue(element.end_at)) {
              // TO Convert from UTC Date expression to Date
              element.end_at = new Date(element.end_at);
            }
            this._received_system_tasks.push(this._newSystemTaskFromResponse(element));
          });
          this._optimizeListSystemTasks();
          this._updateNotificationSummaryString();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  private _newSystemTaskFromResponse(src: any): SystemTaskListItem {
    const new_system_task = new SystemTaskListItem();
    new_system_task.system_task_id = src.system_task_id;
    new_system_task.user_id = src.user_id;
    new_system_task.title = src.title;
    new_system_task.description = src.description;
    new_system_task.result = src.result;
    new_system_task.start_at = new Date(src.start_at);
    if (Utils.isValue(src.start_at)) {
      new_system_task.start_at_duration = new Duration(Date.now() - new_system_task.start_at.getTime());
    }
    new_system_task.end_at = new Date(src.end_at);
    if (Utils.isValue(src.end_at)) {
      new_system_task.start_at_duration = new Duration(Date.now() - new_system_task.end_at.getTime());
    }
    new_system_task.confirmed = false;
    new_system_task.confirmed_at = null;
    return new_system_task;
  }

  ngOnDestroy() {
    if (Utils.isValue(this._notification_interval_id)) {
      clearInterval(this._notification_interval_id);
    }
  }

  public onUpdateMonthlySummary(event: any): void {
    console.log('OperationComponent::onUpdateMonthlySummary() clicked');
    const dt = new Date;
    const params = {
      summary_month: dt.toISOString().slice(0, 7)
    };
    this.auth_service.apiMonthlySummary(params).subscribe(
      response => {
        this.monthly_summary = response.data;
        // console.log('Response', response);

      },
      error => {
        console.log('onUpdateMonthlySummary : Error', error);
      }
    );
  }

  public onShowContracts(event: any): void {
    console.log('OperationComponents::onShowInvoices : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'block';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Contracts';
  }

  public onShowFiberSchedules(event: any): void {
    console.log('OperationComponents::onShowInvoices : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'block';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Fiber Schedules';
  }

  public onShowInvoices(event: any): void {
    console.log('OperationComponents::onShowInvoices : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'block';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Invoices';
  }

  public onShowFileImport(event: any): void {
    console.log('OperationComponents::onShowFileImport : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'block';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'File import';
  }

  public onShowFileExport(event: any): void {
    console.log('OperationComponents::onShowFileExport : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'block';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'File export';
  }

  public onShowReferralPoints(event: any): void {
    console.log('OperationComponents::onShowWooPointExport : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'block';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Point';
  }

  public onShowTopUpTickets(event: any): void {
    console.log('OperationComponents::onShowTopUpTickets : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'block';
    this.toolbar_title = 'Top-Up tickets';
  }

  public onShowSearchFast(event: any): void {
    console.log('OperationComponents::onShowSearchFast : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'block';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Search(Fast)';
  }

  public onShowSearch(event: any): void {
    console.log('OperationComponents::onShowSearch : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'block';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Search';
  }

  public onClickShipWork(event: any): void {
    console.log('OperationComponents::onShowFileExport : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'block';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Shipping';
  }

  public onClickChargeWork(event: any): void {
    console.log('OperationComponents::onClickChargeWork : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'block';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Charge';
  }

  public onClickUtilities(event: any): void {
    console.log('OperationComponents::onClickChargeWork : ');
    this.display_dashboard = 'none';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'block';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Utilities';
  }

  public onClickDashboard(event: any): void {
    console.log('OperationComponents::onShowFileExport : ');
    this.display_dashboard = 'block';
    this.display_list_contracts = 'none';
    this.display_fiber_schedules = 'none';
    this.display_list_invoices = 'none';
    this.display_ship_work = 'none';
    this.display_file_import = 'none';
    this.display_file_export = 'none';
    this.display_search_fast = 'none';
    this.display_search = 'none';
    this.display_charges = 'none';
    this.display_utilities = 'none';
    this.display_refferal_points = 'none';
    this.display_top_up_tickets = 'none';
    this.toolbar_title = 'Dashboard';
  }

  public onClickLogout(event) {
    this.auth_service.logout();
  }

  public onMouseEnterSystemTaskNotification(): void {
    console.log('OperationComponent::onMouseEnterSystemTaskNotification');
    this._checkListSystemTasks();
  }
  public onMouseLeaveSystemTaskNotification(): void {
    console.log('OperationComponent::onMouseLeaveSystemTaskNotification');
  }
  public onClickSystemTaskNotifications(): void {
    const notification_panel = this._el.querySelector('div#system_task_notification_list');
    if (Utils.isValue(notification_panel)) {
      (<HTMLDivElement>(notification_panel)).style.display = 'block';
    }
  }
  public onClickRefreshSystemTaskNotifications(): void {
    this._system_task_refresh_mode = true;
    this.display_system_tasks = new Array<SystemTaskListItem>();
    this._optimizeListSystemTasks();
    this._updateNotificationSummaryString();
    this._saveListSystemTaskFromStorage();
  }
  public onClickCloseSystemTaskNotifications(): void {
    const notification_panel = this._el.querySelector('div#system_task_notification_list');
    if (Utils.isValue(notification_panel)) {
      (<HTMLDivElement>(notification_panel)).style.display = 'none';
    }
  }
  public onClickConfirmedSystemTask(confirmed_system_task: SystemTaskListItem): void {
    const found_tasks = this.display_system_tasks.filter(dest_task => {
      return dest_task.system_task_id === confirmed_system_task.system_task_id;
    });
    if (found_tasks.length > 0) {
      found_tasks[0].confirmed = true;
      found_tasks[0].confirmed_at = new Date();
    }
    this._saveListSystemTaskFromStorage();
  }
}
