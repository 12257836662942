import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { UserProperty } from '../../../models/contract-detail';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';
import { FlashMessage, AuthGetReply } from '../../../models';
import { EditPropertyDialogBaseComponent } from '../edit-property-dialog-base/edit-property-dialog-base.component';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-edit-contract-email-dialog',
  templateUrl:
    '../edit-property-dialog-base/edit-property-dialog-base.component.html',
  styleUrls: [
    '../edit-property-dialog-base/edit-property-dialog-base.component.scss'
  ]
})
export class EditContractEmailDialogComponent extends EditPropertyDialogBaseComponent {
  public confirm_dialog_ref: any;

  constructor(
    protected auth_service: AuthService,
    public dialogRef: MatDialogRef<EditContractEmailDialogComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: UserProperty
  ) {
    super(dialogRef, data);
  }
  public onOkClicked(event) {
    if (event.value === this.input_value) {
      return;
    }

    const is_exist_param = {
      user_id: this.input_value
    };
    this.auth_service.apiIsExistUser(is_exist_param).subscribe(
      response => {
        if (response.data.exist === true) {
          this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
            width: '50rem',
            hasBackdrop: true,
            data: {
              title: '異なるユーザーが使用しているメールアドレス',
              message: '同じメールアドレスが異なるユーザーのメールアドレスとして登録されています。<br/>[OK]で進めると変更後のメールアドレスを使用していたユーザーが、この契約の契約者となります。<br/>契約者を変更するとユーザー情報全体が変更後のメールアドレスを使用しているユーザーのデータにさし変わりますがよろしいですか？'
            }
          });
          this.confirm_dialog_ref.afterClosed().subscribe(result => {
            console.log(result);
            if (Utils.isValue(result)) {
              if (result.closeby === 'ok') {
                this.data.value = this.input_value;
                const update_param = {
                  exist_user_email: this.input_value,
                  contract_id: this.data.data.id,
                };
                this.auth_service.apiChangeContractOwner(update_param).subscribe(
                  update_response => {
                    this.dialogRef.close({
                      applied: true
                    });
                  },
                  update_error => {
                    console.log(update_error);
                    this.snackBar.dismiss();
                    const cfg = new MatSnackBarConfig();
                    cfg.duration = 5000;
                    cfg.panelClass = ['notify_snackbar', 'error'];
                    this.snackBar.open(update_error.error.message.message, 'OK', cfg);
                  }
                );
              }
            }
          });
        } else {
          this.data.value = this.input_value;
          const update_param = {
            id: this.data.user_property_id,
            value: this.data.value
          };
          this.auth_service.apiUpdateUserProperty(update_param, this, function(
            reply: AuthGetReply,
            context: any
          ) {
            console.log(context);
            context.dialogRef.close({
              applied: true
            });
          });
        }
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }
}
