import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { ContractProductProperty } from '../../../models/contract-detail';
import { AuthService } from '../../../services/auth';
import { FlashMessage, AuthGetReply } from '../../../models';
import { EditPropertyDialogBaseComponent } from '../edit-property-dialog-base/edit-property-dialog-base.component';

@Component({
  selector: 'app-edit-product-property-dialog',
  templateUrl:
    '../edit-property-dialog-base/edit-property-dialog-base.component.html',
  styleUrls: [
    '../edit-property-dialog-base/edit-property-dialog-base.component.scss'
  ]
})
export class EditProductPropertyDialogComponent extends EditPropertyDialogBaseComponent {
  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditProductPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContractProductProperty
  ) {
    super(dialogRef, data);
  }

  public onOkClicked(event) {
    console.log('EditProductPropertyDialogComponent::onOkClicked : new value = ' + event);
    this.data.value = this.input_value;
    const param = {
      id: this.data.contract_product_property_id,
      value: this.data.value
    };
    this.auth_service.apiSetContractProductProperty(param).subscribe(
      response => {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open('Product property changed.', 'OK', cfg);
        this.dialogRef.close({
          applied: true
        });
      },
      error => {
        console.log('ContractComponent::onOkClicked - error');
        console.log(error.error.message.message);
        const error_message = AuthService.getErrorMessage(error);
        if (error_message !== undefined) {
          console.log(
            'EditProductPropertyDialogComponent::onOkClicked - has message'
          );
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
      }
    );
  }
}
