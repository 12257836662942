import {Duration} from './units';

export class Utils {
  public static isValue(value): boolean {
    return value !== null && value !== undefined;
  }

  public static isNumber(value: string): boolean {
    const reg = /^\d+$/;
    return reg.test(value);
  }

  public static areValues(values: any[]): boolean {
    if (values === null || values === undefined) {
      return false;
    }
    values.forEach(value => {
      if (Utils.isValue(value)) {
        return false;
      }
    });
    return true;
  }

  public static getUrlParameter(name): string {
    const param_part = window.location.search.substring(1);
    const param_parts = param_part.split('&');
    const params = param_parts.map(v => {
      return v.split('=');
    });
    const target = params.filter(v => {
      return v[0] === name;
    });
    if (target.length > 0) {
      return target[0][1];
    }
    return undefined;
  }

  public static getDatePart(strDateTime: string, until_month: boolean): string {
    if (!Utils.isValue(strDateTime)) {
      return '';
    }
    const elem = strDateTime.split('T');
    if (elem.length >= 2) {
      if (until_month) {
        return elem[0].substring(0, 7);
      }
      return elem[0];
    }
    return strDateTime;
  }

  public static getLimitedLeftString(
    strSrc: string,
    max_length: number
  ): string {
    if (!Utils.isValue(strSrc)) {
      return '';
    }
    if (strSrc.length >= max_length) {
      return strSrc.substring(0, max_length - 3) + '...';
    }
    // console.log(strSrc + ' : ' + strSrc.length.toString());
    return strSrc;
  }

  public static getLocalTimeString(offsetZero: string): string {
    if (!Utils.isValue(offsetZero)) {
      return '';
    }
    const result = new Date(offsetZero);
    return result.toLocaleString(Utils.getHighestPriorityLocale());
  }

  public static getHighestPriorityLocale(): string {
    const language =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language;
    return language;
  }

  public static getUTCElements(date: Date): any {
    const date_elements = date.toUTCString().split(' ');
    const time_elements = date_elements[4].split(':');

    const day_of_week = date_elements[0].replace(/,/g, '');

    const result = {
      year: date_elements[3],
      month: date_elements[2],
      day: date_elements[1],
      day_of_week: day_of_week,
      hour: time_elements[0],
      minite: time_elements[1],
      second: time_elements[2]
    };
    return result;
  }

  public static getDisplayDatetimeString(utc_dt_string: string): string {
    if (!Utils.isValue(utc_dt_string) || utc_dt_string.length === 0) {
      return '';
    }
    const dt_tmp = new Date();
    const dest_dt = new Date(utc_dt_string);
    dest_dt.setMinutes(dest_dt.getMinutes() - dt_tmp.getTimezoneOffset());
    return dest_dt.toISOString().replace('T', ' ').replace('Z', '');
  }

  public static getDisplayDateString(utc_dt_string: string): string {
    if (!Utils.isValue(utc_dt_string) || utc_dt_string.length === 0) {
      return '';
    }
    return Utils.getDisplayDatetimeString(utc_dt_string).split(' ')[0];
  }

  public static getDisplayTimeString(utc_dt_string: string): string {
    if (!Utils.isValue(utc_dt_string) || utc_dt_string.length === 0) {
      return '';
    }
    return Utils.getDisplayDatetimeString(utc_dt_string).split(' ')[1];
  }

  public static getBackendDatetimeString(local_dt_string: string, time_adjust: string): string {
    const dt_tmp = new Date();
    const dest_dt = new Date(local_dt_string);
    if (time_adjust === 'begin') {
      dest_dt.setHours(0, 0, 0, 0);
      console.log('Utils::getBackendDatetimeString : local=' + local_dt_string + ' : dest_dt=' + dest_dt.toISOString());
    } if (time_adjust === 'end') {
      dest_dt.setHours(23, 59, 59, 0);
      console.log('Utils::getBackendDatetimeString : local=' + local_dt_string + ' : dest_dt=' + dest_dt.toISOString());
    }
    return dest_dt.toISOString();
  }

  public static getDurationFromDates(old_date: Date, new_date: Date): Duration {
    return new Duration(new_date.getTime() - old_date.getTime());
  }

  public static monthStringToDate(
    month_string: string,
    month_adjust: number,
    day_adjust: number
  ): Date {
    console.log(`monthStringToDate : ${month_string}, ${month_adjust}, ${day_adjust}`)
    return Utils.dateAdjust(
      month_string + '-01 00:00:00 GMT+00:00',
      month_adjust,
      day_adjust
    );
  }

  public static dateAdjust(
    date_string: string,
    month_adjust: number,
    day_adjust: number
  ): Date {
    console.log(`dateAdjust : ${date_string}, ${month_adjust}, ${day_adjust}`)
    const dts = date_string.split(' ');
    const dt = new Date(`${dts[0]} ${dts[1]}`);
    if (month_adjust === -99) {
      dt.setMonth(0);
    } else if (month_adjust === 99) {
      dt.setMonth(11);
    } else {
      dt.setMonth(dt.getMonth() + month_adjust);
    }
    if (day_adjust === -99) {
      dt.setDate(1);
    } else if (day_adjust === 99) {
      dt.setMonth(dt.getMonth() + 1);
      dt.setDate(0);
    } else {
      dt.setDate(dt.getDate() + day_adjust);
    }
    return dt;
  }

  public static dateToDateString(
    date: Date
  ): string {
    const strY = date.getFullYear().toString().padStart(4, '0');
    const strM = (date.getMonth() + 1).toString().padStart(2, '0');
    const strD = date.getDate().toString().padStart(2, '0');
    if (Utils.isValue(date)) {
      return strY + '-' + strM + '-' + strD;
    } else {
      return undefined;
    }
  }

  public static dateToJSTString(date: Date): string {
    const strY = date.getFullYear().toString().padStart(4, '0');
    const strM = (date.getMonth() + 1).toString().padStart(2, '0');
    const strD = date.getDate().toString().padStart(2, '0');
    const str_H = date.getHours().toString().padStart(2, '0');
    const str_M = (date.getMinutes()).toString().padStart(2, '0');
    const str_S = date.getSeconds().toString().padStart(2, '0');
    if (Utils.isValue(date)) {
      return `${strY}-${strM}-${strD}T${str_H}:${str_M}:${str_S}.000+09:00`;
    } else {
      return undefined;
    }
  }

  public static UTF8StringToUint8Array(string) {
    const str = btoa(unescape(encodeURIComponent(string)));
    const charList = str.split('');
    const uintArray = [];
    for (let i = 0; i < charList.length; i++) {
        uintArray.push(charList[i].charCodeAt(0));
    }
    return new Uint8Array(uintArray);
  }

  public static isSimpleTelString(tel: string) {
    const accept = /^[-\d]{1,14}$/;
    return accept.test(tel);
  }

  public static toTelString(tel: string) {
    const accept = /^\d*$/;
    const tel_trimed = tel.trim();
    if (accept.test(tel_trimed) && tel_trimed.length === 11) {
      const ret = tel_trimed.substr(0, 3) + '-' + tel_trimed.substr(3, 4) + '-' + tel_trimed.substr(7, 4);
      return ret;
    }
    if (!Utils.isSimpleTelString(tel)) {
      console.log('WARNING : tel number is not valid format(simple) : 3.4.4 : "' + tel + '"');
    }
    return tel_trimed;
  }

  public static yen(num: number): string {
    const formatter = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY'
    });
    let formatted = formatter.format(num);
    formatted = formatted.replace('-￥', '￥-');
    return formatted;
  }

  public static joinStringsToBRLines(items: Array<string>): string {
    let joined = '';
    if (items.length <= 1) {
      joined = items[0];
    } else {
      for (let i = 0; i < items.length; i++) {
        joined = joined + items[i];
        if (i < items.length - 1) {
          joined = joined + '<br/>';
        }
      }
    }
    return joined;
  }
}
