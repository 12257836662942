import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatCheckboxChange,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Utils } from '../../../helpers';
import { AuthService } from '../../../services/auth';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';

export class Contract {
  id: number;
  contract_code: string;
  status: boolean;
  start_at: Date;
  start_at_string: string;
  end_at: Date;
  end_at_string: string;
}

export class DeviceCode1 {
  id: number;
  contract_product_id: number;
  product_property_id: number;
  product_property_type_id: number;
  product_type_id: number;
  show_order: number;
  show_status: boolean;
  title: string;
  updated_by_user_id: number;
  updated_at: string;
  key: string;
  value: string;
}

export class ChargeProductItem {
  id: number;
  contract_id: number;
  description: string;
  end_at: string;
  end_at_updated_at: string;
  end_at_updated_by_user_id: number;
  invoice_sender_key: string;
  product_type_id: number;
  return_check_date: string;
  return_checker_user_id: number;
  return_date: string;
  show_order: number;
  start_at: string;
  start_at_updated_at: string;
  start_at_updated_by_user_id: number;
  title: string;
  device_code_1: DeviceCode1;
}

export class ChargeListItem {
  id: number; // invoice_id
  invoice_month: string;
  invoice_code: string;
  contract_id: number;
  contract_code: string;
  contract_end_date: string; // for sort only
  contract: Contract;
  user_id: number;
  user_e_mail: string;
  user_name_id: number;
  user_name: string;
  mail_send_status: number;
  mail_send_config: number;
  pay_method: string;
  demand_status: number;
  demand_send_user_id: number;
  demand_sent_at: string;
  pay_status: number;
  pay_error: string;
  price_total: number;
  price_tax: number;
  products_display_string: string;
  products: Array<ChargeProductItem>;
  is_selected: boolean;    // for user interface
  constructor() {
    this.is_selected = false;
  }
}

export class PayMethod {
  public key: string;
  public title: string;
}

export class DemandLevel {
  public key: string;
  public title: string;
}

@Component({
  selector: 'app-list-charges',
  templateUrl: './list-charges.component.html',
  styleUrls: ['./list-charges.component.scss']
})
export class ListChargesComponent implements OnInit {

  public root_for_pdf: string;
  public unpaid_only: boolean;
  public invoice_month: string;
  public selecting_pay_method: PayMethod;
  public pay_methods: Array<PayMethod>;
  public selecting_demand_level: DemandLevel;
  public demand_levels: Array<DemandLevel>;
  public demand_send_button_disabled: boolean;
  public charge_button_disabled: boolean;

  public invoices: Array<ChargeListItem>;
  public invoices_send_targets: Array<ChargeListItem>;
  public invoice_month_error: string;
  public display_columns: Array<string>;
  public data_source: MatTableDataSource<ChargeListItem>;
  public filter_text: string;
  public selection = new SelectionModel<ChargeListItem>(true, []);
  public current_filter: string;

  public select_send_all: boolean;
  public select_send_all_disabled: boolean;
  public select_send_all_indeterminate: boolean;
  public sent_invoice_codes: Array<string>;
  public sent_success_invoice_codes: Array<string>;
  public sent_fail_invoice_codes: Array<string>;
  protected _el: HTMLElement;
  public confirm_dialog_ref: any;

  constructor(
    protected el: ElementRef,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this._el = el.nativeElement;
    this.select_send_all = false;
    this.select_send_all_disabled = true;
    this.select_send_all_indeterminate = false;
    this.unpaid_only = true;
    this.invoice_month = undefined;
    this.filter_text = '';
    this.pay_methods = new Array<PayMethod>();
    this.pay_methods.push({ key: 'CREDIT CARD', title: 'CREDIT CARD' });
    this.pay_methods.push({ key: 'SMARTPIT', title: 'SMARTPIT' });
    this.pay_methods.push({ key: 'PAYPAL', title: 'PAYPAL' });
    this.pay_methods.push({ key: 'PONTA', title: 'PONTA' });
    this.pay_methods.push({ key: 'GI BILL', title: 'GI BILL' });
    this.demand_levels = new Array<DemandLevel>();
    this.demand_levels.push({ key: '1', title: 'Lv 1 : 未払いの通知' });
    this.demand_levels.push({ key: '2', title: 'Lv 2 : 強制解約の警告' });
    this.demand_levels.push({ key: '3', title: 'Lv 3 : サスペンドのお知らせ' });
    this.demand_send_button_disabled = true;
    this.charge_button_disabled = true;
    this._el = el.nativeElement;
    this.display_columns = [
      'is_selected',
      'contract_code',
      'invoice_code',
      'pdf',
      'products',
      'user_name',
      'pay_method',
      'pay_status',
      'pay_amount',
      'pay_error',
      'demand_level',
      'demand_sent_at',
      'contract_end_date',
    ];
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    // <<< for debug environment
    if (location.href.indexOf('localhost:', 0) > 0) {
      const parts = location.href.split(':');
      this.root_for_pdf = parts[0] + ':' + parts[1] + ':3001';
    } else {
      this.root_for_pdf = '';
    }
    // >>> for debug environment
  }

  public onRefreshList(event: any): void {
    this.refresh();
  }

  public onUnpaidOnlyCheckChanged(event: MatCheckboxChange): void {
    this.unpaid_only = event.checked;
    this.refresh();
  }

  public onInvoiceMonthChanged(invoice_month: string): void {
    this.invoice_month = invoice_month;
    this.refresh();
    this.updateDemandSendButtonDisabled();
  }

  public onChangedPayMethod(event: any): void {
    const filtered = this.pay_methods.filter(v => {
      return v.key === event.value;
    });
    if (Utils.isValue(filtered) && filtered.length > 0) {
      this.selecting_pay_method = filtered[0];
    }
    this.charge_button_disabled = this.selecting_pay_method.key !== 'CREDIT CARD';
    this.refresh();
    this.updateDemandSendButtonDisabled();
  }

  public onChangedDemandLevel(event: any): void {
    const filtered = this.demand_levels.filter(v => {
      return v.key === event.value;
    });
    if (Utils.isValue(filtered) && filtered.length > 0) {
      this.selecting_demand_level = filtered[0];
      console.log(this.selecting_demand_level);
    }
    this.updateDemandSendButtonDisabled();
  }

  private refresh(): void {
    if (!Utils.isValue(this.unpaid_only) || !Utils.isValue(this.invoice_month) || !Utils.isValue(this.selecting_pay_method)) {
      console.log('ListChargesComponent::skip refresh list');
      return;
    }

    const param = {
      unpaid_only: this.unpaid_only,
      invoice_month: this.invoice_month,
      pay_method: this.selecting_pay_method.key,
    };
    this.auth_service.apiListCharges(param).subscribe(
      response => {
        this.invoices = <Array<ChargeListItem>>response.data;
        this.data_source = new MatTableDataSource(this.invoices);
        this.data_source.sort = this.sort;
        this.data_source.paginator = this.paginator;
        this.select_send_all_disabled = this.invoices.length > 0 ? false : true;
        this.select_send_all = false;
        this.select_send_all_disabled = true;
        this.invoices.forEach(invoice => {
          console.log(invoice);
          this.select_send_all = true;
          this.select_send_all_disabled = false;
          invoice.is_selected = true;
          invoice.products_display_string = this.productsString(invoice.products);
          if (Utils.isValue(invoice.contract.start_at)) {
            invoice.contract.start_at_string = Utils.getDisplayDateString(invoice.contract.start_at.toLocaleString(Utils.getHighestPriorityLocale()));
          }
          if (Utils.isValue(invoice.contract.end_at)) {
            invoice.contract.end_at_string = Utils.getDisplayDateString(invoice.contract.end_at.toLocaleString(Utils.getHighestPriorityLocale()));
            invoice.contract_end_date = invoice.contract.end_at_string;
          }
        });
        console.log(this.invoices);
      },
      error => {
        console.log(error);
      }
    );
  }

  public onClickSendDemand(event: any): void {
    this.sent_invoice_codes = [];
    this.sent_success_invoice_codes = [];
    this.sent_fail_invoice_codes = [];
    const invoice_code_visibles = Array.from(this._el.querySelectorAll('td.mat-column-invoice_code a.invoice_code'));

    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '送信開始の確認',
        message: event.title + '督促メールの送信を開始します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.invoices.forEach(invoice => {
            // const found_visible = invoice_code_visibles.filter( invoice_code => {
            //   return invoice_code.textContent === invoice.invoice_code;
            // });
            // if (found_visible.length > 0) {
            if (Utils.isValue(invoice.is_selected) && invoice.is_selected === true) {
              this.sent_invoice_codes.push(invoice.invoice_code);
              const param = {
                demand_level: parseInt(this.selecting_demand_level.key, 10),
                invoice_id: invoice.id,
              };
              this.auth_service.apiSendDemandLetter(param).subscribe(
                response => {
                  this.sent_success_invoice_codes.push(invoice.invoice_code);
                  invoice.demand_status = param.demand_level;
                  console.log('sent= ' + this.sent_invoice_codes.length + ': success= ' + this.sent_success_invoice_codes.length + ': fail=' + this.sent_fail_invoice_codes.length);
                },
                error => {
                  this.sent_fail_invoice_codes.push(invoice.invoice_code);
                  console.log('sent= ' + this.sent_invoice_codes.length + ': success= ' + this.sent_success_invoice_codes.length + ': fail=' + this.sent_fail_invoice_codes.length);
                }
              );
            }
            // }
          });
        }
      }
    });
  }

  private charge_recursive(current_invoice_index: number): void {
    if (current_invoice_index <= this.invoices.length) {
      let current_invoice = this.invoices[current_invoice_index];
      if (current_invoice && current_invoice.is_selected) {
        console.log("current_invoice : ", current_invoice);
        this.auth_service.apiChargeUnpaidInvoice({invoice_id: current_invoice.id}).subscribe(
          response => {
            current_invoice.pay_status = response.pay_status;
            console.log(current_invoice.invoice_code + " PAY SUCCESS!");
            this.charge_recursive(current_invoice_index + 1);
          },
          error => {
            console.log(current_invoice.invoice_code + " PAY FAILED!");
            this.charge_recursive(current_invoice_index + 1);
          }
        )
      }
      else {
        this.charge_recursive(current_invoice_index + 1);
      }
    };
  }

  public onClickCharge(event: any): void {
    this.sent_invoice_codes = [];
    this.sent_success_invoice_codes = [];
    this.sent_fail_invoice_codes = [];
    this.charge_recursive(0);
  }

  public onFilterKeyUp(filterValue: string) {
    this.filter_text = filterValue;
    if (Utils.isValue(this.data_source)) {
      this.data_source.filter = filterValue.trim().toLowerCase();
    }
    this.updateDemandSendButtonDisabled();
  }

  public onChangePayStatus(event: any): void {
    console.log('ListChargesComponent::onChangePayStatus : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoicePayStatus({ invoice_id: event.invoice.id, new_status: event.new_status }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }

  public onSendAllCheckChanged(event: any): void {
    console.log('ListChargesComponent::onSendAllCheckChanged : checked=' + this.select_send_all);
    if (Utils.isValue(this.invoices)) {
      this.invoices.forEach(invoice => {
        invoice.is_selected = this.select_send_all;
      });
    }
  }

  public onSendCheckChanged(event: any): void {
    console.log('ListChargesComponent::onSendCheckChanged : event=');
    console.log(event);

    // update all select checkbox checkstatus and indeterminate
    let checked_count = 0;
    let unchecked_count = 0;
    this.select_send_all_disabled = true;
    this.invoices.forEach(invoice => {
      this.select_send_all_disabled = false;
      if (invoice.is_selected === true) {
        checked_count++;
      } else {
        unchecked_count++;
      }
    });
    if (checked_count <= 0 && unchecked_count <= 0) {
      this.select_send_all = false;
      this.select_send_all_indeterminate = true;
    } else if (checked_count > 0 && unchecked_count > 0) {
      this.select_send_all = true;
      this.select_send_all_indeterminate = true;
    } else if (checked_count <= 0 && unchecked_count > 0) {
      this.select_send_all = false;
      this.select_send_all_indeterminate = false;
    } else if (checked_count > 0 && unchecked_count <= 0) {
      this.select_send_all = true;
      this.select_send_all_indeterminate = false;
    } else {
      this.select_send_all = false;
      this.select_send_all_indeterminate = false;
    }
  }

  public yen(num: number): string {
    return Utils.yen(num);
  }

  public datePart(datestring: string): string {
    return Utils.getDatePart(datestring, false);
  }

  private productString(product: ChargeProductItem): string {
    let product_string = product.title;
    if (Utils.isValue(product.device_code_1) && Utils.isValue(product.device_code_1.value)) {
      if (product.device_code_1.value.toLowerCase() !== 'undefined') {
        product_string = product_string + ' [' + product.device_code_1.value + ']';
      }
    }
    return product_string;
  }

  public productsString(products: Array<ChargeProductItem>): string {
    const product_titles = new Array<string>();
    products.forEach(product => {
      product_titles.push(this.productString(product));
    });
    // console.log('ListChargesComponent::productsString : products=' + JSON.stringify(product_titles));
    return Utils.joinStringsToBRLines(product_titles);
  }

  private updateDemandSendButtonDisabled() {
    this.demand_send_button_disabled = true;
    const demand_button = this._el.querySelector('#demand_send_button');
    if (!Utils.isValue(this.unpaid_only) || !Utils.isValue(this.invoice_month) || !Utils.isValue(this.selecting_pay_method)) {
      return;
    }
    if (!Utils.isValue(this.selecting_demand_level)) {
      return;
    }
    if (this.filter_text.length > 0) {
      return;
    }
    this.demand_send_button_disabled = false;
  }
}
