import { Injectable } from '@angular/core';
import { ProductType } from '../../models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListProductTypesService {
  private _refresh = new Subject<boolean>();
  public refresh$ = this._refresh.asObservable();
  private _current_item = new Subject<ProductType>();
  public current_item$ = this._current_item.asObservable();

  public set refresh(refresh: boolean) {
    this._refresh.next(refresh);
  }

  public set current_item(item: ProductType) {
    this._current_item.next(item);
  }

  constructor() {}
}
