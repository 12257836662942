import { Component, ViewChild, OnInit, Input } from '@angular/core';
import {
  MatSort,
  MatTableDataSource,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { Utils } from '../../../helpers';
import { AuthService } from '../../../services/auth';
import { environment } from 'src/environments/environment';

export class ListItemContractPayHistory {
  public user_id: number;
  public contract_id: number;
  public contract_code: string;
  public invoice_month: string;
  public invoice_id: number;
  public invoice_code: string;
  public sub_total: number;
  public sub_total_tax: number;
  public sub_total_include_tax: number;
  public mail_send_status: number;
  public pay_sub_total: number;
  public pay_sub_total_tax: number;
  public pay_total_include_tax: number;
  public pay_status: number;
  public pay_date: string;
  public pay_error: string;
  public pay_method: string;
}

@Component({
  selector: 'app-list-contract-pay-histories',
  templateUrl: './list-contract-pay-histories.component.html',
  styleUrls: ['./list-contract-pay-histories.component.scss']
})
export class ListContractPayHistoriesComponent implements OnInit {

  public root_for_pdf: string;
  public contract_id: number;
  public unpaid_count: number;
  public pay_histories: Array<ListItemContractPayHistory>;
  public display_columns: Array<string>;
  public data_source: MatTableDataSource<ListItemContractPayHistory>;

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
  ) {
    this.display_columns = [
      // 'invoice_month',
      'invoice_code',
      'pdf',
      'mail_send_status',
      'sub_total_include_tax',
      'pay_total_include_tax',
      'pay_date',
      'pay_error',
      'pay_status',
      'pay_method',
    ];
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.root_for_pdf = environment.apiRoot;
    this.contract_id = Number.parseInt(Utils.getUrlParameter('contract_id'));
    this.refresh();
  }

  public refresh() {
    const param = {
      contract_id: this.contract_id
    };
    this.auth_service.apiContractPayHistory(param).subscribe(
      response => {
        // console.log(response);
        if (Utils.isValue(response) && Utils.isValue(response.data) && (response.data instanceof Array)) {
          this.unpaid_count = 0;
          response.data.forEach(element => {
            element.sub_total_include_tax = element.sub_total + element.sub_total_tax;
            if (element.pay_status === 0) {
              this.unpaid_count++;
            } else {
              element.pay_total_include_tax = element.pay_sub_total + element.pay_sub_total_tax;
            }
            // console.log(element);
          });
          this.data_source = new MatTableDataSource(response.data);
          this.data_source.sort = this.sort;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  public onChangeMailSendStatus(event: any): void {
    console.log('ListInvoicesComponent::onChangeMailSendStatus : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoiceSendStatus({invoice_id: event.invoice.id, new_status: event.new_status}).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }

  public onChangePayStatus(event: any): void {
    console.log('ListInvoicesComponent::onChangePayStatus : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoicePayStatus({invoice_id: event.invoice.id, new_status: event.new_status}).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }

  public onChangePayMethod(event: any): void {
    console.log('ListInvoicesComponent::onChangePayMethod : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoicePayMethod({invoice_id: event.invoice.id, new_method: event.new_method}).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }

  public yen(num: number): string {
    return Utils.yen(num);
  }

  public getDisplayDateString(offsetZero: string): string {
    return Utils.getDisplayDateString(offsetZero);
  }
}
