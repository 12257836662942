import { Component, ElementRef, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AuthService } from '../../../services/auth';
import { Url } from '../../../constants/url';
import { FlashMessage, AuthGetReply } from '../../../models';
import { Utils } from '../../../helpers';

export class InvoicePointItem {
  title: string;
  contract_code: string;
  contract_start_at: string;
  email: string;
  system_user_id: number;
  woo_user_id: number;
  point: number;
  point_start_at: string;
  point_end_at: string;
}

@Component({
  selector: 'app-file-export',
  templateUrl: './file-export.component.html',
  styleUrls: ['./file-export.component.scss']
})
export class FileExportComponent implements OnInit {
  private _el: HTMLElement;

  public invoice_month: string;
  public pay_method: string;
  public request_params: any;
  public contract_aggregation_mode: string;
  public contract_aggregation_disabled: boolean;
  public contract_aggregation_from_date: Date;
  public contract_aggregation_to_date: Date;
  public generate_disabled: boolean;
  public sagawa_disabled: boolean;
  public sagawa_from_date: Date;
  public sagawa_to_date: Date;
  public ehiden3_disabled: boolean;
  public ehiden3_from_date: Date;
  public ehiden3_to_date: Date;
  public airport_disabled: boolean;
  public airport_from_date: Date;
  public airport_to_date: Date;
  private blob_target_id: string;
  public esim_qr_publish_count: string;

  constructor(
    private el: ElementRef,
    private http: HttpClient,
    protected auth_service: AuthService,
    public snackBar: MatSnackBar
  ) {
    this._el = el.nativeElement;
    this.generate_disabled = true;
    this.contract_aggregation_disabled = false;
    this.contract_aggregation_mode = 'start_at';  // or 'Created at'
    this.sagawa_disabled = true;
    this.airport_disabled = true;
    this.esim_qr_publish_count = "0";
  }

  ngOnInit() { }

  public onChargeMonthChanged(month: string) {
    console.log(
      'GenerateInvoiceConfirmDialogComponent::onClickGenerate - start : ' +
      month
    );
    this.invoice_month = month;
    if (Utils.isValue(this.invoice_month) && this.invoice_month.length > 0) {
      this.generate_disabled = false;
      console.log(this._el.querySelectorAll('button.generate.disable'));
      const buttons = Array.prototype.slice.call(
        this._el.querySelectorAll('button.generate.disable')
      );
      buttons.forEach(button => {
        console.log(button);
      });
    } else {
      this.generate_disabled = true;
      const buttons = Array.prototype.slice.call(
        this._el.querySelectorAll('button.generate.enable')
      );
      buttons.forEach(button => {
        console.log(button);
      });
    }
  }

  private generateRecordForCreditCard(record: any): string {
    console.log('FileExportComponent::generateRecordForCreditCard ');
    const prev_invoice_month = Utils.monthStringToDate(record.invoice_month, -1, -99);
    const cur_invoice_month = Utils.monthStringToDate(record.invoice_month, 0, -99);
    console.log(prev_invoice_month);
    console.log(cur_invoice_month);
    const prev_invoice_month_utc = Utils.getUTCElements(prev_invoice_month);
    const cur_invoice_month_utc = Utils.getUTCElements(cur_invoice_month);
    const cur_invoice_month_final_day = Utils.monthStringToDate(record.invoice_month, 0, 99);
    const csv_record =
      // 9100176413941固定
      '"9100176413941",' +
      // BookingID-月年V
      '"' +
      record.contract_code + '-' + cur_invoice_month_utc.month.toUpperCase() + '-' + cur_invoice_month_utc.year.substr(-2) +
      '",' +
      // CAPTURE固定
      '"CAPTURE",' +
      // 空セル
      '"",' +
      // 月額料金
      '"' +
      record.sub_total +
      '",' +
      // 税
      '"' +
      record.sub_total_tax +
      '",' +
      // 1固定
      '"1",' +
      // 空セル
      '"",' +
      // 空セル
      '"",' +
      // 会員ID
      '"' +
      record.user_e_mail +
      '",' +
      // 空セル
      '"",' +
      // 空セル
      '"",' +
      // Serviced年-使用月 : FIXME
      '"Serviced' +
      prev_invoice_month.getFullYear().toString() + '-' +
      (prev_invoice_month.getMonth() + 1).toString().padStart(2, '0') +
      '",' +
      // Payment年-0731 : FIXME
      '"Payment' +
      cur_invoice_month_final_day.getFullYear().toString() + '-' +
      (cur_invoice_month_final_day.getMonth() + 1).toString().padStart(2, '0') +
      cur_invoice_month_final_day.getDate().toString().padStart(2, '0') +
      '",' +
      // プラン(invoice sender key)
      '"' +
      '' + // record.invoice_sender_key +
      // '",' +
      '","' +
      '","' +
      '","' +
      '","' +
      '","' +
      '","' +
      '","' +
      '","' +
      '"\r\n';
    console.log('generated : ' + csv_record);
    return csv_record;
  }

  private generateRecordForPonta(record: any): string {
    console.log('FileExportComponent::generateRecordForPonta ');
    return this.generateRecordForCreditCard(record); // same format as credit card
  }

  private generateRecordForSmartpit(record: any): string {
    console.log('FileExportComponent::generateRecordForSmartpit ');
    const prev_invoice_month = Utils.monthStringToDate(record.invoice_month, -1, -99);
    const cur_invoice_month = Utils.monthStringToDate(record.invoice_month, 0, -99);
    const prev_invoice_month_utc = Utils.getUTCElements(prev_invoice_month);
    const cur_invoice_month_utc = Utils.getUTCElements(cur_invoice_month);
    const next_first_pay_day = Utils.monthStringToDate(record.invoice_month, 1, -99);
    next_first_pay_day.setDate(10);
    const next_second_pay_day = Utils.monthStringToDate(record.invoice_month, 1, -99);
    next_second_pay_day.setDate(25);
    console.log(record);
    const csv_record =
      // 30固定
      '"30",' +
      // 00固定
      '"00",' +
      // 空セル
      '"",' +
      // 空セル
      '"",' +
      // 1271400000固定
      '"1271400000",' +
      // スマピ番号(13桁)
      '"' +
      record.smartpit_number +
      '",' +
      // 空セル
      '"",' +
      // 空セル
      '"",' +
      // BookingID-月年V
      '"' +
      record.contract_code + '-' + cur_invoice_month_utc.month.toUpperCase() + cur_invoice_month_utc.year.substr(-2) +
      '",' +
      // BookingID-月年V
      '"' +
      record.contract_code + '-' + cur_invoice_month_utc.month.toUpperCase() + cur_invoice_month_utc.year.substr(-2) +
      '",' +
      // 月額料金
      '"' +
      record.sub_total +
      '",' +
      // 税 : 消費税 : V.A.T : TAX
      '"' +
      record.sub_total_tax +
      '",' +
      // 支払い第一締切日(翌月10日)
      '"' +
      next_first_pay_day.getFullYear().toString() + (next_first_pay_day.getMonth() + 1).toString().padStart(2, '0') + next_first_pay_day.getDate().toString().padStart(2, '0') +
      '",' +
      // 1固定
      '"1",' +
      // 月額料金+500円
      '"' +
      (parseInt(record.sub_total, 10) + 500).toString() +
      '",' +
      // 税(+500円の税) : 消費税 : V.A.T : TAX (500 * 10%)
      '"' +
      (parseInt(record.sub_total_tax, 10) + 50).toString() +
      '",' +
      // 支払い第二締切日(翌月25日)
      '"' +
      next_second_pay_day.getFullYear().toString() + (next_second_pay_day.getMonth() + 1).toString().padStart(2, '0') + next_second_pay_day.getDate().toString().padStart(2, '0') +
      '",' +
      // プラン(invoice sender key)
      '"' +
      '' + // record.invoice_sender_key +
      // '",' +
      '","","","","",""\r\n';
    console.log('generated : ' + csv_record);
    return csv_record;
  }

  private generateRecordForGIBill(record: any): string {
    console.log('FileExportComponent::generateRecordForGIBill ');
    const prev_invoice_month = Utils.monthStringToDate(record.invoice_month, -1, -99);
    const cur_invoice_month = Utils.monthStringToDate(record.invoice_month, 0, -99);
    const prev_invoice_month_utc = Utils.getUTCElements(prev_invoice_month);
    const cur_invoice_month_utc = Utils.getUTCElements(cur_invoice_month);
    const next_first_pay_day = Utils.monthStringToDate(record.invoice_month, 1, -99);
    next_first_pay_day.setDate(10);
    const next_second_pay_day = Utils.monthStringToDate(record.invoice_month, 1, -99);
    next_second_pay_day.setDate(25);
    console.log(record);
    const csv_record =
      // 30固定
      '"30",' +
      // 00固定
      '"00",' +
      // 空セル
      '"",' +
      // 空セル
      '"",' +
      // 1271400000固定
      '"1271400000",' +
      // スマピ番号(13桁) // TODO : FIXME
      '"' +
      record.smartpit_number +
      '",' +
      // 空セル
      '"",' +
      // 空セル
      '"",' +
      // BookingID-月年V
      '"' +
      record.contract_code + '-' + cur_invoice_month_utc.month.toUpperCase() + cur_invoice_month_utc.year.substr(-2) +
      '",' +
      // BookingID-月年V
      '"' +
      record.contract_code + '-' + cur_invoice_month_utc.month.toUpperCase() + cur_invoice_month_utc.year.substr(-2) +
      '",' +
      // 月額料金
      '"' +
      record.sub_total +
      '",' +
      // 税 : 消費税 : V.A.T : TAX
      '"' +
      record.sub_total_tax +
      '",' +
      // 支払い第一締切日(翌月10日)
      '"' +
      next_first_pay_day.getFullYear().toString() + (next_first_pay_day.getMonth() + 1).toString().padStart(2, '0') + next_first_pay_day.getDate().toString().padStart(2, '0') +
      '",' +
      // 1固定
      '"1",' +
      // 月額料金+500円
      '"' +
      (parseInt(record.sub_total, 10) + 500).toString() +
      '",' +
      // 税(+500円の税) : 消費税 : V.A.T : TAX
      '"' +
      (parseInt(record.sub_total_tax, 10) + 40).toString() +
      '",' +
      // 支払い第二締切日(翌月25日)
      '"' +
      next_second_pay_day.getFullYear().toString() + (next_second_pay_day.getMonth() + 1).toString().padStart(2, '0') + next_second_pay_day.getDate().toString().padStart(2, '0') +
      '",' +
      // プラン(invoice sender key)
      '"' +
      '' + // record.invoice_sender_key +
      // '",' +
      '","","","","",""\r\n';
    console.log('generated : ' + csv_record);
    return csv_record;
  }

  private iso8601DateTimeToDate(dt_string: string): string {
    if (Utils.isValue(dt_string) && dt_string.length > 0) {
      return dt_string.split('T')[0];
    }
    return '';
  }

  private generateAggregationRecord(record: any): string {
    console.log('FileExportComponent::generateAggregationRecord ');
    console.log(record);
    let csv_record = '';
    record.forEach(v => {
      csv_record += '"' + v + '",';
    });
    csv_record += '\r\n';
    console.log('generated : ' + csv_record);
    return csv_record;
  }

  public onGenerateStarted(reply: AuthGetReply, context: any) {
    console.log(context.request_params);
    context.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 10000;
    cfg.panelClass = ['notify_snackbar', 'information'];
    context.snackBar.open(
      reply.message.message,
      'OK',
      cfg
    );
  }

  public onDownloadVoiceCredit(event): void {
    console.log('FileExportComponent::onDownloadVoiceCredit');
    this.blob_target_id = 'voice_credit';
    this.request_params = {
      invoice_month: this.invoice_month,
      pay_method: 'CREDIT CARD'
    };
    this.auth_service.apiRequestChargeInvoiceList(
      this.request_params,
      this,
      this.onGenerateStarted
    );
  }

  public onDownloadVoiceSmartpit(event): void {
    console.log('FileExportComponent::onDownloadVoiceCredit');
    this.blob_target_id = 'voice_smartpit';
    this.request_params = {
      invoice_month: this.invoice_month,
      pay_method: 'SMARTPIT'
    };
    this.auth_service.apiRequestChargeInvoiceList(
      this.request_params,
      this,
      this.onGenerateStarted
    );
  }

  public onDownloadVoicePonta(event): void {
    console.log('FileExportComponent::onDownloadVoiceCredit');
    this.blob_target_id = 'voice_ponta';
    this.request_params = {
      invoice_month: this.invoice_month,
      pay_method: 'PONTA'
    };
    this.auth_service.apiRequestChargeInvoiceList(
      this.request_params,
      this,
      this.onGenerateStarted
    );
  }

  public onDownloadInvoicePointsReply(reply: AuthGetReply, context: any) {
    console.log(context.request_params);
    console.log(reply);
    let utf8_string = `"user_id","point","description","date_earning"\r\n`;
    reply.data.records.forEach(item => {
      if (item.woo_user_id !== null) {
        console.log('item=',item);
        utf8_string += `"${item.woo_user_id}","${Math.ceil(item.point)}","${item.title}","${context.dateExpressionByString(item.point_start_at)}"\r\n`;
      }
    });
    const el_download = document.querySelector('#' + context.blob_target_id);
    const file = new Blob([utf8_string], {type: 'text/csv;charset=utf-8'});
    const a = document.createElement('a');
    a.download = `invoice_point_list_${context.request_params.invoice_month}.csv`
    a.href = window.URL.createObjectURL(file);
    a.click();
  }

  public onDownloadInvoicePoints(event): void {
    console.log('FileExportComponent::onDownloadInvoicePoints');
    this.blob_target_id = 'woo_issued_point';
    this.request_params = {
      invoice_month: this.invoice_month
    };
    this.auth_service.apiRequestInvoicePointsList(
      this.request_params,
      this,
      this.onDownloadInvoicePointsReply
    );
  }

  public onDownloadReferralPointsReply(reply: AuthGetReply, context: any) {
    console.log(context.request_params);
    console.log(reply);
    let utf8_string = `"user_id","point","description","date_earning"\r\n`;
    reply.data.records.forEach(item => {
      if (item.woo_user_id !== null) {
        console.log('item=',item);
        utf8_string += `"${item.woo_user_id}","${Math.ceil(item.point)}","${item.title}","${context.dateExpressionByString(item.point_start_at)}"\r\n`;
      }
    });
    const el_download = document.querySelector('#' + context.blob_target_id);
    const file = new Blob([utf8_string], {type: 'text/csv;charset=utf-8'});
    const a = document.createElement('a');
    a.download = `referral_point_list_${context.request_params.invoice_month}.csv`
    a.href = window.URL.createObjectURL(file);
    a.click();
  }

  public onDownloadReferralPoints(event): void {
    console.log('FileExportComponent::onDownloadReferralPoints');
    this.blob_target_id = 'woo_issued_point';
    this.request_params = {
      invoice_month: this.invoice_month
    };
    this.auth_service.apiRequestReferralPointsList(
      this.request_params,
      this,
      this.onDownloadReferralPointsReply
    );
  }

  public onMonthlyActionReply(reply: AuthGetReply, context: any) {
    console.log(context.request_params);
    context.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 3000;
    cfg.panelClass = ['notify_snackbar', 'information'];
    context.snackBar.open(
      'An email sent to you . Please check the attachment.',
      'OK',
      cfg
    );
  }

  public onClickMonthlyAction(event_params): void {
    console.log('FileExportComponent::onClickMonthlyAction');
    this.auth_service.apiRequestMonthlyList(
      event_params,
      this,
      this.onMonthlyActionReply
    );
  }

  public onEsimQrPublishCountChanged(event: any): void {
    this.esim_qr_publish_count = event.target.value;
    console.log('FileExportComponent::onEsimQrPublishCountChanged', this.esim_qr_publish_count);
  }

  public onClickEsimQrPublish(): void {
    this.auth_service.apiPostPublishEsimLink(
      {
        count: parseInt(this.esim_qr_publish_count)
      }
    ).subscribe(
      response => {
        console.log(`apiPostPublishEsimLink success: `, response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 3000;
        cfg.panelClass = ['notify_snackbar', 'information'];
        this.snackBar.open(response.message, 'OK', cfg);
      },
      error => {
        console.log(`apiPostPublishEsimLink error: `, error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 3000;
        cfg.panelClass = ['notify_snackbar', 'information'];
        this.snackBar.open(error.message, 'OK', cfg);
      }
    );
  }

  public onDownloadVoiceGIBill(event): void {
    console.log('FileExportComponent::onDownloadVoiceGIBill');
    alert('not implemented');
  }

  public onDownloadContractAggregationData(event): void {
    console.log('FileExportComponent::onDownloadContractAggregationData');
    this.blob_target_id = 'contract_aggregation';
    this.request_params = {
      mode: this.contract_aggregation_mode,
      from_date: this.contract_aggregation_from_date,
      to_date: this.contract_aggregation_to_date
    };
    this.auth_service.apiAggregationDataOfContract(this.request_params).subscribe(
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );
  }

  private isSagawaLabelDownloadEnable(): boolean {
    console.log('FileExportComponent::isSagawaLabelDownloadEnable');
    if (Utils.isValue(this.sagawa_from_date) &&
      Utils.isValue(this.sagawa_to_date)) {
      this.sagawa_disabled = false;
      return true;
    }
    this.sagawa_disabled = true;
    return false;
  }

  private isEHiden3LabelDownloadEnable(): boolean {
    console.log('FileExportComponent::isEHiden3LabelDownloadEnable');
    if (Utils.isValue(this.ehiden3_from_date) &&
      Utils.isValue(this.ehiden3_to_date)) {
      this.ehiden3_disabled = false;
      return true;
    }
    this.ehiden3_disabled = true;
    return false;
  }

  private isAirportLabelDownloadEnable(): boolean {
    console.log('FileExportComponent::isAirportLabelDownloadEnable');
    if (Utils.isValue(this.airport_from_date) &&
      Utils.isValue(this.airport_to_date)) {
      this.airport_disabled = false;
      return true;
    }
    this.airport_disabled = true;
    return false;
  }

  private updateContractAggregationDownloadEnable(): void {
    console.log('FileExportComponent::updateContractAggregationDownloadEnable');
    this.contract_aggregation_disabled = false;
  }

  public nullToEmpty(value: any): any {
    if (!Utils.isValue(value)) {
      return '';
    }
    return value;
  }

  public onEHiden3LabelReply(reply: AuthGetReply, context: any) {
    console.log(context.request_params);
    console.log(reply);
    context.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 3000;
    cfg.panelClass = ['notify_snackbar', 'information'];
    context.snackBar.open(
      'Check your email',
      'OK',
      cfg
    );
  }

  public onSagawaLabelReply(reply: AuthGetReply, context: any) {
    console.log(context.request_params);
    console.log(reply);
    let utf8_string = '';
    reply.data.records.forEach(record => {

      let pay_method: string;
      if (record.payment_type === 'CREDIT CARD') {
        pay_method = 'CR';
      } else if (record.payment_type === 'SMARTPIT') {
        pay_method = 'SM';
      } else {
        pay_method = 'NA';
      }

      let delivery_time_specify = '';
      if (Utils.isValue(record.delivery_time_specify)) {
        delivery_time_specify = record.delivery_time_specify.replace(' ~ ', '-');
      }

      utf8_string +=
        '"' + context.nullToEmpty(record.plan) + ':(' + pay_method + ')"\t' +
        '"' + context.nullToEmpty(record.start_date) + '"\t' +
        '"' + context.nullToEmpty(record.end_date) + '"\t' +
        '"' + context.nullToEmpty(record.created) + '"\t' +
        '"' + context.nullToEmpty(record.user_full_name) + '"\t' +
        '"' + context.nullToEmpty(record.source) + '"\t' +
        context.nullToEmpty(record.amount) + '\t' +
        '"' + context.nullToEmpty(record.status) + '"\t' +
        '"' + context.nullToEmpty(record.tracking_code) + '"\t' +
        '"' + context.nullToEmpty(record.shipping_address_v) + '"\t' +
        '"' + context.nullToEmpty(record.shipping_address_d) + '"\t' +
        '"' + context.nullToEmpty(delivery_time_specify) + '"\t' +
        '"' + context.nullToEmpty(record.payment_type) + '"\t' +
        '"' + context.nullToEmpty(record.contact_phone) + '"\t' +
        '"' + '"\t' + // Do you want to use MNP?
        '"' + '"\t' + // Name on your contract (MNP)
        '"' + '"\t' + // Kana (Phonics)*
        '"' + '"\t' + // Email
        '"' + '"\t' + // Country
        '"' + '"\t' + // Sex
        '"' + '"\t' + // Date of birth
        '"' + '"\t' + // Discount Code
        '"' + '"\t' + // Airport pick up location
        '"' + '"\t' + // Arriving Flight number
        '"' + '"\t' + // Estimated Time of Arrival
        '"' + '"\t' + // sim_id0
        '"' + '"\t' + // sim_id1
        '"' + '"\t' + // Office pick up location
        '"' + '"\t' + // I am a JET programme applicant.
        '"' + '"\t' + // Note
        '"' + '"\t' + // Note
        '"' + context.nullToEmpty(record.booking_id) + '"\r\n';
    });

    const blob = new Blob(
      [
        utf8_string
      ],
      { type: 'text/plain;charset=utf-8' }
    );
    // window.URL = window.URL || window.webkitURL;
    const el_download = document.querySelector('#' + context.blob_target_id);
    el_download.setAttribute('href', window.URL.createObjectURL(blob));
    context.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 3000;
    cfg.panelClass = ['notify_snackbar', 'information'];
    context.snackBar.open(
      'Please right click "download" to save data as csv file.',
      'OK',
      cfg
    );
  }

  public onEHiden3FromDateChanged(event: any): void {
    console.log('FileExportComponent::onEHiden3FromDateChanged');
    console.log(event.value);
    this.ehiden3_from_date = event.value;
    this.ehiden3_disabled = !this.isEHiden3LabelDownloadEnable();
  }

  public onEHiden3ToDateChanged(event: any): void {
    console.log('FileExportComponent::onEHiden3ToDateChanged');
    console.log(event.value);
    this.ehiden3_to_date = event.value;
    this.ehiden3_disabled = !this.isEHiden3LabelDownloadEnable();
  }

  public onGenerateEHiden3LabelData(event: any): void {
    console.log('FileExportComponent::onGenerateEHiden3LabelData');
    this.request_params = {
      from_date: this.ehiden3_from_date,
      to_date: this.ehiden3_to_date
    };
    this.auth_service.apiEHiden3LabelData(
      this.request_params,
      this,
      this.onEHiden3LabelReply
    );
  }

  public onSagawaFromDateChanged(event: any): void {
    console.log('FileExportComponent::onSagawaFromDateChanged');
    console.log(event.value);
    this.sagawa_from_date = event.value;
    this.sagawa_disabled = !this.isSagawaLabelDownloadEnable();
  }

  public onSagawaToDateChanged(event: any): void {
    console.log('FileExportComponent::onSagawaToDateChanged');
    console.log(event.value);
    this.sagawa_to_date = event.value;
    this.sagawa_disabled = !this.isSagawaLabelDownloadEnable();
  }

  public onDownloadSagawaLabelData(event: any): void {
    console.log('FileExportComponent::onDownloadSagawaLabelData');
    this.blob_target_id = 'sagawa_label_data';
    this.request_params = {
      from_date: this.sagawa_from_date,
      to_date: this.sagawa_to_date
    };
    this.auth_service.apiSagawaLabelData(
      this.request_params,
      this,
      this.onSagawaLabelReply
    );
  }

  public generateAirportLabelRecord(record) {
    const ESTIMATED_TIME_OF_ARRIVALS = [
      { candidate: '-6:00 A.M.',        resolved: '-6:00'},
      { candidate: '6:00-7:00 A.M.',    resolved: '6:00-7:00'},
      { candidate: '7:00-8:00 A.M.',    resolved: '7:00-8:00'},
      { candidate: '8:00-8:00 A.M.',    resolved: '8:00-9:00'},
      { candidate: '9:00-10:00 A.M.',   resolved: '9:00-10:00'},
      { candidate: '10:00-11:00 A.M.',  resolved: '10:00-11:00'},
      { candidate: '11:00-12:00 A.M.',  resolved: '11:00-12:00'},
      { candidate: '12:00-1:00 P.M.',   resolved: '12:00-13:00'},
      { candidate: '1:00-2:00 P.M.',    resolved: '13:00-14:00'},
      { candidate: '2:00-3:00 P.M.',    resolved: '14:00-15:00'},
      { candidate: '3:00-4:00 P.M.',    resolved: '15:00-16:00'},
      { candidate: '4:00-5:00 P.M.',    resolved: '16:00-17:00'},
      { candidate: '5:00-6:00 P.M.',    resolved: '17:00-18:00'},
      { candidate: '6:00-7:00 P.M.',    resolved: '18:00-19:00'},
      { candidate: '7:00-8:00 P.M.',    resolved: '19:00-20:00'},
      { candidate: '8:00-9:00 P.M.',    resolved: '20:00-21:00'},
      { candidate: '9:00-10:00 P.M.',   resolved: '21:00-22:00'},
      { candidate: '10:00-11:00 P.M.',  resolved: '22:00-23:00'},
      { candidate: '11:00-0:00 A.M.',   resolved: '23:00-24:00'},
      { candidate: '',   resolved: ''}
    ];

    console.log('FileExportComponent::generateAirportLabelRecord');
    const eta_src = record['estimated_time_of_arrival'].toUpperCase().replace(' ~ ', '-');
    const eta_dests = ESTIMATED_TIME_OF_ARRIVALS.filter(c => {
      return c.candidate === eta_src;
    });
    let eta = '';
    if (eta_dests.length > 0) {
      eta = eta_dests[0].resolved;
    }
    let utf8_string = '';
    console.log(record['pick_up_method']);
    if (record['order_source'].toUpperCase() === 'LONG TERM') {
      utf8_string += record['contract_subscription_plan'];
      if (record['pick_up_method'].toLowerCase() === 'airport') {
        utf8_string += '【LONG Airport Pick up】\t';
      } else if (record['pick_up_method'].toLowerCase() === 'delivery') {
        utf8_string += '【LONG Delivery】\t';
      } else if (record['pick_up_method'].toLowerCase() === 'office_pick_up') {
        utf8_string += '【LONG Office Pick up】\t';
      } else {
        utf8_string += '【LONG Other Pick up】\t';
      }
      utf8_string += record['contract_start_at'] + '\t\t\t';
      utf8_string += record['user_full_name'] + '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t';
      utf8_string += record['recipient'] + '\t\t\t\t\t';
      utf8_string += eta + '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t';
      utf8_string += record['contract_code'] + '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t';
      utf8_string += record['airport_pick_up_location'] + '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t';
      utf8_string += record['arriving_flight_number'] + '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t';
      utf8_string += '\r\n';
    }
    return utf8_string;
  }

  public onAirportFromDateChanged(event: any): void {
    console.log('FileExportComponent::onAirportFromDateChanged');
    this.airport_from_date = event.value;
    this.airport_disabled = !this.isAirportLabelDownloadEnable();
  }

  public onAirportToDateChanged(event: any): void {
    console.log('FileExportComponent::onAirportToDateChanged');
    this.airport_to_date = event.value;
    this.airport_disabled = !this.isAirportLabelDownloadEnable();
  }

  public onDownloadAirportLabelData(event: any): void {
    console.log('FileExportComponent::onDownloadAirportLabelData');
    this.blob_target_id = 'airport_label_data';
    this.request_params = {
      from_date: this.airport_from_date,
      to_date: this.airport_to_date
    };
    this.auth_service.apiAirportLabelData(this.request_params).subscribe(
      response => {
        console.log(response);
        let utf8_string = '';
        response.data.records.forEach(record => {
          utf8_string += this.generateAirportLabelRecord(record);
        });
        const blob = new Blob(
          [
            utf8_string
          ],
          { type: 'text/plain;charset=utf-8' }
        );
        const el_download = document.querySelector('#' + this.blob_target_id);
        el_download.setAttribute('href', window.URL.createObjectURL(blob));
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 3000;
        cfg.panelClass = ['notify_snackbar', 'information'];
        this.snackBar.open(
          'Please right click "download" to save data as csv file.',
          'OK',
          cfg
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  public onContractAggregationModeChanged(event: any): void {
    console.log('FileExportComponent::onContractAggregationModeChanged');
    this.contract_aggregation_mode = event;
  }

  public onContractAggregationFromDateChanged(event: any): void {
    console.log('FileExportComponent::onContractAggregationFromDateChanged');
    this.contract_aggregation_from_date = event.value;
    this.updateContractAggregationDownloadEnable();
  }

  public onContractAggregationToDateChanged(event: any): void {
    console.log('FileExportComponent::onContractAggregationToDateChanged');
    this.contract_aggregation_to_date = event.value;
    this.updateContractAggregationDownloadEnable();
  }

  public dateExpression(dt: Date): string {
    if (dt === null) {
      return "";
    }
    return `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`;
  }
  
  public dateExpressionByString(dt_str: string): string {
    const dt = new Date(dt_str);
    return `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`;
  }
  
  public paidExpression(paid_value: boolean): string {
    return paid_value ? "Paid" : "Unpaid";
  }
}
