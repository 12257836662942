import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth';
import { VisibleService, FlashMessageService } from '../../../services/misc';
import { Visible } from '../../../constants';
import { EmailErrorStateMatcher } from '../../../helpers/email-error-state-matcher';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class LoginComponent implements OnInit {
  // 入力内容
  public input_user_id: string;
  public input_password: string;
  public button_login_disabled: boolean;

  // サービス読み取り
  private _is_logging_in: boolean;
  private _subscription_is_logging_in: Subscription;

  public email_form_control: FormControl;
  public email_error_matcher: EmailErrorStateMatcher;

  private _invisible: boolean;
  private _subscription_invisible: Subscription;

  constructor(
    private auth_service: AuthService,
    private visible_service: VisibleService,
  ) {
    this.email_form_control = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
    this.email_error_matcher = new EmailErrorStateMatcher();
    this.input_password = '';
    this.button_login_disabled = true;
  }

  ngOnInit() {
    document.title = 'Sakura mobile';
    this._subscription_is_logging_in = this.auth_service.logging_in$.subscribe(
      logging_in => {
        console.log('LoginComponent::_subscription_is_logging_in : logging_in=' + logging_in);
        if (logging_in === true) {
          console.log('LoginComponent::_subscription_is_logging_in (success to login): logging_in=' + logging_in);
          location.href = '/operation'; // TODO : check user role and redirect url
        } else {
          console.log('LoginComponent::_subscription_is_logging_in (failed to login): logging_in=' + logging_in);
        }
      }
    );

    this._subscription_invisible = this.visible_service.login$.subscribe(
      invisible => {
        console.log('LoginComponent::_subscription_invisible : invisible=' + invisible);
        const el = document.querySelector(
          'div.app-login'
        );
        if (this._invisible === Visible.HIDDEN || this._invisible === undefined) {
          el.classList.remove('invisible');
          if (invisible === Visible.HIDDEN) {
            el.classList.add('invisible');
            // invisible => invisible (nothing to do)
          } else {
            // invisible to visible => TODO : initialize display data
          }
        } else {
          if (invisible === Visible.HIDDEN) {
            el.classList.add('invisible');
            // visible => invisible => TODO : clear data
          } else {
            // visible => visible (nothing to do)
          }
        }
        this._invisible = invisible;
      }
    );
    // initialize login status here!
    this.auth_service.initialize();
  }

  private enableSubmit() {
    this.button_login_disabled = false;
    if (this.email_form_control.hasError('email')) {
      this.button_login_disabled = true;
      return;
    }
    if (this.email_form_control.hasError('required')) {
      this.button_login_disabled = true;
      return;
    }
    if (this.input_password.length <= 0) {
      this.button_login_disabled = true;
      return;
    }
  }

  public onEmailChanged(event) {
    this.input_user_id = this.email_form_control.value;
    this.enableSubmit();
  }

  public onPasswordChanged(event) {
    this.enableSubmit();
  }

  public onClickLogin(event) {
    this.auth_service.login(this.input_user_id, this.input_password);
  }
}
