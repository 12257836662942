import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import {
  ContractMemoListItem,
  FlashMessage,
  AuthGetReply
} from '../../../models';
import { Utils } from '../../../helpers';
import { Subscription } from 'rxjs';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import {
  AddContractMemoDialogComponent,
  AddContractMemoDialogData,
} from '../../dialog/add-contract-memo-dialog/add-contract-memo-dialog.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-list-contract-memos',
  templateUrl: './list-contract-memos.component.html',
  styleUrls: ['./list-contract-memos.component.scss']
})
export class ListContractMemosComponent implements OnInit {
  public contract_id: number;
  public memos: Array<ContractMemoListItem>;
  public display_columns: Array<string>;

  protected _el: HTMLElement;
  private _subscription_refresh: Subscription;
  public _domSanitizer: DomSanitizer;

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
  ) {
    this._el = el.nativeElement;
    this.display_columns = ['item'];
    this._domSanitizer = sanitizer;
  }

  ngOnInit() {
    this.contract_id = Number.parseInt(Utils.getUrlParameter('contract_id'));
    this.refresh();
  }

  public refresh() {
    const params = {
      contract_id: this.contract_id
    };
    this.auth_service.apiListContractMemos(params, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      // convert gmt offset 0 to local gmt offset
      const memos = <Array<ContractMemoListItem>>reply.data;
      context.memos = new Array<ContractMemoListItem>();
      memos.forEach(element => {
        context.memos.push({
          memo_id: element.memo_id,
          contract_id: element.contract_id,
          user_id: element.user_id,
          user_e_mail: element.user_e_mail,
          memo: context._domSanitizer.bypassSecurityTrustHtml(element.memo),
          created_at: Utils.getLocalTimeString(element.created_at),
        });
      });
    });
  }

  public onClickAddContractMemo(event): void {
    const dialogAddRef = this.dialog.open(AddContractMemoDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        contract_id: this.contract_id
      }
    });

    dialogAddRef.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.added === true) {
          this.refresh();
        }
      }
    });
  }

  public onClickDeleteMemo(memo: any): void {
    const params = {
      memo_id: memo.memo_id
    };
    this.auth_service.apiDeleteContractMemo(params).subscribe(
      response => {
        this.refresh();
      },
      error => {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          error.error.message.message,
          'OK',
          cfg
        );
      }
    );
  }
}
