<div class="app-list-charges">
    <table class="operation" style="width: 100%">
      <tr>
        <td style="width: 30%;">
          <button mat-icon-button class="edit_icon" (click)="onRefreshList($event)">
            <mat-icon aria-label="Refresh list">refresh</mat-icon>
          </button>
          未払いのみ表示 :
          <mat-checkbox [checked]="unpaid_only" (change)="onUnpaidOnlyCheckChanged($event)"></mat-checkbox>
        </td>
        <td style="text-align: right; align-content: right; width: 25%;">
          <table class="invoice_month" style="width: 100%; margin-left: auto;">
            <tr>
              <td class="title" style="width: 40%;">
                請求書発行月 :&nbsp;
              </td>
              <td class="date" style="width: 60%;">
                <mat-form-field class="date_pick">
                  <input matInput type="month" [(ngModel)]="invoice_month" (change)="onInvoiceMonthChanged($event.target.value)">
                  <mat-hint>
                    <div *ngIf="invoice_month_error" class="invoice_month_error">Invalid</div>
                  </mat-hint>
                </mat-form-field>
              </td>
            </tr>
          </table>
        </td>
        <td style="width: 15%;">
          <mat-form-field>
            <mat-select placeholder="支払い方法" (selectionChange)="onChangedPayMethod($event)">
              <mat-option *ngFor="let item of pay_methods" [value]="item.key">
                {{item.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td style="text-align: right;">
          <table style="margin-left: auto;">
            <tr>
              <td>
                <mat-form-field style="min-width: 16rem;">
                  <mat-select placeholder="督促状レベル" (selectionChange)="onChangedDemandLevel($event)">
                    <mat-option *ngFor="let item of demand_levels" [value]="item.key">
                      {{item.title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <button id="demand_send_button" mat-flat-button class="warn" [disabled]="demand_send_button_disabled" (click)="onClickSendDemand($event)">督促状の送信</button>
              </td>
              <td>
                <button id="charge_button" mat-flat-button class="warn" [disabled]="charge_button_disabled" (click)="onClickCharge($event)">チャージ</button>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="filter">
            <input matInput [(ngModel)]="current_filter" (keyup)="onFilterKeyUp($event.target.value)" placeholder="Filter">
          </mat-form-field>
        </td>
        <td class="charging-list-paginator" colspan="3">
          <mat-paginator class="charging-list-paginator" [pageSizeOptions]="[50, 100, 500, 1000, 5000, 10000, 50000]" showFirstLastButtons></mat-paginator>
        </td>
      </tr>
    </table>
    <div class="list-invoices-table-container mat-elevation-z8">
      <table mat-table [dataSource]="data_source" matSort>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="is_selected">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox [(ngModel)]="select_send_all" (change)="onSendAllCheckChanged($event)" (disabled)="select_send_all_disabled" [indeterminate]="select_send_all_indeterminate">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox [(ngModel)]="row.is_selected" (change)="onSendCheckChanged($event);">
            </mat-checkbox>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="contract_code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract code </th>
          <td mat-cell *matCellDef="let element">
              <a class="contract_code" href="{{'/operation/contract?contract_id=' + element.contract_id}}"
              target="_blank">{{element.contract_code}}</a>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="invoice_code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice code </th>
          <td mat-cell *matCellDef="let element">
              <a class="invoice_code" href="{{'/operation/invoice?contract_code=' + element.contract_code + '&invoice_month=' + invoice_month}}"
              target="_blank">{{element.invoice_code}}</a>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="pdf">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>PDF</th>
          <td mat-cell *matCellDef="let element">
              <a class="pdf" href="{{root_for_pdf + '/invoices/show/' + element.secure_random}}" target="_blank">show</a>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="mail_send_status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 請求書 </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.mail_send_status === 0">
              <select name="send_status_select" (change)="onChangeMailSendStatus({invoice: element, new_status: $event.target.value})">
                <option value="NOT_YET" selected>未送信</option>
                <option value="SENT">送信済</option>
              </select>
            </div>
            <div *ngIf="element.mail_send_status !== 0">
              <select name="send_status_select" (change)="onChangeMailSendStatus({invoice: element, new_status: $event.target.value})">
                <option value="NOT_YET">未送信</option>
                <option value="SENT" selected>送信済</option>
              </select>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="products">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 契約商品 </th>
          <td mat-cell *matCellDef="let element" [innerHTML]="productsString(element.products)"></td>
        </ng-container>
  
        <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ユーザー名 </th>
          <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
        </ng-container>
  
        <ng-container matColumnDef="contract_end_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 契約終了日 </th>
          <td mat-cell *matCellDef="let element"> {{element.contract_end_date}} </td>
        </ng-container>
  
        <ng-container matColumnDef="pay_method">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 支払方法 </th>
          <td mat-cell *matCellDef="let element"> {{element.pay_method}} </td>
        </ng-container>
  
        <ng-container matColumnDef="pay_status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 支払状況 </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.pay_status === 0">
              <select name="send_status_select" (change)="onChangePayStatus({invoice: element, new_status: $event.target.value})">
                  <option value="NOT_YET" selected>未払い</option>
                  <option value="PAID">支払済</option>
              </select>
            </div>
            <div *ngIf="element.pay_status !== 0">
              <select name="send_status_select" (change)="onChangePayStatus({invoice: element, new_status: $event.target.value})">
                <option value="NOT_YET">未払い</option>
                <option value="PAID" selected>支払済</option>
              </select>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="demand_level">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 送信済督促Lv </th>
          <td mat-cell *matCellDef="let element"> {{element.demand_status}} </td>
        </ng-container>
  
        <ng-container matColumnDef="demand_sent_by">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 督促状送信者 </th>
          <td mat-cell *matCellDef="let element"> {{element.demand_send_user_id}} </td>
        </ng-container>
  
        <ng-container matColumnDef="demand_sent_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 督促送信日 </th>
          <td mat-cell *matCellDef="let element"> {{datePart(element.demand_sent_at)}} </td>
        </ng-container>
  
        <ng-container matColumnDef="pay_amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 請求金額合計 </th>
          <td mat-cell *matCellDef="let element"> {{yen(element.sub_total + element.sub_total_tax)}} </td>
        </ng-container>
  
        <ng-container matColumnDef="pay_error">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 請求エラー </th>
          <td mat-cell *matCellDef="let element"> {{element.pay_error}} </td>
        </ng-container>
  
        <!-- <tr mat-header-row *matHeaderRowDef="display_columns; sticky: true"></tr> -->
        <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
        <tr mat-row *matRowDef="let row; columns: display_columns;"></tr>
      </table>
    </div>
  </div>