<div class="app-edit-property-dialog">
    <table style="width: 100%">
      <tr>
        <td colspan="2" style="width: 100%">
          {{data.title}}
        </td>
      </tr>
      <tr>
        <td colspan="2" style="width: 100%">
          <div *ngIf="data.value_type === 'line_status'">
            <mat-form-field class="full_width">
              <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onValueChanged($event)">
                <mat-option *ngFor="let item of candidates" [value]="item.key">
                  {{item.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'paymethod'">
            <mat-form-field class="full_width">
              <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onValueChanged($event)">
                <mat-option *ngFor="let item of candidates" [value]="item.key">
                  {{item.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'sex'">
            <mat-form-field class="full_width">
              <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onValueChanged($event)">
                <mat-option *ngFor="let item of candidates" [value]="item.key">
                  {{item.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'nationality'">
            <mat-form-field class="full_width">
              <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onValueChanged($event)">
                <mat-option *ngFor="let item of candidates" [value]="item.key">
                  {{item.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'status_of_residence'">
            <mat-form-field class="full_width">
              <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onValueChanged($event)">
                <mat-option *ngFor="let item of candidates" [value]="item.key">
                  {{item.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="data.value_type === 'fiber_plan_type'">
            <mat-form-field class="full_width">
              <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onValueChanged($event)">
                <mat-option *ngFor="let item of candidates" [value]="item.key">
                  {{item.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="data.value_type === 'fiber_installation_type'">
            <mat-form-field class="full_width">
              <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onValueChanged($event)">
                <mat-option *ngFor="let item of candidates" [value]="item.key">
                  {{item.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="data.value_type === 'fiber_rental_equipment'">
            <mat-form-field class="full_width">
              <mat-select placeholder="{{data.placeholder}}" (selectionChange)="onValueChanged($event)">
                <mat-option *ngFor="let item of candidates" [value]="item.key">
                  {{item.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="data.value_type === 'zip'">
            zip
          </div>
          <div *ngIf="data.value_type === 'tel'">
            <mat-form-field class="full_width">
              <input matInput type="tel" #InputUserId [(ngModel)]="input_value" name="input_value" (keyup)="onValueChanged($event)" (change)="onValueChanged($event)">
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'email'">
            <mat-form-field class="full_width">
              <input matInput type="email" #InputUserId [formControl]="email_form_control" [errorStateMatcher]="email_error_matcher"
                name="input_value" (keyup)="onValueChanged($event)" (change)="onValueChanged($event)">
              <mat-error *ngIf="email_form_control.hasError('email') && !email_form_control.hasError('required')">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="email_form_control.hasError('required')">
                Email is
                <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'address'">
            <mat-form-field class="full_width">
              <input matInput type="text" #InputUserId [(ngModel)]="input_value" name="input_value" (keyup)="onValueChanged($event)" (change)="onValueChanged($event)">
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'month'">
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="myMonthPicker">
              <mat-datepicker-toggle matSuffix [for]="myMonthPicker"></mat-datepicker-toggle>
              <mat-datepicker #myMonthPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'date'">
            <mat-form-field class="full_width">
              <input matInput [matDatepicker]="myDatePicker" placeholder="Date" [value]="input_value" (dateChange)="onValueChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="myDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #myDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'datetime'">
            datetime
          </div>
          <div *ngIf="data.value_type === 'file'">
            file
          </div>
          <div *ngIf="data.value_type === 'string'">
            <mat-form-field class="full_width">
              <input matInput type="text" #InputUserId [(ngModel)]="input_value" name="input_value" (keyup)="onValueChanged($event)" (change)="onValueChanged($event)">
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'text'">
            <mat-form-field class="full_width">
              <textarea matInput type="text" #InputUserId [(ngModel)]="input_value" name="input_value" (keyup)="onValueChanged($event)"
                (change)="onValueChanged($event)"></textarea>
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'currency'">
            <mat-form-field class="full_width">
              <input matInput type="number" #InputUserId [(ngModel)]="input_value" name="input_value" (keyup)="onValueChanged($event)"
                (change)="onValueChanged($event)">
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'number'">
            <mat-form-field class="full_width">
              <input matInput type="number" #InputUserId [(ngModel)]="input_value" name="input_value" (keyup)="onValueChanged($event)"
                (change)="onValueChanged($event)">
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'smartpit_number'">
            <mat-form-field class="full_width">
              <input matInput type="text" #InputUserId [(ngModel)]="input_value" name="input_value" (keyup)="onValueChanged($event)"
                (change)="onValueChanged($event)">
            </mat-form-field>
          </div>
          <div *ngIf="data.value_type === 'status'">
            status
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: right">
          <button mat-flat-button class="yes" (click)="onOkClicked($event)" [disabled]="button_ok_disable">Apply</button>
          <button mat-flat-button class="no" (click)="onCancelClicked($event)" [disabled]="button_cancel_disable">Cancel</button>
        </td>
      </tr>
    </table>
  </div>