import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatCheckboxChange,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import {
  InvoiceListItem,
  AuthGetReply,
} from '../../../models';
import { Utils } from '../../../helpers';
import { Subscription } from 'rxjs';
import { ListInvoicesService } from '../../../services/list';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import {
  SendInvoiceProgressDialogComponent,
  SendInvoiceProgressDialogData,
} from '../../dialog/send-invoice-progress-dialog/send-invoice-progress-dialog.component';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-list-invoices',
  templateUrl: './list-invoices.component.html',
  styleUrls: ['./list-invoices.component.scss']
})
export class ListInvoicesComponent implements OnInit, OnDestroy {
  public root_for_pdf: string;
  public invoices: Array<InvoiceListItem>;
  public invoices_send_targets: Array<InvoiceListItem>;
  public invoice_month_error: string; // TODO: what for?
  public display_columns: Array<string>;
  public data_source: MatTableDataSource<InvoiceListItem>;
  public selection = new SelectionModel<InvoiceListItem>(true, []);

  public unsent_only: boolean;
  public current_filter: string;
  public invoice_month: string;
  public date_filter_timer: any;

  protected _el: HTMLElement;
  private _refresh: boolean;
  private _subscription_refresh: Subscription;

  private _selecting_invoice: InvoiceListItem;
  private _subscription_list_invoices_current_item: Subscription;

  public confirm_dialog_ref: any;

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    private list_invoices_service: ListInvoicesService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.unsent_only = true;
    this.current_filter = '';
    this.invoice_month = '';
    this.date_filter_timer = undefined;
    this._el = el.nativeElement;
    this.display_columns = [
      'contract_code',
      'pdf',
      'mail_send_status',
      'mail_send_config',
      'mail_send_config_org',
      'user_e_mail',
      'invoice_products',
      'user_name',
      'contract_end_date',
      'pay_method',
      'pay_status',
      'pay_error',
    ];
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.root_for_pdf = environment.apiRoot;
    this._subscription_refresh = this.list_invoices_service.refresh$.subscribe(
      refresh => {
        if (refresh === true) {
          this.refresh();
          this.list_invoices_service.refresh = false;
        }
      }
    );
    this._subscription_list_invoices_current_item = this.list_invoices_service.current_item$.subscribe(
      item => {
        // console.log(
        //   'ListProductTypesComponent::_subscription_list_product_types_current_item : ' +
        //     JSON.stringify(item)
        // );
        const el_t = this._el.querySelector(
          'app-list-invoices div.list-invoices-table-container table tbody'
        );
        const el_rs = el_t.querySelectorAll('tr');
        const list = Array.prototype.slice.call(el_rs, 0);
        list.forEach(element => {
          if (element.classList.contains('selected')) {
            element.classList.remove('selected');
          }
          const el_td_contract_code = element.querySelectorAll(
            // '.mat-column-contract_code'
            '.cdk-column-contract_code'
          )[0];
          if (Utils.isValue(el_td_contract_code)) {
            if (item.contract_code === el_td_contract_code.innerText) {
              element.classList.add('selected');
              this._selecting_invoice = item;
            }
          }
        });
      }
    );
    this.invoices = new Array<InvoiceListItem>();
    this.refresh();
  }

  ngOnDestroy() {
    this._subscription_refresh.unsubscribe();
    this._subscription_list_invoices_current_item.unsubscribe();
  }

  public onClickSendInvoice(event) {
    this.invoices_send_targets = new Array<InvoiceListItem>();
    this.invoices.forEach(invoice => {
      if (invoice.mail_send_status === 0) {
        this.invoices_send_targets.push(invoice);
      }
    });

    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: 'Invoiceの送信',
        message: '未送信で自動送信設定のインボイスを<strong>全て送信</strong>します。<br/>' +
          'この作業には時間がかかります。<br/>' +
          '<strong>完了するまでブラウザを閉じないでください。</strong><br/>' +
          'よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          // editing
          const dialogProgressRef = this.dialog.open(SendInvoiceProgressDialogComponent, {
            width: '20rem',
            hasBackdrop: true,
            data: {
              invoice_month: this.invoice_month,
              invoices: this.invoices_send_targets,
            }
          });
          dialogProgressRef.afterClosed().subscribe(generate_result => {
            console.log('ListInvoicesComponent::onClickSendInvoice : invoice send end : ');
            this.refresh();
          });
        } else if (result.closeby === 'cancel') {
          console.log(
            'ContractComponent::onRemoveUserPropertyClicked() - closed by cancel'
          );
        } else {
          console.log(
            'ContractComponent::onRemoveUserPropertyClicked() - closed by unknown status.'
          );
        }
      }
    });
  }

  public refresh() {
    const params = {};
    params['unsent_only'] = this.unsent_only;
    if (Utils.isValue(this.invoice_month) !== false) {
      params['invoice_month'] = this.invoice_month;
    }

    if (this.data_source !== undefined) {
      this.data_source = undefined;
    }

    this.auth_service.apiInvoiceList(params, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      context.invoices = <Array<InvoiceListItem>>reply.data;
      context.data_source = new MatTableDataSource(context.invoices);
      context.data_source.sort = context.sort;
      context.data_source.paginator = context.paginator;
    });
  }

  public onRefreshList(event) {
    console.log('ListInvoicesComponent::onRefreshList');
    this.refresh();
  }

  public onClickRow(event) {
    console.log('ListInvoicesComponent::onClickRow');
  }

  public onUnsentOnlyCheckChanged(event: MatCheckboxChange) {
    console.log('ListInvoicesComponent::onOpenOnlyCheckChanged');
    this.unsent_only = event.checked;
    this.current_filter = '';
    this.refresh();
  }

  public onInvoiceMonthChanged(invoice_month: string) {
    console.log(
      'ListInvoicesComponent::onInvoiceMonthChanged : invoice_month=' +
      invoice_month
    );
    this.invoice_month = invoice_month;
    this.refresh();
  }

  public onChangeMailSendStatus(event: any): void {
    console.log('ListInvoicesComponent::onChangeMailSendStatus : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoiceSendStatus({invoice_id: event.invoice.id, new_status: event.new_status}).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }

  public onChangePayStatus(event: any): void {
    console.log('ListInvoicesComponent::onChangePayStatus : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoicePayStatus({invoice_id: event.invoice.id, new_status: event.new_status}).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }

  public onFilterKeyUp(filterValue: string) {
    if (Utils.isValue(this.data_source)) {
      this.data_source.filter = filterValue.trim().toLowerCase();
    }
  }

  public yen(num: number): string {
    return Utils.yen(num);
  }

  public getDisplayDateString(offsetZero: string): string {
    return Utils.getDisplayDateString(offsetZero);
  }

}
