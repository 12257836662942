import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthService } from '../../../services/auth';

@Component({
  selector: 'app-generate-topup-ticket-dialog',
  templateUrl: './generate-topup-ticket-dialog.component.html',
  styleUrls: ['./generate-topup-ticket-dialog.component.scss']
})
export class GenerateTopupTicketDialogComponent implements OnInit {

  public campaign_title: string;
  public selected_amount: number;
  public publish_count: number;
  public valid_until: string;

  public amount_candidates: Array<number>;

  constructor(
    protected auth_service: AuthService,
    public dialogRef: MatDialogRef<GenerateTopupTicketDialogComponent>
  ) {
    this.amount_candidates = [];
    this.initializeAmountCandidates();
  }

  ngOnInit() {
  }

  private initializeAmountCandidates(): void {
    for (let amount = 100; amount <= 5000; amount += 100) {
      this.amount_candidates.push(amount);
    }
    this.selected_amount = this.amount_candidates[0];
  }

  public onClickYes(event) {
    // generate tickets here
    console.log(`POST DATA : `, {
      campaign_title: this.campaign_title,
      topup_amount: this.selected_amount,
      publish_count: this.publish_count,
      valid_until: this.valid_until
    });

    this.auth_service.apiPostGenerateTopUpTickets(
      {
        campaign_title: this.campaign_title,
        topup_amount: this.selected_amount,
        publish_count: this.publish_count,
        valid_until: this.valid_until
      }
    ).subscribe(
      response => {
        console.log(`apiPostGenerateTopUpTickets success: `, response);
      },
      error => {
        console.log(`apiPostGenerateTopUpTickets error: `, error);
      }
    );
    this.dialogRef.close({
      generate: true,
      campaign_title: this.campaign_title,
    });
  }

  public onClickNo(event) {
    this.dialogRef.close();
  }
  
  public onChangedCampaignTitle(event: any): void {
    console.log('event : ', event);
    // this.campaign_title = event.value;
  }
  public onChangedDataAmount(event: any): void {
    console.log('event : ', event);
    this.selected_amount = event.value;
  }
  public onChangedPublishCount(event: any): void {
    console.log('event : ', event);
    // this.publish_count = parseInt(event.value);
  }
  public onValidUntilChanged(event: any): void {
    console.log('event : ', event);
    this.valid_until = event.value;
  }
}
