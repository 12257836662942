import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource
} from '@angular/material';
import {
  SearchTextListItem
} from 'src/app/models';
import { Utils } from 'src/app/helpers';
import { AuthService } from 'src/app/services/auth';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public display_app_search_text: string;

  private _el: HTMLElement;
  public contracts: Array<SearchTextListItem>;
  public current_filter: string;
  public display_columns: Array<string>;
  public data_source: MatTableDataSource<SearchTextListItem>;
  private _loading_animation;

  constructor(
    protected el: ElementRef,
    protected auth_service: AuthService
  ) {
    this._el = el.nativeElement;
    this.display_columns = [
      'contract_code',
      'category',
      'title',
      'value',
      'updated_at'
    ];
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.contracts = new Array<SearchTextListItem>();
    this.refresh(true);
  }

  public refresh(is_first: boolean): void {
    console.log('is_first=' + is_first + ' : current_filter=' + this.current_filter);
    if (Utils.isValue(this.current_filter) && this.current_filter.length === 0 && is_first === false) {
      // Don't search when there is no search text
      return;
    }
    if (!Utils.isValue(this._loading_animation)) {
      this._loading_animation = this._el.querySelector('#search_text_searching');
    }
    this._loading_animation.style.opacity = 1.0;  // show loading icon;
    this.auth_service.apiSearchText({text: this.current_filter}).subscribe(
      response => {
        // search in users
        this._loading_animation.style.opacity = 0.0;  // hide loading icon;
        if (Utils.isValue(response)) {
          if (Utils.isValue(response)) {
            this.data_source = new MatTableDataSource(response.data.records);
            this.data_source.sort = this.sort;
            this.data_source.paginator = this.paginator;
          }
        }
      },
      error => {
        this._loading_animation.style.opacity = 0.0;  // hide loading icon;
        // console.log(error);
      }
    );
  }

  public onRefreshList(event) {
    this.refresh(true);
  }

  public onFilterKeyUp(event: any) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      this.refresh(false);
    }
  }

  public onBlurFilter(event: any) {
    this.refresh(false);
  }

  public onClickRow(event) {
  }
  
  public getDisplayDatetimeString(utc_dt_string: string): string {
    return Utils.getDisplayDatetimeString(utc_dt_string)
  }
}
