<div class="app-parental-consent-component">
  <p>
    Contract code : {{(contract && contract.contract_code)? contract.contract_code : ''}}
  </p>
  <table>
    <tbody>
      <tr>
        <td colspan="2">
          Parent
        </td>
      </tr>
      <tr>
        <td class="title">
          email
        </td>
        <td class="value">
          <input id="parent_email" [(ngModel)]="parental_consent.email"
            [class.warning]="parental_consent.email != parental_consent_old.email" (blur)="onBlur($event)" />
        </td>
      </tr>
      <tr>
        <td>
          Full name
        </td>
        <td>
          <input id="parent_full_name" [(ngModel)]="parental_consent.full_name"
            [class.warning]="parental_consent.full_name != parental_consent_old.full_name" (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Address
        </td>
        <td>
          <input id="parent_address" [(ngModel)]="parental_consent.address"
            [class.warning]="parental_consent.address != parental_consent_old.address" (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Relationship
        </td>
        <td>
          <input id="parent_relationship" [(ngModel)]="parental_consent.relationship"
            [class.warning]="parental_consent.relationship != parental_consent_old.relationship"
            (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Date of birth
        </td>
        <td>
          <input id="parent_date_of_birth" [(ngModel)]="parental_consent.date_of_birth"
            [class.warning]="parental_consent.date_of_birth != parental_consent_old.date_of_birth"
            (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Age
        </td>
        <td>
          <input id="parent_age" [(ngModel)]="parental_consent.age"
            [class.warning]="parental_consent.age != parental_consent_old.age" (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Contact phone number
        </td>
        <td>
          <input id="parent_contact_phone_number" [(ngModel)]="parental_consent.contact_phone_number"
            [class.warning]="parental_consent.contact_phone_number != parental_consent_old.contact_phone_number"
            (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Submission date
        </td>
        <td>
          <input id="submission_date" [(ngModel)]="parental_consent.submission_date"
            [class.warning]="parental_consent.submission_date != parental_consent_old.submission_date"
            (blur)="onBlur($event)" />
        </td>
      </tr>
    </tbody>
  </table>
  <br/>
  <table>
    <tbody>
      <!-- -->
      <tr>
        <td colspan="2">
          Holder
        </td>
      </tr>
      <!-- -->
      <tr>
        <td class="title">
          Full name
        </td>
        <td class="value">
          <input id="holder_full_name" [(ngModel)]="parental_consent.holder_name"
            [class.warning]="parental_consent.holder_name != parental_consent_old.holder_name"
            (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Address
        </td>
        <td>
          <input id="holder_address" [(ngModel)]="parental_consent.holder_address"
            [class.warning]="parental_consent.holder_address != parental_consent_old.holder_address"
            (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Date of birth
        </td>
        <td>
          <input id="holder_date_of_birth" [(ngModel)]="parental_consent.holder_date_of_birth"
            [class.warning]="parental_consent.holder_date_of_birth != parental_consent_old.holder_date_of_birth"
            (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Age
        </td>
        <td>
          <input id="holder_age" [(ngModel)]="parental_consent.holder_age"
            [class.warning]="parental_consent.holder_age != parental_consent_old.holder_age" (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          Filtering service
        </td>
        <td>
          <input id="filtering_service" [(ngModel)]="parental_consent.filtering_service"
            [class.warning]="parental_consent.filtering_service != parental_consent_old.filtering_service"
            (blur)="onBlur($event)" />
        </td>
      </tr>
      <!-- -->
      <tr>
        <td>
          No filtering service reason
        </td>
        <td>
          <input id="not_need_filtering_service_reason" [(ngModel)]="parental_consent.not_need_filtering_service_reason"
            [class.warning]="parental_consent.not_need_filtering_service_reason != parental_consent_old.not_need_filtering_service_reason"
            (blur)="onBlur($event)" />
        </td>
      </tr>
    </tbody>
  </table>
  <table>
    <tbody>
      <!-- -->
      <tr>
        <td>
          Parental consent : Documents
        </td>
      </tr>
      <tr *ngFor="let parental_consent_document of parental_consent_documents">
        <td colspan="2">
          <a href="{{apiRoot}}/{{parental_consent_document.file_name}}" target="_blank">{{parental_consent_document.file_name}}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <button id="update_parental_consent" (click)="onUpdateParentalConsent($event)">
    Update
  </button>
</div>