import { Component, OnInit } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import { Subscription } from 'rxjs';
import { ElementRef } from '@angular/core';
import { Utils } from '../../../helpers';
import { AuthService } from '../../../services/auth';

@Component({
  selector: 'app-admin-tool',
  templateUrl: './admin-tool.component.html',
  styleUrls: ['./admin-tool.component.scss']
})
export class AdminToolComponent implements OnInit {

  // delete contract
  public delete_contract_contract_code: string;

  // add contract property type
  public add_contract_property_type_value_type: string;
  public add_contract_property_type_key: string;
  public add_contract_property_type_title: string;
  public add_contract_property_type_description: string;
  public add_contract_property_type_value_behavior: string;
  public add_contract_property_type_show_order: number;
  public add_contract_property_type_default_value: string;

  // add contract property type
  public add_product_type_title: string;
  public add_product_type_description: string;
  public add_product_type_invoice_sender_key: string;
  public add_product_type_show_order: number;

  // add product property type
  public add_product_property_type_value_type: string;
  public add_product_property_type_key: string;
  public add_product_property_type_title: string;
  public add_product_property_type_description: string;
  public add_product_property_type_value_behavior: string;
  public add_product_property_type_show_order: number;

  // apply product property type to product
  public apply_product_property_type_product_property_type_id: number;
  public apply_product_property_type_product_id: number;
  public apply_product_property_type_default_value: string;

  // checkfront api test
  public checkfront_api_test_contract_id: string;
  public display_columns: Array<string>;
  public booking_sim_infos: Array<any>;

  // JSONメッセージ送信テスト
  public is_post: boolean;
  public json_send_test_url: string;
  public json_send_test_send_data: string;
  public json_send_test_response_data: string;

  protected _el: HTMLElement;

  constructor(
    protected el: ElementRef,
    protected auth_service: AuthService,
    public snackBar: MatSnackBar
  ) {
    this._el = el.nativeElement;
    this.delete_contract_contract_code = '';
    this.add_contract_property_type_value_type = 'number, paymethod, zip, address, tel, date, text, status, file, bool';
    this.add_contract_property_type_key = '';
    this.add_contract_property_type_title = '';
    this.add_contract_property_type_description = '';
    this.add_contract_property_type_value_behavior = 'show_default,show_not_null,dont_use';
    this.add_contract_property_type_show_order = 20;
    this.add_contract_property_type_default_value = '';
    this.add_product_property_type_value_type = 'currency,string,tel,zip';
    this.add_product_property_type_key = '';
    this.add_product_property_type_title = '';
    this.add_product_property_type_description = '';
    this.add_product_property_type_value_behavior = 'show_default,show_invoice_dont,transfer_default,transfer_dont,reset_kpd_import';
    this.add_product_property_type_show_order = 28;
    this.is_post = false;
    this.display_columns = [
      'booking_id',
      'sim_id0',
      'sim_id1',
      'sim_id2',
      'sim_id3',
    ];
  }

  ngOnInit() {
  }
  public onClickDeleteContract(): void {
    console.log('AdminToolComponent::onClickDeleteContract : contract_code=' + this.delete_contract_contract_code);

    const params = {
      contract_code: this.delete_contract_contract_code
    };
    this.auth_service.apiAdminDeleteContract(params).subscribe(
      response => {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          response.message.message,
          'OK',
          cfg
        );
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          error.error.message.message,
          'OK',
          cfg
        );
      }
    );
  }

  public onClickAddContractPropertyType(): void {
    console.log('AdminToolComponent::onClickAddContractPropertyType');
    console.log('value_type : '  + this.add_contract_property_type_value_type);
    console.log('key : '  + this.add_contract_property_type_key);
    console.log('title : '  + this.add_contract_property_type_title);
    console.log('description : '  + this.add_contract_property_type_description);
    console.log('value_behavior : '  + this.add_contract_property_type_value_behavior);
    console.log('default_value : '  + this.add_contract_property_type_default_value);
    console.log('show_order : '  + this.add_contract_property_type_show_order);
    const params = {
      value_type : this.add_contract_property_type_value_type,
      key : this.add_contract_property_type_key,
      title : this.add_contract_property_type_title,
      description : this.add_contract_property_type_description,
      value_behavior : this.add_contract_property_type_value_behavior,
      default_value : this.add_contract_property_type_default_value,
      show_order : this.add_contract_property_type_show_order,
    };
    this.auth_service.apiAdminAddContractPropertyType(params).subscribe(
      response => {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          response.message.message,
          'OK',
          cfg
        );
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          error.error.message.message,
          'OK',
          cfg
        );
      }
    );
  }

  public onClickAddProductType(): void {
    console.log('AdminToolComponent::onClickAddProductType');
    console.log('title : '  + this.add_product_type_title);
    console.log('description : '  + this.add_product_type_description);
    console.log('invoice_sender_key : '  + this.add_product_type_invoice_sender_key);
    console.log('show_order : '  + this.add_product_type_show_order);
    const params = {
      title : this.add_product_type_title,
      description : this.add_product_type_description,
      invoice_sender_key : this.add_product_type_invoice_sender_key,
      show_order : this.add_product_type_show_order,
    };
    this.auth_service.apiAdminAddProductType(params).subscribe(
      response => {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          response.message.message,
          'OK',
          cfg
        );
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          error.error.message.message,
          'OK',
          cfg
        );
      }
    );
  }

  public onClickAddProductPropertyType(): void {
    console.log('AdminToolComponent::onClickAddProductPropertyType');
    console.log('value_type : '  + this.add_product_property_type_value_type);
    console.log('key : '  + this.add_product_property_type_key);
    console.log('title : '  + this.add_product_property_type_title);
    console.log('description : '  + this.add_product_property_type_description);
    console.log('value_behavior : '  + this.add_product_property_type_value_behavior);
    console.log('show_order : '  + this.add_product_property_type_show_order);
    const params = {
      value_type : this.add_product_property_type_value_type,
      key : this.add_product_property_type_key,
      title : this.add_product_property_type_title,
      description : this.add_product_property_type_description,
      value_behavior : this.add_product_property_type_value_behavior,
      show_order : this.add_product_property_type_show_order,
    };
    this.auth_service.apiAdminAddProductPropertyType(params).subscribe(
      response => {
        this.apply_product_property_type_product_property_type_id = response.data.id;
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          response.message.message,
          'OK',
          cfg
        );
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          error.error.message.message,
          'OK',
          cfg
        );
      }
    );
  }

  public onClickApplyProductPropertyTypeToProduct(): void {
    console.log('AdminToolComponent::onClickApplyProductPropertyTypeToProduct');
    console.log('product_property_type_id : '  + this.apply_product_property_type_product_property_type_id);
    console.log('product_type_id : '  + this.apply_product_property_type_product_id);
    const params = {
      product_property_type_id : this.apply_product_property_type_product_property_type_id,
      product_type_id : this.apply_product_property_type_product_id,
      default_value : this.apply_product_property_type_default_value,
    };
    this.auth_service.apiAdminApplyProductPropertyTypeToProduct(params).subscribe(
      response => {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          response.message.message,
          'OK',
          cfg
        );
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          error.error.message.message,
          'OK',
          cfg
        );
      }
    );
  }

  public bookingSimInfoToTableRow(booking: any): Array<string> {
    const result = [];
    result.push(booking.booking_id);
    booking.sim_ids.forEach(sim_id => {
      result.push(JSON.stringify(sim_id));
    });
    return result;
  }

  public onClickCheckfrontAPITest(): void {
    console.log('AdminToolComponent::onClickCheckfrontAPITest');
    const params = {
      contract_code : this.checkfront_api_test_contract_id,
    };
    this.auth_service.apiCheckfrontAPITest(params).subscribe(
      response => {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          response.message.message,
          'OK',
          cfg
        );
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(
          error.error.message.message,
          'OK',
          cfg
        );
      }
    );
  }

  public onClickSendJSON(): void {
    if (this.is_post) {
      console.log('AdminToolComponent::onClickCheckfrontAPITest - send by post');
      const params = JSON.parse(this.json_send_test_send_data);
      this.auth_service.post(this.json_send_test_url, params).subscribe(
        response => {
          console.log(response);
          this.json_send_test_response_data = JSON.stringify(response);
        },
        error => {
          console.log(error);
          this.json_send_test_response_data = JSON.stringify(error);
        }
      );
    }
    else {
      console.log('AdminToolComponent::onClickCheckfrontAPITest - send by get');
      this.auth_service.get(this.json_send_test_url).subscribe(
        response => {
          console.log(response);
          this.json_send_test_response_data = JSON.stringify(response);
        },
        error => {
          console.log(error);
          this.json_send_test_response_data = JSON.stringify(error);
        }
      );
    }
  }
}
