<div class="app-topup-tickets">
  <table class="operation-header" style="width: 100%">
    <tr>
      <td style="text-align: left"></td>
      <td style="text-align: right">
        <button mat-flat-button (click)="onClickGenerateTicket($event)">
          チケット追加
        </button>
        <button mat-flat-button (click)="onClickExportTickets($event)">
          CSVエクスポート
        </button>
      </td>
    </tr>
  </table>

  <table class="operation-filter">
    <tr>
      <td class="refresh">
        <button
          mat-icon-button
          class="edit_icon"
          (click)="onRefreshList($event)"
        >
          <mat-icon aria-label="Refresh list">refresh</mat-icon>
        </button>
      </td>
      <td>
        <table>
          <tr>
            <td class="select-campaign">
              <mat-form-field>
                <mat-select
                  [(value)]="selected_campaign"
                  (selectionChange)="onChangedCampaign($event)"
                >
                  <mat-option *ngFor="let item of campaigns" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table>
          <tr>
            <td style="min-width: 1.5rem">
              <img
                id="top_up_ticket_updating"
                src="loading_s.gif"
                style="width: 1.5rem; opacity: 0"
              />
            </td>
          </tr>
        </table>
      </td>
      <td class="list-paginator">
        <mat-paginator
          class="list-paginator"
          [pageSizeOptions]="[50, 100, 500, 1000, 5000, 10000, 50000]"
          showFirstLastButtons
        ></mat-paginator>
      </td>
    </tr>
  </table>
  <div class="list-contracts-table-container mat-elevation-z8">
    <table mat-table [dataSource]="data_source" matSort>
      <ng-container matColumnDef="campaign_title">
        <th class="center" mat-header-cell *matHeaderCellDef mat-sort-header>キャンペーン</th>
        <td mat-cell *matCellDef="let element">
          {{ element.campaign_title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="topup_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>データ量</th>
        <td class="right" mat-cell *matCellDef="let element">
          {{ element.topup_amount }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valid_until">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>有効期限</th>
        <td mat-cell *matCellDef="let element">
          {{ formatDatePart(element.valid_until) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="applied">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>利用済</th>
        <td class="center" mat-cell *matCellDef="let element">
          {{ formatUsedOrNot(element.applied) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="used_phone_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>利用番号</th>
        <td mat-cell *matCellDef="let element">
          {{ element.used_phone_number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="user_full_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>利用者</th>
        <td mat-cell *matCellDef="let element">
          {{ element.user_full_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          メールアドレス
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="display_columns; sticky: true"></tr> -->
      <tr mat-header-row *matHeaderRowDef="display_columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: display_columns"
        (click)="onClickRow($event)"
      ></tr>
    </table>
  </div>
</div>
