import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InvoiceListItemProductProperty, InvoiceContentListItem } from '../../../models';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';
import { FlashMessage, AuthGetReply } from '../../../models';

@Component({
  selector: 'app-edit-invoice-product-property-dialog',
  templateUrl: './edit-invoice-product-property-dialog.component.html',
  styleUrls: ['./edit-invoice-product-property-dialog.component.scss']
})
export class EditInvoiceProductPropertyDialogComponent implements OnInit {
  public key: string;
  public title: string;
  public description: string;
  public amount: number;
  public button_ok_disable: boolean;
  public button_cancel_disable: boolean;

  constructor(
    protected auth_service: AuthService,
    public dialogRef: MatDialogRef<EditInvoiceProductPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvoiceContentListItem
  ) {
    console.log('EditInvoiceProductPropertyDialogComponent::constructor');
    console.log(this.data);
    this.button_ok_disable = true;
  }

  OnInit() {
    console.log('EditInvoiceProductPropertyDialogComponent::OnInit');
  }

  ngOnInit() {
    console.log('EditInvoiceProductPropertyDialogComponent::ngOnInit');
    console.log(this.data);
    this.key = this.data.key;
    this.title = this.data.title;
    this.description = this.data.description;
    this.amount = this.data.amount;
  }

  public switchApplyDisable() {
    if (this.amount === this.data.amount && this.description === this.data.description) {
      this.button_ok_disable = true;
      return;
    }
    if (this.description.length <= 0) {
      this.button_ok_disable = true;
      return;
    }
    this.button_ok_disable = false;
  }

  public onDescriptionChanged(event): void {
    this.switchApplyDisable();
  }

  public onAmountChanged(event): void {
    this.switchApplyDisable();
  }

  public onOkClicked(event): void {
    console.log('EditInvoiceProductPropertyDialogComponent::onOkClicked');
    const param = {
      invoice_id: this.data.invoice_id,
      invoice_product_id: this.data.invoice_product_id,
      key: this.key,
      description: this.description,
      amount: this.amount,
    };
    this.auth_service.apiSetInvoiceContentProperty(param, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      console.log(reply);
      context.dialogRef.close({
        applied: true
      });
    });
  }

  public onCancelClicked(event): void {
    this.dialogRef.close({
      applied: false
    });
  }
}
