import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';
import { FlashMessage, AuthGetReply } from '../../../models';

export class ConfirmDialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {

  }

  ngOnInit() {
  }

  public onCancelClicked(event): void {
    console.log('ConfirmDialog::onCancelClicked -start');
    this.dialogRef.close({
      closeby: 'cancel'
    });
  }

  public onOkClicked(event): void {
    console.log('ConfirmDialog::onOkClicked -start');
    this.dialogRef.close({
      closeby: 'ok'
    });
  }
}
