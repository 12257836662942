import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ContractListItem,
  AuthGetReply,
  ContractStatus
} from '../../../models';
import { Utils } from '../../../helpers';
import { Subscription } from 'rxjs';
import { ListContractsService } from '../../../services/list';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import {
  GenerateInvoiceConfirmDialogComponent
} from '../../dialog/generate-invoice-confirm-dialog/generate-invoice-confirm-dialog.component';
import {
  GenerateInvoiceProgressDialogComponent
} from '../../dialog/generate-invoice-progress-dialog/generate-invoice-progress-dialog.component';
import {
  NewContractDialogComponent
} from '../../dialog/new-contract-dialog/new-contract-dialog.component';

@Component({
  selector: 'app-list-contracts',
  templateUrl: './list-contracts.component.html',
  styleUrls: ['./list-contracts.component.scss']
})
export class ListContractsComponent implements OnInit, OnDestroy {
  public status_filter_form: FormControl;
  public contract_status_titles: Array<string>;
  public contract_statuses: Array<ContractStatus>;
  public contract_status_filters: Array<string>;

  public contracts_all: Array<ContractListItem>;
  public contracts: Array<ContractListItem>;
  public display_columns: Array<string>;
  public data_source: MatTableDataSource<ContractListItem>;
  public selection = new SelectionModel<ContractListItem>(true, []);

  public current_filter: string;
  public end_date_from: string;
  public end_date_to: string;
  public end_date_error: boolean;
  public date_filter_timer: any;

  protected _el: HTMLElement;
  private _refresh: boolean;
  private _subscription_refresh: Subscription;

  private _selecting_contract: ContractListItem;
  private _subscription_list_contracts_current_item: Subscription;
  public disable_generate_selecting_invoice: boolean;

  private loading_animation;

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    private list_contracts_service: ListContractsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.status_filter_form = new FormControl();
    this.contract_status_filters = [];
    this.current_filter = '';
    this.end_date_from = '';
    this.end_date_to = '';
    this.end_date_error = false;
    this.date_filter_timer = undefined;
    this._el = el.nativeElement;
    this.display_columns = [
      // 'select',
      'contract_code',
      'user_full_name',
      'email',
      'status',
      'pay_method',
      'subscription_plan',
      'pickup_method',
      'pickup_date',
      'prefer_pickup_date',
      'start_at',
      'end_at',
      'note',
      'updated_at',
    ];
    this.disable_generate_selecting_invoice = true;
  }

  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  ngOnInit() {
    this.auth_service.apiEnumContractStatuses().subscribe(
      response => {
        this.contract_statuses = response.data;
        this.contract_status_titles = new Array<string>();
        this.contract_statuses.forEach(v => {
          this.contract_status_titles.push(v.title);
        });
      },
      error => {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 10000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'Failed to load contract status candidates.',
          'OK',
          cfg
        );
        console.log(JSON.stringify(error));
      }
    );
    this._subscription_refresh = this.list_contracts_service.refresh$.subscribe(
      refresh => {
        if (refresh === true) {
          this.refresh(true);
          this.list_contracts_service.refresh = false;
        }
      }
    );
    this._subscription_list_contracts_current_item = this.list_contracts_service.current_item$.subscribe(
      item => {
        // console.log(
        //   'ListProductTypesComponent::_subscription_list_product_types_current_item : ' +
        //     JSON.stringify(item)
        // );
        const el_t = this._el.querySelector(
          'app-list-contracts div.list-contracts-table-container table tbody'
        );
        const el_rs = el_t.querySelectorAll('tr');
        const list = Array.prototype.slice.call(el_rs, 0);
        list.forEach(element => {
          if (element.classList.contains('selected')) {
            element.classList.remove('selected');
          }
          const el_td_contract_code = element.querySelectorAll(
            // '.mat-column-contract_code'
            '.cdk-column-contract_code'
          )[0];
          if (Utils.isValue(el_td_contract_code)) {
            if (item.contract_code === el_td_contract_code.innerText.trim()) {
              element.classList.add('selected');
              this._selecting_contract = item;
              this.disable_generate_selecting_invoice = false;
            }
          }
        });
      }
    );
    this.contracts = new Array<ContractListItem>();
    // this.refresh(true);
  }

  ngOnDestroy() {
    this._subscription_refresh.unsubscribe();
    this._subscription_list_contracts_current_item.unsubscribe();
  }

  public refresh(from_db: boolean) {
    if (from_db) {
      const params = {};

      if (!Utils.isValue(this.loading_animation)) {
        this.loading_animation = this._el.querySelector('#contract_list_updating');
      }
      this.loading_animation.style.opacity = 1.0;  // show loading icon;

      this.auth_service.apiListContract(params, this, function(
        reply: AuthGetReply,
        context: any
      ) {
        console.log('ListContractsComponent::refresh : ',reply.data);
        context.contracts_all = <Array<ContractListItem>>reply.data;
        context.contracts_all.forEach(contract => {
          if (contract.pickup_date) {
            contract.pickup_date = new Date(contract.pickup_date);
          }
          if (contract.prefer_pickup_date) {
            contract.prefer_pickup_date = new Date(contract.prefer_pickup_date);
          }
        });
        context.refresh(false);
        context.loading_animation.style.opacity = 0.0;  // hide loading icon;
        return;
      });
    }
    if (Utils.isValue(this.contracts_all)) {
      this.contracts = new Array<ContractListItem>();
      this.contracts_all.forEach(contract => {
        if (this.contract_status_filters == null || this.contract_status_filters.length === 0 || this._isShowContractStatus(contract.contract_status) ) {
          this.contracts.push(contract);
        }
      });
    }
    this.data_source = new MatTableDataSource(this.contracts);
    this.data_source.sort = this.sort;
    this.data_source.paginator = this.paginator;
    this.disable_generate_selecting_invoice = true;
  }

  private _isShowContractStatus(status: number): boolean {
    const statuses = this.contract_statuses.filter(v => {
      return v.status === status;
    });
    if (statuses.length === 0) {
      return false;
    }
    const matched = this.contract_status_filters.filter(v => {
      return v === statuses[0].title;
    });
    return matched.length > 0;
  }

  public onClickNewContract(event): void {
    console.log('ListContracts::onClickNewContract');
    const dialogNewContractRef = this.dialog.open(
      NewContractDialogComponent,
      {
        width: '60rem',
        hasBackdrop: true,
      }
    );
    dialogNewContractRef.afterClosed().subscribe(generate_result => {
      if (generate_result.closeby === 'ok') {
        this.refresh(true);
      }
    });
  }

  public onClickRow(event): void {
    const el_r = event.target.parentElement;
    // title => .mat-column-title
    const el_c = el_r.querySelector('.mat-column-contract_code');
    if (!Utils.isValue(el_c)) {
      return;
    }
    const contract_code = el_c.innerText.trim();
    const found = this.contracts.filter(function(candidate: ContractListItem) {
      return candidate.contract_code === contract_code;
    });
    if (found.length > 0) {
      this.list_contracts_service.current_item = found[0];
    }
  }

  public onRefreshList(event) {
    console.log('ListContractsComponent::onRefreshList');
    this.refresh(true);
  }

  private endDateFilter() {
    this.end_date_error = false;
    if (Utils.isValue(this.end_date_from) && Utils.isValue(this.end_date_to)) {
      if (this.end_date_from.length > 0 && this.end_date_to.length > 0) {
        if (this.end_date_from > this.end_date_to) {
          console.log(
            'ListContractsComponent::onEndDateFrom : end_date_from=' +
              this.end_date_from +
              ' > end_date_to=' +
              this.end_date_to
          );
          this.end_date_error = true;
          return; // don't do refresh if invalid date specification
        }
      }
    }
    if (this.date_filter_timer !== undefined) {
      clearTimeout(this.date_filter_timer);
    }
    this.date_filter_timer = setTimeout(
      function() {
        this.refresh(false);
        this.date_filter_timer = undefined;
      }.bind(this),
      100
    );
  }

  public onEndDateFrom(year_month: string) {
    console.log(
      'ListContractsComponent::onEndDateFrom : year_month=' +
        year_month +
        ' : end_date_from=' +
        this.end_date_from
    );
    this.endDateFilter();
  }

  public onEndDateTo(year_month: string) {
    console.log(
      'ListContractsComponent::onEndDateTo : year_month=' +
        year_month +
        ' : end_date_to=' +
        this.end_date_to
    );
    this.endDateFilter();
  }

  public onClearTextFilter(event: any): void {
    this.current_filter = '';
    this.data_source.filter = '';
  }

  public onFilterKeyUp(filterValue: string) {
    if (Utils.isValue(this.data_source)) {
      this.data_source.filter = filterValue.trim().toLowerCase();
    }
  }

  public onClickGenerateInvoice(event) {
    console.log('ListContractsComponent::onClickGenerateInvoice :');
    const dialogConfirmRef = this.dialog.open(
      GenerateInvoiceConfirmDialogComponent,
      {
        width: '20rem',
        hasBackdrop: true,
        data: {
          invoice_month: null, // actually, this is not used
          contract: this._selecting_contract
        }
      }
    );

    dialogConfirmRef.afterClosed().subscribe(confirm_result => {
      if (Utils.isValue(confirm_result)) {
        if (confirm_result.generate === true) {
          // call generate api here
          console.log(confirm_result);
          console.log(this._selecting_contract);
        }
      }
    });
  }

  public onClickGenerateInvoices(event) {
    console.log('ListContractsComponent::onClickGenerateInvoice :');
    const dialogConfirmRef = this.dialog.open(
      GenerateInvoiceConfirmDialogComponent,
      {
        width: '20rem',
        hasBackdrop: true,
        data: {
          invoice_month: null,
          contracts: null
        }
      }
    );

    dialogConfirmRef.afterClosed().subscribe(confirm_result => {
      if (Utils.isValue(confirm_result)) {
        if (confirm_result.generate === true) {
          if (Utils.isValue(confirm_result.contract)) {
            // end generate an invoice
          } else {
            console.log(
              'ListContractsComponent::onClickGenerateInvoice : invoice generate start : charge month=' +
                confirm_result.invoice_month
            );
            const dialogProgressRef = this.dialog.open(
              GenerateInvoiceProgressDialogComponent,
              {
                width: '20rem',
                hasBackdrop: true,
                data: {
                  invoice_month: confirm_result.invoice_month
                }
              }
            );
            dialogProgressRef.afterClosed().subscribe(generate_result => {
              console.log(
                'ListContractsComponent::onClickGenerateInvoice : invoice generate end : '
              );
              console.log(generate_result);
            });
          }
        }
      }
    });
  }

  public getDisplayDateString(offsetZero: string): string {
    return Utils.getDisplayDateString(offsetZero);
  }

  public getDisplayDatetimeString(offsetZero: string): string {
    return Utils.getDisplayDatetimeString(offsetZero);
  }

  public onClearStatusFilter(event: any): void {
    this.contract_status_filters = [];
    this.refresh(false);  // 表示内容を更新
  }

  public onStatusFilterSelectionChanged(event: any): void {
    // 使用可能なステータスの中から該当を検索
    const statuses = this.contract_statuses.filter(v => {
      return v.title === event.source.value;
    });
    if (statuses.length > 0) {
      // フィルタの配列からとりあえず削除
      this.contract_status_filters = this.contract_status_filters.filter(v => {
        console.log(statuses[0]);
        return v !== statuses[0].title;
      });
      // フィルタ配列に追加
      if (event.source.selected === true) {
        this.contract_status_filters.push(statuses[0].title);
      }
    }
    console.log(this.contract_status_filters);
    this.refresh(false);  // 表示内容を更新
  }

  public onChangeContractStatus(event: any): void {
    console.log(JSON.stringify(event));
    const changed = this.data_source.data.filter(v => {
      return v.contract_id === event.contract_id;
    });
    if (changed.length > 0) {
      const new_status = this.contract_statuses.filter(v => {
        return v.title === event.new_title;
      });
      if (new_status.length > 0) {
        const old_status = changed[0].contract_status;
        changed[0].contract_status = new_status[0].status;
        this.auth_service.apiUpdateContractStatus(changed[0]).subscribe(
          response => {
            console.log(JSON.stringify(response));
            this.snackBar.dismiss();
            const barcfg = new MatSnackBarConfig();
            barcfg.duration = 5000;
            barcfg.panelClass = ['notify_snackbar', 'success'];
            this.snackBar.open(response.message.message, 'OK', barcfg);
          },
          error => {
            console.log(error);
            this.snackBar.dismiss();
            const barcfg = new MatSnackBarConfig();
            barcfg.duration = 5000;
            barcfg.panelClass = ['notify_snackbar', 'error'];
            this.snackBar.open(error.error.message.message, 'OK', barcfg);
          }
        );
        return;
      }
    }
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 3000;
    cfg.panelClass = ['notify_snackbar', 'success'];
    this.snackBar.open(
      'Failed to set new contract status.',
      'OK',
      cfg
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data_source.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data_source.data.forEach(row => this.selection.select(row));
  }
}
