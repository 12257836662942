export class Candidates {
    public static fiber_statuses = [
        'Application内容確認中（住所など）',
        'NTTオーダー入力完了',
        'SakuraMobile対応必要',
        'NTT対応中',
        '管理会社フォーム返答待ち',
        '管理会社フォーム返答あり',
        '工事日程調整中',
        '日程変更希望あり',
        '注文取次OK',
        '注文取次完了 -コンセント確認待ち',
        '注文取次完了 -管理者の情報待ち',
        '注文取次完了 -管理者に連絡待ち',
        '注文取次完了',
        '事前連絡とISP登録済み',
        '開通確認中',
        '工事後トラブル対応',
        'Smartpit支払い待ち',
        'Smartpit支払い完了',
        '契約完了(OPDB登録済み)',
        'WIFI未返却・DB商品追加済(工事完了)',
        'WIFI未返却(工事不可・DBレンタル商品済み)',
        '一旦保留',
        '119)速度問題あり',
        '119)接続問題あり'
    ];
}