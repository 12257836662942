import {environment} from 'src/environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import {
  AuthGetReply,
  ContractSummary,
  ParentalConsent,
  ParentalConsentDocument
} from '../../../models';
import { Utils } from '../../../helpers';
import { StylingIndex } from '@angular/core/src/render3/styling';

@Component({
  selector: 'app-parental-consent',
  templateUrl: './parental-consent.component.html',
  styleUrls: ['./parental-consent.component.scss']
})
export class ParentalConsentComponent implements OnInit {

  public contract_id: number;
  public contract: ContractSummary;
  public parental_consent: ParentalConsent;
  public parental_consent_old: ParentalConsent;
  public parental_consent_documents: any;
  public apiRoot: string;

  constructor(
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.parental_consent = new ParentalConsent;
    this.parental_consent_old = new ParentalConsent;
    this.apiRoot = environment.apiRoot;
  }

  ngOnInit() {
    if (this.auth_service.isLoggingIn() !== true) {
      location.href = '/auth/login';
      return;
    }
    this.auth_service.apiIsMemberUser('operator', this, function (
      reply: AuthGetReply,
      context: any
    ) {
      if (reply.data.is_member === true) {
      } else {
        location.href = '/auth/login';
      }
    });
    this.contract_id = Number.parseInt(Utils.getUrlParameter('contract_id'));
    this.refresh();
  }

  ngOnDestroy() {
    return this.isEdited();
  }

  private isEdited(): boolean {
    if (
      this.parental_consent_old.email !== this.parental_consent.email ||
      this.parental_consent_old.full_name !== this.parental_consent.full_name ||
      this.parental_consent_old.address !== this.parental_consent.address ||
      this.parental_consent_old.relationship !== this.parental_consent.relationship ||
      this.parental_consent_old.date_of_birth !== this.parental_consent.date_of_birth ||
      this.parental_consent_old.age !== this.parental_consent.age ||
      this.parental_consent_old.contact_phone_number !== this.parental_consent.contact_phone_number ||
      this.parental_consent_old.submission_date !== this.parental_consent.submission_date ||
      this.parental_consent_old.holder_name !== this.parental_consent.holder_name ||
      this.parental_consent_old.holder_address !== this.parental_consent.holder_address ||
      this.parental_consent_old.holder_date_of_birth !== this.parental_consent.holder_date_of_birth ||
      this.parental_consent_old.holder_age !== this.parental_consent.holder_age ||
      this.parental_consent_old.filtering_service !== this.parental_consent.filtering_service ||
      this.parental_consent_old.not_need_filtering_service_reason !== this.parental_consent.not_need_filtering_service_reason
    ) {
      return false;
    }
    return true;
  }

  private refresh(): void {
    const params = {
      contract_id: this.contract_id
    }
    this.auth_service.apiGetParentalConsent(params).subscribe(
      response => {
        this.contract = {...response.data.contract};
        this.parental_consent = {...response.data.parental_consent};
        this.parental_consent_old = {...response.data.parental_consent};
        console.log('success to get parental consent', this.parental_consent);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        if (
          Utils.isValue(error.error.message) &&
          Utils.isValue(error.error.message.message)
        ) {
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
        else {
          this.snackBar.open("Failed to get parental consent : Server error", 'OK', cfg);
        }
      }
    );

    this.auth_service.apiGetParentalConsentDocuments(params).subscribe(
      response => {
        this.parental_consent_documents = response.data.parental_consent_documents;
        this.parental_consent_documents.forEach(pcd => {
          this.auth_service.apiPrepareParentalConsentDocument({
            file_name: pcd.file_name
          }).subscribe(
            response => {
              console.log('File : ' + pcd.file_name + ' success to prepare.', response);
            },
            error => {
              console.log('File : ' + pcd.file_name + ' failed to prepare.', response);
            }
          );
        });
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        if (
          Utils.isValue(error.error.message) &&
          Utils.isValue(error.error.message.message)
        ) {
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
        else {
          this.snackBar.open("Failed to get parental consent documents: Server error", 'OK', cfg);
        }
      }
    );
  }

  public onBlur(event: any): void {
  }

  public onUpdateParentalConsent(event: any) {
    const param = {
      contract_id: this.contract_id,
      field: 'fff',
      value: 'vvv'
    }
    this.auth_service.apiSetParentalConsent(param).subscribe(
      response => {
        console.log('success to update parental consent', this.parental_consent);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        if (
          Utils.isValue(error.error.message) &&
          Utils.isValue(error.error.message.message)
        ) {
          this.snackBar.open(error.error.message.message, 'OK', cfg);
        }
        else {
          this.snackBar.open("Failed to update parental consent : Server error", 'OK', cfg);
        }
      }
    );
  }
}
