import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { ContractProperty } from '../../../models/contract-detail';
import { AuthService } from '../../../services/auth';
import { EditPropertyDialogBaseComponent } from '../edit-property-dialog-base/edit-property-dialog-base.component';
import { Utils } from '../../../helpers';

@Component({
  selector: 'app-edit-contract-property-dialog',
  templateUrl:
    '../edit-property-dialog-base/edit-property-dialog-base.component.html',
  styleUrls: [
    '../edit-property-dialog-base/edit-property-dialog-base.component.scss'
  ]
})
export class EditContractPropertyDialogComponent extends EditPropertyDialogBaseComponent {
  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditContractPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContractProperty
  ) {
    super(dialogRef, data);
  }

  public onOkClicked(event) {
    console.log('EditContractPropertyDialogComponent::onOkClicked');
    console.log(this.data);
    this.data.value = this.input_value;
    let params = null;
    if (this.data.value_type === 'date') {
      console.log(Utils.getBackendDatetimeString(this.data.value, this.data.option.time_adjust));
      if (Utils.isValue(this.data.option) && Utils.isValue(this.data.option.time_adjust)) {
        params = {
          property_id: this.data.contract_property_id,
          new_value: Utils.getBackendDatetimeString(this.data.value, this.data.option.time_adjust),
          is_unique: false
        };
      }
    } else {
      params = {
        property_id: this.data.contract_property_id,
        new_value: this.data.value,
        is_unique: false
      };
    }
    console.log(params);
    this.auth_service.apiUpdateContractProperty(params).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
          this.dialogRef.close({
            applied: true
          });
        }
      },
      error => {
        if (Utils.isValue(error.message) && Utils.isValue(error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }
}
