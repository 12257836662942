
<!-- <form #imageForm=ngForm (ngSubmit)="onOkClicked($event)" class="mui-form"> -->
<form #imageForm=ngForm class="mui-form">
  <table style="width: 100%">
    <!-- ROW ========== ROW -->
    <tr>
      <td style="width: 47%;">
        新規契約の作成<br/>
      </td>
      <td style="width: 6%;">
      </td>
      <td style="width: 47%;">
      </td>
    </tr>
    <!-- ROW ========== ROW -->
    <tr>
      <td>
        <table style="width: 100%;">
          <tr>
            <td>
              <mat-form-field class="full_width">
                <input matInput type="email" #InputUserId [(ngModel)]="input_user_id" name="input_user_id" (change)="onUserIdChanged($event)" [errorStateMatcher]="email_error_matcher" placeholder="e-mail">
              </mat-form-field>
              <!--
              <mat-error *ngIf="email_form_control.hasError('email') && !email_form_control.hasError('required')">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="email_form_control.hasError('required')">
                  Email is
                  <strong>required</strong>
              </mat-error>
              -->
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="full_width">
                <input matInput type="text" #ContractCode [(ngModel)]="input_full_name" name="input_full_name" (change)="onUserFullNameChanged($event)" [disabled]=input_full_name_disabled placeholder="User full name">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <form [formGroup]="pay_method_category">
                <mat-form-field class="full_width">
                  <mat-select class="pay_method_select" formControlName="pay_method_category" placeholder="支払い方法を選択" (selectionChange)="onChangedSelectingPayMethod($event)" [disabled]=input_pay_method_disabled>
                    <mat-option *ngFor="let pay_method of pay_methods" [value]="pay_method">
                      {{pay_method.title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </td>
          </tr>
        </table>
      </td>
      <td></td>
      <td>
        <table style="width: 100%;">
          <tr>
            <td>
              <mat-form-field class="full_width">
                <input matInput type="text" #ContractCode [(ngModel)]="input_contract_code" name="input_contract_code" (change)="onContractCodeChanged($event)" (keyup)="onContractCodeChanged($event)" placeholder="Contract code">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="full_width">
                <mat-select placeholder="契約商品を選択" (selectionChange)="onChangedSelectingContractProduct($event)">
                  <mat-option *ngFor="let contract_product of contract_products" [value]="contract_product">
                    {{contract_product.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="full_width">
                <input matInput [matDatepicker]="startDatePicker" placeholder="契約開始日" [value]="input_start_date" (dateChange)="onStartDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <!-- ROW ========== ROW -->
    <tr>
      <td style="text-align: left;">
          <button mat-flat-button style="background-color: #2c97de" (click)="onUseCheckfrontDataClicked($event)" [disabled]="use_checkfront_button_disabled">Use checkfront data</button>
      </td>
      <td colspan="2" style="text-align: right">
        <button mat-flat-button class="yes" (click)="onOkClicked($event)" [disabled]="create_button_disabled">Create</button>
        <button mat-flat-button class="no" (click)="onCancelClicked($event)">Cancel</button>
      </td>
    </tr>
  </table>
</form>

