<div class="app-list-contracts">
  <table class="operation-header" style="width: 100%">
    <tr>
      <td style="text-align: left">
        <button
          mat-flat-button
          class="yes"
          style="margin-left: 0"
          (click)="onClickNewContract($event)"
        >
          New Contract
        </button>
      </td>
      <td style="text-align: right">
        <button
          mat-flat-button
          [disabled]="disable_generate_selecting_invoice"
          (click)="onClickGenerateInvoice($event)"
        >
          Generate selecting invoice
        </button>
        <button mat-flat-button (click)="onClickGenerateInvoices($event)">
          Generate invoices
        </button>
      </td>
    </tr>
  </table>
  <table class="operation-filter" style="width: 100%">
    <tr>
      <td class="refresh">
        <button
          mat-icon-button
          class="edit_icon"
          (click)="onRefreshList($event)"
        >
          <mat-icon aria-label="Refresh list">refresh</mat-icon>
        </button>
      </td>
      <td>
        <table width="100%">
          <tr>
            <td class="icon">
              <button mat-icon-button (click)="onClearStatusFilter($event)">
                <mat-icon aria-label="Clear status filter selection"
                  >clear</mat-icon
                >
              </button>
            </td>
            <td class="status-filter">
              <mat-form-field>
                <mat-select
                  placeholder="Status filter"
                  [(ngModel)]="contract_status_filters"
                  [formControl]="status_filter_form"
                  multiple
                >
                  <mat-select-trigger>
                    <span>
                      {{
                        contract_status_filters.length === 0 ||
                        contract_status_titles.length ===
                          contract_status_filters.length
                          ? "全て"
                          : contract_status_filters.length + "選択中"
                      }}
                    </span>
                  </mat-select-trigger>
                  <mat-option
                    (onSelectionChange)="onStatusFilterSelectionChanged($event)"
                    *ngFor="let contract_status_title of contract_status_titles"
                    [value]="contract_status_title"
                    >{{ contract_status_title }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table width="100%">
          <tr>
            <td class="icon">
              <button mat-icon-button (click)="onClearTextFilter($event)">
                <mat-icon aria-label="Clear text filter">clear</mat-icon>
              </button>
            </td>
            <td class="text-filter">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="current_filter"
                  (keyup)="onFilterKeyUp($event.target.value)"
                  placeholder="Text filter"
                />
              </mat-form-field>
            </td>
            <td style="min-width: 1.5rem">
              <img
                id="contract_list_updating"
                src="loading_s.gif"
                style="width: 1.5rem; opacity: 0"
              />
            </td>
          </tr>
        </table>
      </td>
      <td class="charging-list-paginator">
        <mat-paginator
          class="charging-list-paginator"
          [pageSizeOptions]="[50, 100, 500, 1000, 5000, 10000, 50000]"
          showFirstLastButtons
        ></mat-paginator>
      </td>
    </tr>
  </table>
  <div class="list-contracts-table-container mat-elevation-z8">
    <table mat-table [dataSource]="data_source" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>status</th>
        <td mat-cell *matCellDef="let element">
          <select
            #opts
            name="{{ 'status_' + element.contract_id }}"
            (change)="
              onChangeContractStatus({
                contract_id: element.contract_id,
                new_title: opts.value
              })
            "
            disabled
          >
            <option
              *ngFor="let status of contract_statuses"
              [value]="status.title"
              selected="{{
                status.status == element.contract_status ? 'selected' : ''
              }}"
            >
              {{ status.title }}
            </option>
          </select>
        </td>
      </ng-container>

      <ng-container matColumnDef="contract_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract code</th>
        <td mat-cell *matCellDef="let element">
          <a
            class="contract_code"
            target="_blank"
            href="{{
              '/operation/contract?contract_id=' + element.contract_id
            }}"
            >{{ element.contract_code }}</a
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>e-mail</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="user_full_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User name</th>
        <td mat-cell *matCellDef="let element">{{ element.user_full_name }}</td>
      </ng-container>

      <ng-container matColumnDef="pay_method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pay method</th>
        <td mat-cell *matCellDef="let element">{{ element.pay_method }}</td>
      </ng-container>

      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated at</th>
        <td mat-cell *matCellDef="let element">
          {{ getDisplayDateString(element.updated_at) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="subscription_plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Subscription plan
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.subscription_plan }}
        </td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltip]="element.note"
          matTooltipPosition="above"
        >
          {{ element.note }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pickup_method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pickup-method</th>
        <td mat-cell *matCellDef="let element">
          {{ element.pickup_method }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pickup_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pickup-date</th>
        <td mat-cell *matCellDef="let element">
          {{ getDisplayDateString(element.pickup_date) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="prefer_pickup_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pickup-prefer</th>
        <td mat-cell *matCellDef="let element">
          {{ getDisplayDateString(element.prefer_pickup_date) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="start_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
        <td mat-cell *matCellDef="let element">
          {{ getDisplayDateString(element.start_at) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="end_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
        <td mat-cell *matCellDef="let element">
          {{ getDisplayDateString(element.end_at) }}
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="display_columns; sticky: true"></tr> -->
      <tr mat-header-row *matHeaderRowDef="display_columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: display_columns"
        (click)="onClickRow($event)"
      ></tr>
    </table>
  </div>
</div>
