<table style="width: 100%">
  <tr>
    <td colspan="2">
      返却状況の更新
    </td>
  </tr>
  <tr>
    <td colspan="2">
      返却確認を行った日付を入力してください。商品が返却されているのであれば、[商品返却済み]にチェックを入れてください。返却状況の情報を削除する場合は[クリア]をクリックし、日付を空にしてから[OK]をクリックしてください。
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <mat-form-field class="date_pick">
        <input matInput [matDatepicker]="myDatePicker" placeholder="返却確認日" [value]="input_check_date" (dateChange)="onInputDateChanged($event)"/>
        <mat-datepicker-toggle matSuffix [for]="myDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatePicker></mat-datepicker>
      </mat-form-field>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <mat-checkbox [(ngModel)]="input_is_product_returned">商品返却済み</mat-checkbox>
    </td>
  </tr>
  <tr>
    <td style="text-align: left">
      <button mat-flat-button class="warn first" (click)="onClearClicked($event)">クリア</button>
    </td>
    <td style="text-align: right">
      <button mat-flat-button class="yes" (click)="onOkClicked($event)">OK</button>
      <button mat-flat-button class="no" (click)="onCancelClicked($event)">Cancel</button>
    </td>
  </tr>
</table>
