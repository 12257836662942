export class ContractListItemProduct {
    contract_product_id: number;
    product_name: string;
}

export class ContractListItem {
    id: number;
    contract_id: number;
    contract_code: string;
    contract_status: number;
    contract_status_key: string;
    contract_status_title: string;
    email: string;
    user_full_name_id: number;
    user_full_name: string;
    pay_method_id: number;
    pay_method: string;
    subscription_type_id: number;
    subscription_type: string;
    subscription_plan_id: number;
    subscription_plan: string;
    pickup_method: string;
    pickup_date: string;
    prefer_pickup_date: string;
    note_id: number;
    note: string;
    updated_at: string;
}
