import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserProperty } from '../../../models/contract-detail';
import { AuthService } from '../../../services/auth';
import { FlashMessage, AuthGetReply } from '../../../models';
import { EditPropertyDialogBaseComponent } from '../edit-property-dialog-base/edit-property-dialog-base.component';

@Component({
  selector: 'app-edit-user-property-dialog',
  templateUrl: '../edit-property-dialog-base/edit-property-dialog-base.component.html',
  styleUrls: ['../edit-property-dialog-base/edit-property-dialog-base.component.scss']
})
export class EditUserPropertyDialogComponent extends EditPropertyDialogBaseComponent {
  constructor(
    protected auth_service: AuthService,
    public dialogRef: MatDialogRef<EditUserPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserProperty
  ) {
    super(
      dialogRef,
      data,
    );
  }
  public onOkClicked(event) {
    this.data.value = this.input_value;
    const param = {
      id: this.data.user_property_id,
      value: this.data.value
    };
    this.auth_service.apiUpdateUserProperty(param, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      console.log(reply);
      context.dialogRef.close({
        applied: true
      });
    });
  }
}
