import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';

export class ReturnInformation {
  contract_id: number;
  contract_product_id: number;
  return_date: string;
  return_check_date: string;
  return_checker_e_mail: string;
  return_checker_user_id: number;
}

export class EditProductReturnInformationDialogData {
  return_info: ReturnInformation;
}

@Component({
  selector: 'app-edit-product-return-information-dialog',
  templateUrl: './edit-product-return-information-dialog.component.html',
  styleUrls: ['./edit-product-return-information-dialog.component.scss']
})

export class EditProductReturnInformationDialogComponent implements OnInit, OnDestroy {

  public input_check_date: string;
  public input_is_product_returned: boolean;
  public option;

  constructor(
    protected auth_service: AuthService,
    public dialogRef: MatDialogRef<EditProductReturnInformationDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: EditProductReturnInformationDialogData
  ) {
    let has_input_check_date = false;
    if (Utils.isValue(this.data) &&
        Utils.isValue(this.data.return_info) &&
        Utils.isValue(this.data.return_info.return_check_date)) {
      console.log('EditProductReturnInformationDialogComponent::constructor() : has return_check_date : ' + this.data.return_info.return_check_date);
      this.input_check_date = Utils.getDisplayDateString(this.data.return_info.return_check_date);
      has_input_check_date = true;
    } else {
      const today = new Date();
      this.input_check_date = Utils.getDisplayDateString(today.toISOString());
    }
    if (Utils.isValue(this.data) &&
        Utils.isValue(this.data.return_info) &&
        Utils.isValue(this.data.return_info.return_date)) {
      console.log('EditProductReturnInformationDialogComponent::constructor() : has return_date : ' + this.data.return_info.return_check_date );
      this.input_is_product_returned = true;
    } else {
      if (has_input_check_date) {
        // this.input_is_product_returned = false;
        this.input_is_product_returned = true;
      } else {
        // default
        this.input_is_product_returned = true;
      }
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  public onClearClicked(event): void {
    this.input_check_date = undefined;
    this.input_is_product_returned = false;
  }

  public onInputDateChanged(event): void {
    this.input_check_date = Utils.dateToDateString(event.value);
  }

  public onOkClicked(event): void {
    console.log('EditProductreturnInformationDialogComponent::onOkClicked');
    console.log(event);
    const return_check_date = Utils.isValue(this.input_check_date) && this.input_check_date.length > 0 ? this.input_check_date : null;
    console.log(this.input_check_date);
    console.log(this.input_is_product_returned);
    this.dialogRef.close({
      closeby: 'ok',
      return_date: return_check_date !== null && this.input_is_product_returned === true ? this.input_check_date : null,
      return_check_date: return_check_date,
    });
  }

  public onCancelClicked(event): void {
    console.log('EditProductreturnInformationDialogComponent::onCancelClicked');
    console.log(event);
    this.dialogRef.close({
      closeby: 'cancel',
    });
  }

  public getNamePart(e_mail: string): string {
    if (!Utils.isValue(e_mail)) {
      return '';
    }
    return e_mail.split('@')[0];
  }
  public getDisplayDateString(offsetZero: string): string {
    if (!Utils.isValue(offsetZero) || offsetZero.length === 0) {
      return '';
    }
    return Utils.getDisplayDateString(offsetZero);
  }
}
