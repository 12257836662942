<div class="app-add-invoice-product-property-dialog">
    <table style="width: 100%">
      <tr>
        <td colspan="2" style="width: 100%">
          {{title}}
        </td>
      </tr>
      <tr>
        <td colspan="2" style="width: 100%">
          <mat-select placeholder="商品プロパティを選択" (selectionChange)="onChangedPropertyTitle($event)">
            <mat-option *ngFor="let property of selectable_properties" [value]="property.title">
              {{property.title}}
            </mat-option>
          </mat-select>
          <mat-form-field class="full_width">
            <input matInput type="text" [(ngModel)]="description" name="description" (keyup)="onDescriptionChanged($event)" (change)="onDescriptionChanged($event)">
          </mat-form-field>
          <mat-form-field class="full_width">
            <input matInput type="number" [(ngModel)]="amount" name="amoount" (keyup)="onAmountChanged($event)" (change)="onAmountChanged($event)">
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: right">
          <button mat-flat-button class="yes" (click)="onOkClicked($event)" [disabled]="button_ok_disable">Apply</button>
          <button mat-flat-button class="no" (click)="onCancelClicked($event)" [disabled]="button_cancel_disable">Cancel</button>
        </td>
      </tr>
    </table>
  </div>