import { Component, OnInit, ElementRef } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { Utils } from '../../../helpers';
import { EmailErrorStateMatcher } from '../../../helpers/email-error-state-matcher';

export class PayMethodItem {
  key: string;
  title: string;
}
export class ContractProductSelectItem {
  key: string;
  title: string;
}

@Component({
  selector: 'app-new-contract-dialog',
  templateUrl: './new-contract-dialog.component.html',
  styleUrls: ['./new-contract-dialog.component.scss']
})
export class NewContractDialogComponent implements OnInit {
  private _el: HTMLElement;

  public pay_method_category: FormGroup;

  public input_user_id: string;
  public input_full_name: string;
  public input_full_name_disabled: boolean;
  public input_contract_code: string;
  public input_contract_code_error: boolean;
  public input_start_date: string;
  public create_button_disabled: boolean;
  public input_pay_method: PayMethodItem;
  public pay_methods: Array<PayMethodItem>;
  public input_pay_method_disabled: boolean;
  public contract_products: Array<ContractProductSelectItem>;
  protected input_contract_product: ContractProductSelectItem;
  public use_checkfront_button_disabled: boolean;

  public email_form_control: FormControl;
  public email_error_matcher: EmailErrorStateMatcher;

  constructor(
    private el: ElementRef,
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewContractDialogComponent>,
    private fb: FormBuilder,
  ) {
    this._el = el.nativeElement;
    this.email_form_control = new FormControl('', [
      // Validators.required,
      // Validators.email,
    ]);
    this.email_error_matcher = new EmailErrorStateMatcher();
    this.input_full_name_disabled = true;
    this.input_pay_method_disabled = true;
    this.input_contract_code_error = false;
    this.use_checkfront_button_disabled = true;
  }

  ngOnInit() {
    console.log('NewContractDialogComponent::ngOnInit');
    this.create_button_disabled = true;
    this.pay_methods = new Array<PayMethodItem>();
    this.pay_methods.push({key: 'CREDIT CARD', title: 'CREDIT CARD'});
    this.pay_methods.push({key: 'SMARTPIT', title: 'SMARTPIT'});
    this.pay_methods.push({key: 'GI BILL', title: 'GI BILL'});
    this.pay_method_category = this.fb.group({
      pay_method_category: [null, Validators.required]
    });
    this.auth_service.apiGetProductList().subscribe(
      response => {
        this.contract_products = new Array<ContractProductSelectItem>();
        response.data.forEach(element => {
          const item = new ContractProductSelectItem();
          item.key = element.id;
          item.title = element.title;
          this.contract_products.push(item);
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  private _contractCodeFormatIsValid(contract_code: string): boolean {
    if (!Utils.isValue(contract_code)) {
      return true;
    }
    if (contract_code.length <= 0) {
      return true;
    }

    const regex = new RegExp(/^[A-Z]{4}-[0-9]{6}$/);
    const is_valid = regex.test(contract_code);
    const input_el = (this._el.querySelector('input[name="input_contract_code"]') as HTMLElement);
    if (is_valid === true) {
      input_el.style.color = '';
    } else {
      input_el.style.color = 'red';
    }
    return is_valid;
  }

  private updateCreateButtonDisabled(): void {
    this.input_contract_code_error = !this._contractCodeFormatIsValid(this.input_contract_code);
    console.log(this.input_contract_code.length);
    this.use_checkfront_button_disabled = (
      !this._contractCodeFormatIsValid(this.input_contract_code) ||
      this.input_contract_code_error === undefined ||
      this.input_contract_code.length <= 0
    );
    this.create_button_disabled = !(
      this.input_user_id !== undefined &&
      this.input_user_id.length > 0 &&
      this.input_full_name !== undefined &&
      this.input_full_name.length > 0 &&
      this.input_pay_method !== undefined &&
      this.input_contract_code !== undefined &&
      this.input_contract_code.length > 0 &&
      this.input_contract_code_error === false &&
      this.input_contract_product !== undefined &&
      this.input_start_date !== undefined &&
      this.input_start_date.length > 0
    );
  }

  public onUserIdChanged(event): void {
    console.log('NewContractDialogComponent::onUserIdChanged');
    console.log(event.target.value);
    this.input_user_id = event.target.value;

    if (Utils.isValue(this.input_user_id)) {
      this.isExistUser();
    }
    // if (event_data.data.edited && event_data.event.key === 'Enter') {
    //   this.updateContractProperty(event_data.event.target, event_data.event.target.value, event_data.data, event_data.is_unique);
    // } else if (event_data.event.key !== 'Tab') {
    //   event_data.data.edited = true;
    // }

    this.updateCreateButtonDisabled();
  }

  public onBlurUserId(event): void {
    console.log('NewContractDialogComponent::onBlueUserId');
    this.input_user_id = event.target.value;
    this.updateCreateButtonDisabled();
  }

  public onUserFullNameChanged(event): void {
    console.log('NewContractDialogComponent::onUserFullNameChanged');
    this.input_full_name = event.target.value;
    this.updateCreateButtonDisabled();
  }

  public onChangedSelectingPayMethod(event): void {
    console.log('NewContractDialogComponent::onChangedSelectingPayMethod - start : ' + JSON.stringify(event.value));
    const filtered = this.pay_methods.filter(v => {
      return v.key === event.value.key;
    });
    if (Utils.isValue(filtered) && filtered.length > 0) {
      this.input_pay_method = filtered[0];
    }
    this.updateCreateButtonDisabled();
  }

  public onContractCodeChanged(event): void {
    console.log('NewContractDialogComponent::onContractCodeChanged');
    // this.input_contract_code = event.target.value;
    this.updateCreateButtonDisabled();
  }

  public onChangedSelectingContractProduct(event): void {
    console.log('NewContractDialogComponent::onChangedSelectingContractProduct - start : ' + JSON.stringify(event.value));
    const filtered = this.contract_products.filter(v => {
      return v.key === event.value.key;
    });
    if (Utils.isValue(filtered) && filtered.length > 0) {
      this.input_contract_product = filtered[0];
    }
    console.log(this.input_contract_product);
    this.updateCreateButtonDisabled();
  }

  public onStartDateChanged(event): void {
    if (Utils.isValue(event.value)) {
      this.input_start_date = Utils.dateToDateString(event.value);
    }
    this.updateCreateButtonDisabled();
  }

  public onCancelClicked(event): void {
    console.log('NewContractDialogComponent::onCancelClicked');
    console.log(event);
    this.dialogRef.close({
      closeby: 'cancel'
    });
  }

  public isExistUser(): void {
    console.log('NewContractDialogComponent::isExistUser');
    const params = {
      user_id: this.input_user_id
    };
    this.auth_service.apiIsExistUser(params).subscribe(
      response => {
        console.log(response);
        this.input_full_name_disabled = false;
        this.input_pay_method_disabled = false;
        if (Utils.isValue(response.data)) {
          if (Utils.isValue(response.data.exist)) {
            if (response.data.exist === true) {
              if (Utils.isValue(response.data.full_name) && response.data.full_name.length > 0) {
                this.input_full_name = response.data.full_name;
                this.input_full_name_disabled = true;
              }
              if (Utils.isValue(response.data.pay_method) && response.data.pay_method.length > 0) {
                const toSelect = this.pay_methods.find(method => method.key === response.data.pay_method);
                if (Utils.isValue(toSelect)) {
                  this.pay_method_category.get('pay_method_category').setValue(toSelect);
                  this.input_pay_method = toSelect;
                }
              }
            }
          }
        }
        this.updateCreateButtonDisabled();
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }

  public onOkClicked(event): void {
    console.log('NewContractDialogComponent::onOkClicked');
    console.log(event);
    const params = {
      e_mail: this.input_user_id,
      full_name: this.input_full_name,
      pay_method: this.input_pay_method.key,
      contract_code: this.input_contract_code,
      product_type_id: this.input_contract_product.key,
      start_date: Utils.getBackendDatetimeString(this.input_start_date, 'begin'),
    };
    this.auth_service.apiAddContract(params).subscribe(
      response => {
        console.log(response);
        this.dialogRef.close({
          closeby: 'ok'
        });
        window.open('/operation/contract?contract_id=' + response.data.contract.id);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }

  public onUseCheckfrontDataClicked(event): void {
    console.log('NewContractDialogComponent::onUseCheckfrontDataClicked');
    console.log(event);
    const params = {
      booking_id_checkfront: this.input_contract_code,
    };
    this.auth_service.apiNewContractViaCheckfront(params).subscribe(
      response => {
        console.log(response);
        this.dialogRef.close({
          closeby: 'ok'
        });
        if (response.data.has_other_pay_method === true ||
          response.data.pick_up_at_sakuramobile_shinjuku === true ||
          response.data.no_start_date === true) {
          window.open('/operation/contract?contract_id=' + response.data.contract.id + '&check_memo=true');
        } else {
          window.open('/operation/contract?contract_id=' + response.data.contract.id);
        }
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }
}
