<div class="app-add-contract-memo-dialog">
  <div>
    <mat-form-field>
      <textarea matInput [(ngModel)]="memo"  (keyup)="onMemoKeyUp($event.target.value)" placeholder="Memo"></textarea>
    </mat-form-field>
  <div>

  </div>
  <table style="width: 100%">
    <tr>
      <td>
        <mat-checkbox [(ngModel)]="to_be_contract_note">Contract Note</mat-checkbox>
      </td>
      <td colspan="2" style="text-align: right">
        <button mat-flat-button class="yes" (click)="onClickAdd($event)" [disabled]="button_add_disable">Add</button>
        <button mat-flat-button class="no" (click)="onClickCancel($event)" [disabled]="button_cancel_disable">Cancel</button>
      </td>
    </tr>
  </table>
</div>