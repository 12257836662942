<div class="app-list-fiber-schedules">
  <table class="operation-header" style="width: 100%">
    <tr>
      <td style="text-align: left"></td>
      <td style="text-align: right">

        <button mat-flat-button style="margin-left: 0;" (click)="onExportYamatoCSV($event)">ヤマトCSV(書き出し)</button>
        <input style="display: none" type="file" (change)="onClickUploadYamatoCSV($event)" #uploadYamatoCSV />
        <button mat-flat-button (click)="uploadYamatoCSV.click($event)">ヤマトCSV(取込)</button>
        <button mat-flat-button (click)="onClickShippingNotification($event)">
          発送連絡送信
        </button>
        <button mat-flat-button (click)="onClickRemindNotification($event)">
          事前連絡送信
        </button>
        <button mat-flat-button (click)="onClickLineOpenNotification($event)">
          開通確認送信
        </button>
      </td>
    </tr>
  </table>
  <table class="operation-filter" style="width: 100%">
    <tr>
      <td class="refresh">
        <button
          mat-icon-button
          class="edit_icon"
          (click)="onRefreshList($event)"
        >
          <mat-icon aria-label="Refresh list">refresh</mat-icon>
        </button>
      </td>
      <td>
        <table width="100%">
          <tr>
            <td class="icon">
              <button mat-icon-button (click)="onClearStatusFilter($event)">
                <mat-icon aria-label="Clear status filter selection"
                  >clear</mat-icon
                >
              </button>
            </td>
            <td class="status-filter">
              <mat-form-field>
                <mat-select
                  placeholder="処理ステータス"
                  [(ngModel)]="fiber_schedule_status_filters"
                  [formControl]="fiber_schedule_status_filter_form"
                  multiple
                >
                  <mat-select-trigger>
                    <span>
                      {{
                        fiber_schedule_status_filters.length === 0 ||
                        fiber_schedule_status_titles.length ===
                          fiber_schedule_status_filters.length
                          ? "全て"
                          : fiber_schedule_status_filters.length + "選択中"
                      }}
                    </span>
                  </mat-select-trigger>
                  <mat-option
                    (onSelectionChange)="onStatusFilterSelectionChanged($event)"
                    *ngFor="let candidate of fiber_schedule_status_titles"
                    [value]="candidate"
                    >{{ candidate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </td>
            <td class="date-type">
              <select
                name="fiber_schedule_date_filter_select"
                (change)="
                onDateFilterTypeChanged($event)
                "
              >
                <option
                  *ngFor="let candidate of fiber_schedule_date_filter_types"
                  [ngValue]="candidate"
                  [selected]="candidate === fiber_schedule_date_filter_selected"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
            <td class="date-picker from">
              <input
                matInput
                [matDatepicker]="fiberListDateFromPicker"
                placeholder="none"
                [value]="fiber_schedule_date_from"
                (dateChange)="onDateFilterFromChanged($event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fiberListDateFromPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #fiberListDateFromPicker
              ></mat-datepicker>
            </td>
            <td>
              -
            </td>
            <td class="date-picker to">
              <input
                matInput
                [matDatepicker]="fiberListDateToPicker"
                placeholder="none"
                [value]="fiber_schedule_date_to"
                (dateChange)="onDateFilterToChanged($event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fiberListDateToPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #fiberListDateToPicker
              ></mat-datepicker>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table width="100%">
          <tr>
            <td style="min-width: 1.5rem">
              <img
                id="contract_list_updating"
                src="loading_s.gif"
                style="width: 1.5rem; opacity: 0"
              />
            </td>
          </tr>
        </table>
      </td>
      <td class="charging-list-paginator">
        <mat-paginator
          class="charging-list-paginator"
          [pageSizeOptions]="[50, 100, 500, 1000, 5000, 10000, 50000]"
          showFirstLastButtons
        ></mat-paginator>
      </td>
    </tr>
  </table>
  <div class="list-contracts-table-container mat-elevation-z8">
    <table mat-table [dataSource]="data_source" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th class="fiber-list-select-header" mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td class="fiber-list-select" mat-cell *matCellDef="let row" data="row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="fiber_schedule_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fiber_schedule_status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contract_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking ID</th>
        <td mat-cell *matCellDef="let element">
          <a
            class="contract_code"
            target="_blank"
            href="{{
              '/operation/fiber_schedule?contract_product=' + element.contract_product_id
            }}"
            >{{ element.contract_code }}</a
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>メール</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="user_full_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>名前</th>
        <td mat-cell *matCellDef="let element">{{ element.user_full_name }}</td>
      </ng-container>

      <ng-container matColumnDef="ntt_region">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>東西</th>
        <td mat-cell *matCellDef="let element">{{ element.ntt_region }}</td>
      </ng-container>

      <ng-container matColumnDef="router_model">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>機種</th>
        <td mat-cell *matCellDef="let element">{{ element.router_model }}</td>
      </ng-container>

      <ng-container matColumnDef="preliminary_investigation_dt_fixed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>事前調査日</th>
        <td mat-cell *matCellDef="let element">
          {{ dateAndTimeToDate(element.preliminary_investigation_dt_fixed) }}<br />
          {{ dateAndTimeToTime(element.preliminary_investigation_dt_fixed) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="install_dt_fixed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>工事日</th>
        <td mat-cell *matCellDef="let element">
          {{ dateAndTimeToDate(element.install_dt_fixed) }}<br/>
          {{ dateAndTimeToTime(element.install_dt_fixed) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="support_date_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>対応予定日</th>
        <td mat-cell *matCellDef="let element">
          {{ datetimeStringToDisplayDate(element.support_date_at) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="isp_registoration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ISP登録</th>
        <td mat-cell *matCellDef="let element">
          {{ element.isp_registoration }}
        </td>
      </ng-container>

      <ng-container matColumnDef="router_arrengement_data_exported">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CSV出力</th>
        <td mat-cell *matCellDef="let element">
          {{ element.router_arrengement_data_exported }}
        </td>
      </ng-container>

      <ng-container matColumnDef="router_tracking_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>追跡番号</th>
        <td mat-cell *matCellDef="let element">
          {{ element.router_tracking_number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="shipment_notified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>発送連絡</th>
        <td mat-cell *matCellDef="let element">
          {{ displayBool(element.shipment_notified, '済', '未') }}
        </td>
      </ng-container>

      <ng-container matColumnDef="inst_reminder_notified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>事前連絡</th>
        <td mat-cell *matCellDef="let element">
          {{ displayBool(element.inst_reminder_notified, '済', '未') }}
        </td>
      </ng-container>

      <ng-container matColumnDef="connection_confirmed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>開通確認</th>
        <td mat-cell *matCellDef="let element">
          {{ displayBool(element.connection_confirmed, '済', '未') }}
        </td>
      </ng-container>

      <ng-container matColumnDef="inst_fee_pay_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>支払</th>
        <td mat-cell *matCellDef="let element">
          {{ element.inst_fee_pay_status }}
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="display_columns; sticky: true"></tr> -->
      <tr mat-header-row *matHeaderRowDef="display_columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: display_columns"
      ></tr>
    </table>
  </div>
</div>
