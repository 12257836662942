<div class="app-search-fast-text">
  <table class="operation" style="width: 100%">
    <tr>
      <td style="width: 50%; padding: 0;">
        <table style="width: 100%; margin: 0;">
          <tr>
            <td style="width: 100%">
              <mat-form-field class="filter">
                <input matInput [(ngModel)]="current_filter" (blur)="onBlurFilter($event)" (keyup)="onFilterKeyUp($event)" placeholder="Filter">
              </mat-form-field>
            </td>
            <td style="min-width: 1.5rem;">
              <img id="search_text_searching" src="loading_s.gif" style="width: 1.5rem; opacity: 0.0;"/>
            </td>
          </tr>
        </table>
      </td>
      <td style="width: 50%;">
        <mat-paginator class="serch-text-list-paginator" [pageSizeOptions]="[50, 100, 500, 1000, 5000, 10000, 50000]" showFirstLastButtons></mat-paginator>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <div class="list-search-text-table-container mat-elevation-z8">
          <table mat-table [dataSource]="data_source" matSort>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="contract_code">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract code </th>
              <td mat-cell *matCellDef="let element">
                <a class="contract_code" href="{{'/operation/contract?contract_id=' + element.contract_id}}"
                  target="_blank">{{element.contract_code}}</a>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="contract_status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.contract_status}}
              </td>
            </ng-container>
      
            <ng-container matColumnDef="subscription_plan">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Plan </th>
              <td mat-cell *matCellDef="let element"> {{element.subscription_plan}}
              </td>
            </ng-container>
      
            <ng-container matColumnDef="contract_start_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Start at </th>
              <td mat-cell *matCellDef="let element"> {{getDisplayDateString(element.contract_start_at)}}
              </td>
            </ng-container>
      
            <ng-container matColumnDef="contract_end_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> End at </th>
              <td mat-cell *matCellDef="let element"> {{getDisplayDateString(element.contract_end_at)}}
              </td>
            </ng-container>
      
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> email </th>
              <td mat-cell *matCellDef="let element"> {{element.email}}
              </td>
            </ng-container>
      
            <ng-container matColumnDef="user_full_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.user_full_name}}
              </td>
            </ng-container>
      
            <ng-container matColumnDef="found_value">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Found </th>
              <td mat-cell *matCellDef="let element"> {{element.found_value}}
              </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
            <tr mat-row *matRowDef="let row; columns: display_columns;" (click)="onClickRow($event)"></tr>
          </table>
        </div>
      </td>
    </tr>
  </table>
</div>