<div class="container">
    <mat-card>
        <mat-card-title>
            Login
        </mat-card-title>
    </mat-card>
    <br/>
    <form #imageForm=ngForm (ngSubmit)="onClickLogin($event)" class="mui-form">
        <div class="row">
            <mat-form-field>
                <mat-label>User ID (e-mail)</mat-label>
                <input matInput type="email" #InputUserId [formControl]="email_form_control" [errorStateMatcher]="email_error_matcher" name="input_value"
                (keyup)="onEmailChanged($event)" (change)="onEmailChanged($event)" autocomplete="on" required>
            <mat-error *ngIf="email_form_control.hasError('email') && !email_form_control.hasError('required')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="email_form_control.hasError('required')">
                Email is
                <strong>required</strong>
            </mat-error>

            </mat-form-field>
        </div>
        <br/>
        <div class="row">
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password" #InputPassword [(ngModel)]="input_password" (keyup)="onPasswordChanged($event)" (change)="onPasswordChanged($event)" name="input_password" autocomplete="on" required>
            </mat-form-field>
        </div>
        <br/>
        <button mat-flat-button id="submit_login" type="submit" [disabled]="button_login_disabled">Submit</button>
    </form>
</div>