import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { AuthService } from '../../../services/auth';
import { FlashMessage, AuthGetReply, ContractListItem } from '../../../models';
import { Utils } from '../../../helpers';

export class GenerateInvoiceProgressDialogData {
  invoice_month: string;
}

@Component({
  selector: 'app-generate-invoice-progress-dialog',
  templateUrl: './generate-invoice-progress-dialog.component.html',
  styleUrls: ['./generate-invoice-progress-dialog.component.scss']
})
export class GenerateInvoiceProgressDialogComponent
  implements OnInit, OnDestroy {
  public progress: number;
  public progress_display: string;
  public progress_title: string;
  public progress_message: string;
  public close_disable: boolean;
  public timer_for_proc: any;
  public invoice_month: string;
  public contracts: Array<ContractListItem>;

  constructor(
    protected auth_service: AuthService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<GenerateInvoiceProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenerateInvoiceProgressDialogData
  ) {
    this.progress = 0;
    this.progress_display = 'block';
    this.progress_title = '';
    this.progress_message = '';
  }

  ngOnInit() {
    this.invoice_month = this.data.invoice_month;
    if (!Utils.isValue(this.invoice_month)) {
      this.dialogRef.close();
    }
    // start then, close automatically
    this.timer_for_proc = setTimeout(this.generateProc.bind(this), 1000);
  }

  ngOnDestroy() {
    if (Utils.isValue(this.timer_for_proc)) {
      this.timer_for_proc = undefined;
    }
  }

  public generateProc() {
    clearTimeout(this.timer_for_proc);
    console.log('GenerateInvoiceProgressDialogComponent::generateProc - start');
    this.progress = 0;
    this.progress_display = 'block';
    this.progress_title = this.invoice_month + ' generating...';
    this.close_disable = true;
    const params = {
      invoice_month: this.invoice_month
    };
    this.auth_service.apiGenerateInvoices(params, this, function(
      reply: AuthGetReply,
      context: any
    ) {
      context.progress_display = 'none';
      if (reply.data.status === 'success') {
        context.progress_title = context.invoice_month + ' generated.';
        context.progress = 100;
      } else {
        context.progress_title = context.invoice_month + ' failed.';
        context.progress = 0;
      }
      context.close_disable = false;
      console.log(reply);
    });
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 5000;
    cfg.panelClass = ['notify_snackbar', 'information'];
    const bar_ref = this.snackBar.open(
      'Started to generate invoices. This takes for long time.',
      'OK',
      cfg
    );
    this.dialogRef.close();
  }

  public onClickClose(event): void {
    console.log('GenerateInvoiceProgressDialogComponent::onClickClose - start');
    this.dialogRef.close();
  }
}
