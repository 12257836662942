import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../../services/auth';
import { FlashMessage, AuthGetReply, InvoiceListItem } from '../../../models';
import { Utils } from '../../../helpers';
import { Values } from '../../../constants';

export class SendInvoiceProgressDialogData {
  invoice_month: string;
  invoices: Array<InvoiceListItem>;
}

@Component({
  selector: 'app-send-invoice-progress-dialog',
  templateUrl: './send-invoice-progress-dialog.component.html',
  styleUrls: ['./send-invoice-progress-dialog.component.scss']
})
export class SendInvoiceProgressDialogComponent implements OnInit, OnDestroy {
  public progress: number;
  public sending_invoices_count: number;
  public sending_invoice_index: number;
  public sent_count: number;
  public fail_count: number;
  public progress_display: string;
  public progress_title: string;
  public progress_message: string;
  public close_disable: boolean;
  public timer_for_proc: any;
  public invoice_month: string;
  public invoices: Array<InvoiceListItem>;

  constructor(
    protected auth_service: AuthService,
    public dialogRef: MatDialogRef<SendInvoiceProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SendInvoiceProgressDialogData,
  ) {
    this.progress = 0;
    this.progress_display = 'block';
    this.progress_title = '';
    this.progress_message = '';
  }

  ngOnInit() {
    this.invoice_month = this.data.invoice_month;
    this.invoices = this.data.invoices;
    if (
      !Utils.isValue(this.invoice_month)
    ) {
      this.dialogRef.close();
    }
    // start then, close automatically
    this.timer_for_proc = setTimeout(this.sendProc.bind(this), 1000);
  }

  ngOnDestroy() {
    if (Utils.isValue(this.timer_for_proc)) {
      this.timer_for_proc = undefined;
    }
  }

  private sendInvoicesImpl() {
    if (this.sending_invoice_index >= this.sending_invoices_count) {
      console.log('SendInvoiceProgressDialogComponent::sendInvoicesImpl - done(should not be called here)');
      return;
    }
    const invoice = this.invoices[this.sending_invoice_index];
    this.sending_invoice_index++;
    if (invoice.mail_send_config === 0) {
      console.log('SendInvoiceProgressDialogComponent::sendInvoicesImpl - send skip(manual)');
      this.sendInvoicesImpl(); // recursive call
      return;
    } else if (invoice.mail_send_config === 1) {
      console.log('SendInvoiceProgressDialogComponent::sendInvoicesImpl : invoice_id=' + invoice.id + ' : contract_code=' + invoice.contract_code);
      this.progress_message = invoice.contract_code + 'sending invoice.';
      const params = {
        invoice_id: invoice.id,
      };
      this.auth_service.apiSendInvoice(params, this, function(
        reply: any,
        context: any
      ) {
        console.log('SendInvoiceProgressDialogComponent::sendInvoice : got reply');
        console.log(reply);
        context.addCounter(reply.message.status === 'success');
        if (context.sending_invoice_index >= context.sending_invoices_count) {
          console.log('SendInvoiceProgressDialogComponent::sendInvoicesImpl - done');
          return;
        }
        context.sendInvoicesImpl(); // recursive call
      });
      return;
    } else {
      console.log('SendInvoiceProgressDialogComponent::sendInvoicesImpl - send skip(unknown send config)');
      this.sendInvoicesImpl(); // recursive call
    }
  }

  private async sendInvoices(invoices: Array<InvoiceListItem>) {
    this.sending_invoices_count = this.invoices.length;
    this.sending_invoice_index = 0;
    this.sendInvoicesImpl();
  }

  public sendProc() {
    clearTimeout(this.timer_for_proc);
    this.progress = 0;
    this.progress_display = 'block';
    this.progress_title = this.invoice_month + ' sending...';
    this.close_disable = true;
    this.resetSentCounter();
    this.sendInvoices(this.invoices);
  }

  private resetSentCounter() {
    this.sending_invoices_count = this.invoices !== undefined ? this.invoices.length : 0;
    this.sending_invoice_index = 0;
    this.sent_count = 0;
    this.fail_count = 0;
  }

  private addCounter(sent: boolean) {
    if (sent) {
      this.addSentCounter();
    } else {
      this.addFailCounter();
    }
    if (this.sending_invoice_index >= this.sending_invoices_count) {
      this.progress_title = this.invoice_month + ' done';
      this.progress_message = this.sent_count.toString() + ' sent / ' + this.fail_count.toString() + ' failed';
      this.close_disable = false;
    } else {
      this.progress_message = this.sending_invoices_count.toString() + ' / ' + this.sending_invoices_count.toString();
    }
  }

  private addSentCounter() {
    this.sent_count++;
    this.progress = ((this.sent_count + 1) * 100) / this.sending_invoices_count;
  }

  private addFailCounter() {
    this.fail_count++;
  }

  public onClickClose(event): void {
    this.dialogRef.close();
  }
}
