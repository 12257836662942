<div class="app-list-contract-pay-histories">
  支払い状況 : 未払い件数= {{unpaid_count}}
  <table mat-table [dataSource]="data_source" matSort>

    <ng-container matColumnDef="invoice_month">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice month </th>
      <td mat-cell *matCellDef="let element">{{element.invoice_month}}</td>
    </ng-container>

    <ng-container matColumnDef="invoice_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice code </th>
      <td mat-cell *matCellDef="let element">
          <a class="invoice_code" href="{{'/operation/invoice?contract_code=' + element.contract_code + '&invoice_month=' + element.invoice_month}}"
          target="_blank">{{element.invoice_code}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="pdf">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>PDF</th>
      <td mat-cell *matCellDef="let element">
          <a class="pdf" href="{{root_for_pdf + '/invoices/show/' + element.secure_random}}" target="_blank">show</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="sub_total_include_tax">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub total </th>
      <td mat-cell *matCellDef="let element"> {{yen(element.sub_total_include_tax)}} </td>
    </ng-container>

    <ng-container matColumnDef="mail_send_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Send status </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.mail_send_status === 0">
          <select name="send_status_select" (change)="onChangeMailSendStatus({invoice: element, new_status: $event.target.value})">
            <option value="NOT_YET" selected>未送信</option>
            <option value="SENT">送信済</option>
          </select>
        </div>
        <div *ngIf="element.mail_send_status !== 0">
          <select name="send_status_select" (change)="onChangeMailSendStatus({invoice: element, new_status: $event.target.value})">
            <option value="NOT_YET">未送信</option>
            <option value="SENT" selected>送信済</option>
          </select>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="pay_total_include_tax">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay total </th>
      <td mat-cell *matCellDef="let element"> {{element.pay_total_include_tax === undefined ? '' : yen(element.pay_total_include_tax)}} </td>
    </ng-container>

    <ng-container matColumnDef="pay_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay date </th>
      <td mat-cell *matCellDef="let element"> {{element.pay_date === undefined || element.pay_date === null ? '' : getDisplayDateString(element.pay_date)}} </td>
    </ng-container>

    <ng-container matColumnDef="pay_error">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay error </th>
      <td mat-cell *matCellDef="let element"> {{element.pay_error}} </td>
    </ng-container>

    <ng-container matColumnDef="pay_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay status </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.pay_status === 0">
          <select name="pay_status_select" (change)="onChangePayStatus({invoice: element, new_status: $event.target.value})">
              <option value="NOT_YET" selected>未払い</option>
              <option value="PAID">支払済</option>
          </select>
        </div>
        <div *ngIf="element.pay_status !== 0">
          <select name="pay_status_select" (change)="onChangePayStatus({invoice: element, new_status: $event.target.value})">
            <option value="NOT_YET">未払い</option>
            <option value="PAID" selected>支払済</option>
          </select>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="pay_method">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pay method </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.pay_method === 'CREDIT CARD'">
          <select name="pay_method_select" (change)="onChangePayMethod({invoice: element, new_method: $event.target.value})">
            <option value="CREDIT CARD" selected>CREDIT</option>
            <option value="SMARTPIT">SMARTPIT</option>
            <option value="PAYPAL" >PAYPAL</option>
            <option value="SM_DEPO">SM_DEPO</option>
            <option value="GI BILL">GI BILL</option>
          </select>
        </div>
        <div *ngIf="element.pay_method === 'SMARTPIT'">
          <select name="pay_method_select" (change)="onChangePayMethod({invoice: element, new_method: $event.target.value})">
            <option value="CREDIT CARD">CREDIT</option>
            <option value="SMARTPIT" selected>SMARTPIT</option>
            <option value="PAYPAL" >PAYPAL</option>
            <option value="SM_DEPO">SM_DEPO</option>
            <option value="GI BILL">GI BILL</option>
          </select>
        </div>
        <div *ngIf="element.pay_method === 'PAYPAL'">
          <select name="pay_method_select" (change)="onChangePayMethod({invoice: element, new_method: $event.target.value})">
            <option value="CREDIT CARD">CREDIT</option>
            <option value="SMARTPIT">SMARTPIT</option>
            <option value="PAYPAL" selected>PAYPAL</option>
            <option value="SM_DEPO">SM_DEPO</option>
            <option value="GI BILL">GI BILL</option>
          </select>
        </div>
        <div *ngIf="element.pay_method === 'SM_DEPO'">
          <select name="pay_method_select" (change)="onChangePayMethod({invoice: element, new_method: $event.target.value})">
            <option value="CREDIT CARD">CREDIT</option>
            <option value="SMARTPIT">SMARTPIT</option>
            <option value="PAYPAL" >PAYPAL</option>
            <option value="SM_DEPO" selected>SM_DEPO</option>
            <option value="GI BILL">GI BILL</option>
          </select>
        </div>
        <div *ngIf="element.pay_method === 'GI BILL'">
          <select name="pay_method_select" (change)="onChangePayMethod({invoice: element, new_method: $event.target.value})">
            <option value="CREDIT CARD">CREDIT</option>
            <option value="SMARTPIT">SMARTPIT</option>
            <option value="PAYPAL" >PAYPAL</option>
            <option value="SM_DEPO">SM_DEPO</option>
            <option value="GI BILL" selected>GI BILL</option>
          </select>
        </div>
        <div *ngIf="element.pay_method !== 'CREDIT CARD' && element.pay_method !== 'SMARTPIT' && element.pay_method !== 'PAYPAL' && element.pay_method !== 'SM_DEPO'">
          {{element.pay_method}}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
    <tr mat-row *matRowDef="let row; columns: display_columns;"></tr>
  </table>
</div>
