<div class="app-edit-invoice-product-property-dialog">
  <table style="width: 100%">
    <tr>
      <td colspan="2" style="width: 100%">
        {{title}}
      </td>
    </tr>
    <tr>
      <td colspan="2" style="width: 100%">
        <div *ngIf="key === 'PRICE'">
          <div>{{description}}</div>
        </div>
        <div *ngIf="key !== 'PRICE'">
            <mat-form-field class="full_width">
              <input matInput type="text" [(ngModel)]="description" name="description" (keyup)="onDescriptionChanged($event)" (change)="onDescriptionChanged($event)">
            </mat-form-field>
        </div>
        <mat-form-field class="full_width">
          <input matInput type="number" [(ngModel)]="amount" name="amoount" (keyup)="onAmountChanged($event)" (change)="onAmountChanged($event)">
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="text-align: right">
        <button mat-flat-button class="yes" (click)="onOkClicked($event)" [disabled]="button_ok_disable">Apply</button>
        <button mat-flat-button class="no" (click)="onCancelClicked($event)" [disabled]="button_cancel_disable">Cancel</button>
      </td>
    </tr>
  </table>
</div>