export class InvoiceContent {
  detail: InvoiceContentDetail;
  list: Array<InvoiceContentListItem>;

  constructor() {
    this.detail = new InvoiceContentDetail;
    this.list = new Array<InvoiceContentListItem>();
  }
}

export class InvoiceContentDetail {
  invoice_id: number;
  invoice_month: string;
  invoice_code: string;
  contract_id: number;
  contract_code: string;
  user_id: number;
  user_e_mail: number;
  user_name: string;
  sub_total: number;
  sub_total_tax: number;
  mail_send_status: number;
  pay_status: number;
  invoice_date: string;
  invoice_due_date: string;
  products: Array<InvoiceContentProduct>;

  constructor() {
    this.products = new Array<InvoiceContentProduct>();
  }
}

export class InvoiceContentProduct {
  title: string;
  price: number;
  price_tax: number;
  sub_total: number;
  sub_total_tax: number;
  properties: Array<InvoiceContentProductProperty>;

  constructor() {
    this.properties = new Array<InvoiceContentProductProperty>();
  }
}
export class InvoiceContentProductProperty {
  key: string;
  value: string;
  title: string;
  description: string;
}

export enum InvoiceContentListItemType {
  TITLE = 0,
  PRODUCT = 1,
  PROPERTY = 2,
  PRODUCT_PRICE = 3,
  PRODUCT_TAX = 4,
  PROPERTY_PRICE = 5,
  PROPERTY_TAX = 6,
  SUB_TOTAL = 7,
  SUB_TOTAL_TAX = 8,
  TOTAL = 9,
  NOTE = 10
}

export class InvoiceContentListItem {
  invoice_id: number;
  invoice_product_id: number;
  item_type: InvoiceContentListItemType;
  invoice_month: string;
  key: string;
  title: string;
  description: string;
  amount: number;
}
