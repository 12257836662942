<div class="app-generate-topup-ticket">
  <table>
    <tbody>
      <tr>
        <td class="title">キャンペーン名 :</td>
        <td>
          <mat-form-field>
            <input
              matInput
              type="text"
              [(ngModel)]="campaign_title"
              name="campaign_title"
              (change)="onChangedCampaignTitle($event)"
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="title">追加データ量 :</td>
        <td>
          <mat-form-field class="full_width">
            <mat-select
              (selectionChange)="onChangedDataAmount($event)"
            >
              <mat-option
                *ngFor="let item of amount_candidates"
                [value]="item"
              >
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="title">発行数 :</td>
        <td>
          <mat-form-field>
            <input
              matInput
              type="number"
              [(ngModel)]="publish_count"
              name="publish_count"
              (change)="onChangedPublishCount($event)"
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="title">有効期限 :</td>
        <td>
          <mat-form-field class="full_width">
            <input
              matInput
              [matDatepicker]="topUpTicketValidUntilPicker"
              placeholder="Date"
              [value]="valid_until"
              (dateChange)="onValidUntilChanged($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="topUpTicketValidUntilPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #topUpTicketValidUntilPicker></mat-datepicker>
          </mat-form-field>
        </td>
      </tr>
    </tbody>
  </table>
  <table style="width: 100%">
    <tr>
      <td colspan="2" style="text-align: right">
        <button mat-flat-button class="yes" (click)="onClickYes($event)">
          発行
        </button>
        <button mat-flat-button class="no" (click)="onClickNo($event)">
          キャンセル
        </button>
      </td>
    </tr>
  </table>
</div>
